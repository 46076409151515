<i18n src="@/i18n/backoffice/account/security_form.json"></i18n>
<template>
    <div v-if="isActive">
        <!-- Dados pessoais !-->
        <Card :title="$t('security.access_data')" class="mt-4">

        </Card>
        <!-- Dados pessoais !-->
        <Card :title="$t('security.devices')" class="mt-4">
        </Card>
    </div>
</template>


<script>
import Card from "@/components/Card"

export default {
    name: "SecurityForm",
    components: {
        Card
    },
    data() {
        return {
            isActive: false
        }
    },
}
</script>