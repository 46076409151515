import numeral from "numeral"

export default {
    install(vue) {

        Object.defineProperties(vue.prototype, {
            $numeral(v){
                return numeral(v)
            }
        });

        vue.prototype.$formatCurrency = function(v, format){
            format = format || '$ 0,0.00'
            return numeral(v).format(format)
        }

        vue.prototype.$formatUsdCurrency = function(v, format){
            format = format || '$ 0,0.00'
            return numeral(v).format(format)
        }

    }
}
