<i18n src="@/i18n/backoffice/shipments/ticket.json"></i18n>
<template>
	<aside class="hidden xl:block xl:flex-shrink-0 xl:order-first bg-white">
		<!-- Message list-->
		<aside class="hidden xl:order-first xl:block xl:flex-shrink-0">
			<div class="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
				<div class="flex-shrink-0">
					<div class="flex h-16 flex-col justify-center bg-white px-6">
						<div class="flex items-baseline space-x-3">
							<h2 class="text-lg font-medium text-gray-900">Caixa de entrada</h2>
							<p class="text-sm font-medium text-gray-500">{{ ticketList.length }} {{ $tc(`mensagem | menssagens`, ticketList.length) }}</p>
						</div>
					</div>
					<div class="border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">Ordenado por mais atual</div>
				</div>
				<nav aria-label="Message list" class="min-h-0 flex-1 overflow-y-auto">
					<ul role="list" class="divide-y divide-gray-200 border-b border-gray-200">

						<template v-for="(ticket, index) in ticketList">

							<li class="relative bg-white py-5 px-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-turquoise hover:bg-gray-50"
								:class="{ 'bg-gray-100' : isReaded(ticket) }" @click="handleSelect(ticket, index)">
								<div class="flex justify-between space-x-3">
									<div class="min-w-0 flex-1">
										<a href="#" class="block focus:outline-none">
											<span class="absolute inset-0" aria-hidden="true"></span>
											<p class="truncate text-sm font-medium text-gray-900">{{ ticket.created_by.name }}</p>
											<p class="truncate text-sm text-gray-500">{{ $localize(ticket.subject.title_locale) }}</p>
										</a>
									</div>
									<time datetime="2021-01-27T16:35" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{{ $utcDisplayDateTime(ticket.updated_at) }}</time>
								</div>
								<div class="mt-1">
									<p class="text-sm text-gray-600 line-clamp-2">{{ ticket.short_body }}</p>
								</div>
							</li>

						</template>

					</ul>
				</nav>
			</div>
		</aside>
	</aside>
</template>

<script>
import {get} from "vuex-pathify"
import Showdown from "showdown"
import Card from "@/components/Card.vue"


export default {
	name: "TicketNavPartial",
	components: {
		Card
	},
	computed: {
		page: get('SupportTickets@page'),
		ticketList() {
			return this.page?.data?.filter(item => item.support_ticket_status != 99) ?? []
		}
	},
	methods: {
		/**
		 * Check if message is read
		 * @param message
		 * @returns {boolean}
		 */
		isReaded(message) {
			return message.updated_at < message.last_seen_at;
		},
		/**
		 * Handle select ticket
		 * @param ticket
		 * @param index
		 */
		handleSelect(ticket, index) {
			this.$emit('select', {id: ticket.id, index});
		}
	}
}
</script>