export default {
    data(){
        return {
            emitLoading: false,
            isLoading: false,
            isBgLoading: false,
            loadingTimer: null
        }
    },
    methods: {
        /**
         * Action loading
         * @param loadingAttribute
         * @returns {default.methods}
         */
        loading(loadingAttribute = 'isLoading'){
            this.$set(this, loadingAttribute, true)
            if(this.emitLoading) this.$emit('loading')
            // this.loadingTimer = setTimeout(() => {
            //     this.$set(this, loadingAttribute, true)
            //     if(this.emitLoading) this.$emit('loading')
            // }, 300)
            return this
        },
        /**
         * Action loaded
         * @param loadingAttribute
         * @returns {default.methods}
         */
        loaded(loadingAttribute = 'isLoading'){
            // clearTimeout(this.loadingTimer)
            this.$set(this, loadingAttribute, false)
            this.isBgLoading = false
            if(this.emitLoading) this.$emit('loaded')
            return this
        },
        /**
         * Action background loading
         * @returns {default.methods}
         */
        bgLoading(){
            this.isBgLoading = true
            return this
        },
        /**
         * Action background loaded
         * @returns {default.methods}
         */
        bgLoaded(){
            this.isBgLoading = false
            return this
        },
        /**
         * Check if current loading
         * @returns {boolean}
         */
        isCurrentLoading(){
            return this.isLoading || this.isBgLoading
        }
    }
}
