<template>
	<div>
		<template v-if="user.picture_url">
			<img :src="user.picture_url" alt="Foto" :class="$tw('rounded-full w-8 h-8', imageClass)">
		</template>
		<template v-else-if="user.name">
			<p class="flex items-center justify-center bg-gray-400" :class="$tw('rounded-full w-8 h-8', letterClass)">
              <span class="font-medium leading-none text-white" :class="textClass">{{ twoLetters }}</span>
            </p>
        </template>
        <template v-else>
            <p class="block overflow-hidden" :class="$tw('bg-gray-100 rounded-full w-8 h-8', imageClass)">
				<svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
					<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
				</svg>
			</p>
		</template>
	</div>
</template>

<script>
export default {
	name: "ProfileImage",
	props: {
		user: {
			type: Object,
			required: true
		},
		textClass: String,
		letterClass: String,
		imageClass: String,
	},
	computed: {
		twoLetters() {
			if (!this.user.name) return false;

			const fragments = this.user.name.trim().split(' ');

			if (fragments.length == 1) return this.user.name.substr(0, 2)

			return fragments[0][0] + (fragments[1][0] ?? '');
		}
	}
}
</script>
