<template>
    <div>
        <div class="rounded-md shadow-sm relative">
            <textarea
				ref="input"
                class="rounded-md min-h-17 form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-700"
                :class="{
                    'error border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red' : validation && validation.$error,
                    'pb-8' : $slots.inner
                }"
                :type="type"
                :value="value"
                :rows="rows"
                :placeholder="placeholder"
                @input="$emit('input', $event.target.value)"
            ></textarea>
            <div v-if="validation && validation.$error" class="top-0 right-0 mt-3 pr-3 absolute flex items-center pointer-events-none">
                <i class="fa fa-exclamation-circle text-red-500"></i>
            </div>
            <div v-if="$slots.inner" class="absolute bottom-0 ml-3 mb-2 text-xs text-gray-500 flex items-center">
                <slot name="inner" />
            </div>
        </div>
        <ErrorBag v-if="validation" :validation="validation" v-bind="error" />
        <div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
            <slot name="info" />
        </div>
        <slot name="after" />
    </div>
</template>

<script>
    import ErrorBag from "@/components/ErrorBag";
    export default {
        name: "FormInput",
        components: { ErrorBag },
        props: {
            value: String,
            placeholder: String,
            validation: Object,
            error: Object,
            rows: [String, Number],
            type: {
                type: String,
                default: 'text'
            }
        },
		methods: {
			/**
			 * External
			 */
			focus(){
				this.$refs.input.focus()
			}
		}
    }
</script>
