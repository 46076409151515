<template>
	<ContextMenu @update:open="isContextMenuOpen = $event">
		<li class="py-2 cursor-pointer border rounded" @click="$emit('click', $event)" :class="isContextMenuOpen ?  'bg-gray-100 border border-gray-400' : 'border-transparent hover:bg-blue-100'">
			<div class="px-2 flex items-center">
				<!-- Status !-->
				<div class="mr-2 flex-none rounded-full p-1" :class="room.is_group ? 'text-gray-400 bg-gray-400/10' : 'text-red-400 bg-red-400/10'">
					<div class="h-2 w-2 rounded-full bg-current"></div>
				</div>
				<div>
					<!-- title !-->
					<h2 class="text-sm w-[250px] truncate text-gray-800">{{ room.title }}</h2>
					<!-- Last message !-->
					<p class="text-xs w-[250px] truncate text-gray-600">{{ room.last_message }}</p>
				</div>
			</div>
		</li>
		<template #items>
			<ContextMenuItem icon="fal fa-copy" text="Copiar" />
			<ContextMenuItem icon="fal fa-eraser" text="Apagar" divider />
		</template>
	</ContextMenu>
</template>
<script>
import ContextMenu from "@/components/ContextMenu.vue"
import ContextMenuItem from "@/components/ContextMenuItem.vue"

export default {
	name: "ChatAppItem",
	components: {ContextMenu, ContextMenuItem},
	props: {
		room: {
			type: Object,
			required: true
		}
	},
	data(){
		return {
			isContextMenuOpen: false
		}
	}
}
</script>