import {sync} from "vuex-pathify"

export default {
    computed: {
        ...sync('Notifications', ['events'])
    },
    methods: {
        $notify(title, message, opt) { // type = 'default', timeout = 5, withClose = false

            const notification = {}

            notification.title = title
            notification.show = true
            notification.message = message

            const defaults = {
                type: 'default',
                timeout: 5000,
                withClose: true
            }

            const config = {...defaults, ...opt}

            Object.getOwnPropertyNames(config).forEach((key) => notification[key] = config[key])

            this.events.push(notification)
        },
        $notifySuccess(title, message, opt){
            this.$notify(title, message, { type: 'success', withClose: true, ...opt })
        },
        $notifyInfo(title, message, opt){
            this.$notify(title, message, { type: 'info', withClose: true, ...opt })
        },
        $notifyWarning(title, message, opt){
            this.$notify(title, message, { type: 'warning', withClose: true, ...opt })
        },
        $notifyError(title, message, opt){
            this.$notify(title, message, { type: 'error', withClose: true, ...opt })
        },
        $notifyMessage(title, message, opt){
            this.$notify(title, message, { type: 'message', withClose: true, ...opt })
        }
    }
}
