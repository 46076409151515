<i18n src="@/i18n/components/CancelButton.json"></i18n>
<template>
	<button
		class="items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm border border-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
		v-on="$listeners" @click="handleClick">
		<i class="fa fa-times mr-2"></i>
		<slot>{{ $t('cancel_button.label') }}</slot>
	</button>
</template>
<script>
export default {
	name: "CancelButton",
	props: {
		route: [Object, String]
	},
	methods: {
		/**
		 * Handle click
		 */
		handleClick() {
			if(Object.keys(this.$listeners).length) return
			if (this.route) return this.$router.push(this.route)
			this.$router.go(-1)
		}
	}
}
</script>
