<template>
    <div>
        <div class="rounded-md shadow-sm relative" >
            <template v-if="maskOnly">
                <input
					ref="input"
                    class="w-full min-w-0 rounded-md sm:text-sm"
                    type="text"
                    :value="unformatedDateText"
                    placeholder="dd/mm/yyyy"
                    v-mask="`##/##/####`"
                    @input="handleDateInput($event.target.value, 'text')"
                />
            </template>
            <template v-else>
                <div class="hidden sm:block">
<!--                    <v-date-picker-->
<!--                        v-bind:key="`a`"-->
<!--                        :popover="{ visibility: 'focus' }"-->
<!--                        :inputProps="{ class : computedClass, placeholder: 'dd/mm/yyyy', type : 'text' }"-->
<!--                        -->
<!--                    />-->

                    <v-date-picker :value="value" :mode="mode" @input="handleDateInput($event)" :popover="{ visibility: 'focus' }">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
								ref="input"
                                type="text"
                                class="block w-full min-w-0 rounded-md sm:text-sm form-input"
                                :value="inputValue"
                                v-on="inputEvents"
                                :timezone="timezone"
                            />
                        </template>
                    </v-date-picker>

                </div>
            </template>
            <div class="sm:hidden">
                <input
                    v-bind:key="`b`"
                    type="date"
                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-700"
                    style="min-height: 42px"
                    :value="unformatedDate"
                    @input="handleDateInput($event.target.value)"
                />
            </div>
            <div class="inset-y-0 right-0 pr-3 hidden sm:flex absolute items-center pointer-events-none">
                <i class="fal fa-calendar-alt" :class="{ 'text-red-500' : hasError }"></i>
            </div>
        </div>
        <ErrorBag v-bind="error" :validation="validation" />
        <slot v-slot:afterError />
        <!-- Info !-->
        <div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
            <slot name="info" />
        </div>
    </div>
</template>

<script>
	import dayjs from "dayjs"
    import ErrorBag from "@/components/ErrorBag"
    export default {
        name: "FormDatePicker",
        props: {
            value: [String, Date],
            validation: Object,
            error: Object,
            maskOnly: Boolean,
            mode: {
                type: String,
                default: "date"
            },
            inputDefault: {
                type: String,
                default: 'form-input'
            },
            defaultInputClass: {
                type: String,
                default: 'form-input'
            },
            errorClass: {
                type: String,
                default: 'error'
            },
            type: {
                type: String,
                default: 'text'
            },
            timezone: {
                type: String,
                default: 'UTC'
            }
        },
        components: { ErrorBag },
        computed: {
            computedClass(){
                return [
                    this.defaultInputClass,
                    {
                        [this.errorClass]: this.hasError
                    }
                ]
            },
            hasError(){
                return this.validation?.$error
            },
            inputClassName(){

                const baseClass = [
                    'pr-10 form-input w-full block transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-700'
                ];

                if(this.hasError) {
                    baseClass.push('border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red')
                }

                return baseClass.join(' ')
            },
            currentDate(){
                if(!this.value || this.value.getDate || !this.value.replace) return this.value
                if(this.value.indexOf('-') != -1) return new Date(this.value)
                return this.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
            },
            unformatedDateText(){
                if(!this.value) return ''

                if(this.value.getDate) {
                    return dayjs(this.value).format('DD/MM/YYYY')
                }

                // return this.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
                return this.value.valueOf()
            },
            unformatedDate(){
                if(!this.value) return ''

                if(this.value.getDate) return dayjs(this.value).format('YYYY-MM-DD')

                // return this.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")

                return this.value
            }
        },
        methods: {
            handleDateInput(value, type){
                // if(!value) return;

                if(type == 'text' && value.length == 10) {
                    value = dayjs(value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1"))
                }

                this.$emit('input', value)
            },
			focus() {
				this.$refs.input.focus()
			}
        }
    }
</script>

<style>
.vc\-select select {
    background-image: none;
}
</style>