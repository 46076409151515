<template>
    <div>
        <!-- input wrap !-->
        <label class="relative flex items-center">
            <!-- Left icon !-->
            <div v-if="leftIcon" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i :class="leftIcon"></i>
            </div>
            <!-- Input !-->
            <input
                ref="input"
                class="form-checkbox h-4 w-4 transition duration-150 ease-in-out text-turquoise"

                :class="[
                    inputClass,
                    {
                        'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red' : validation && validation.$error,
                        'pl-10' : leftIcon,
                        'pr-10' : rightIcon
                    }
                ]"
                :checked="value"
                type="checkbox"
                :placeholder="placeholder"
                @input="$emit('input', $event.target.checked)"
            />
	        <span class="ml-2">{{ placeholder }}</span>
            <!-- Right Icon !-->
            <div v-if="rightIcon" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i :class="rightIcon"></i>
            </div>
            <!-- Validation Icon !-->
            <div v-if="validation && validation.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i class="fa fa-exclamation-circle text-red-500"></i>
            </div>
            <!-- Loading !-->
            <Loading v-if="loading" use-spinner />
        </label>
        <!-- /input wrap !-->
        <!-- ErrorBag !-->
        <ErrorBag v-if="validation && !hideValidationMessage" :placeholder="placeholder" :validation="validation" v-bind="error" />
        <!-- Info !-->
        <div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
            <slot name="info" />
        </div>
    </div>
</template>

<script>
    import Loading from "@/components/Loading"
    import ErrorBag from "@/components/ErrorBag"
    export default {
        name: "FormCheckbox",
        components: { Loading, ErrorBag },
        props: {
            value: [String, Number, Boolean],
            inputClass: String,
            placeholder: String,
            validation: Object,
            hideValidationMessage: Boolean,
            leftIcon: String,
            rightIcon: String,
            error: Object,
            loading: Boolean,
            type: {
                type: String,
                default: 'text'
            }
        },
        methods: {
            focus(){
                this.$refs.input.focus()
            }
        }
    }
</script>
