import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    /**
     *
     * @param params
     * @returns {Promise<[Error, T]>}
     */
    async list(params) {
        return to(Http.get(window.API_PREFIX + "/background_tasks", {params}))
    },
    /**
     *
     * @param id
     * @returns {Promise<[Error, T]>}
     */
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/background_tasks/${id}`))
    },
    /**
     *
     * @param id
     * @returns {Promise<[Error, T]>}
     */
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/background_tasks/${id}`, {'_method': 'delete'}))
    },
    /**
     *
     * @param id
     * @returns {Promise<[Error, T]>}
     */
    async clearAll() {
        return to(Http.post(`/background_tasks/clear`, {'_method': 'delete'}))
    }
}

