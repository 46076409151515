<i18n src="@/i18n/components/Notifications.json"></i18n>
<template>
	<div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
		<div class="flex relative">
			<!-- Content !-->
			<div class="flex-1 p-4">
				<div class="flex items-center">
					<!-- Icon !-->
					<div class="flex-shrink-0 px-1">
						<i class="fal text-2xl" :class="notificationIconClass"></i>
					</div>
					<!-- Title !-->
					<div class="ml-3 w-0 flex-1 pt-0.5">
						<p class="text-sm font-medium text-gray-900">{{ notification.code }}</p>
						<div class="mt-1 text-sm text-gray-500">
							<p v-html="notification.message"/>
						</div>
					</div>
					<!-- Close !-->
					<template v-if="!hasActions">
						<div class="ml-4 flex flex-shrink-0 self-start">
							<button type="button"
									class="items-center justify-center inline-flex rounded-full bg-white text-gray-400 hover:text-red-500 focus:text-red-500"
									@click="handleClose">
								<i class="fa fa-times-circle"></i>
							</button>
						</div>
					</template>
					<!-- / Close !-->
				</div>
			</div>
			<!-- Actions !-->
			<template v-if="hasActions">
				<div class="flex border-l border-gray-200" :class="{
						'divide-y divide-gray-200 flex-col' : notification.actions.length > 1
					}">
					<!-- One Action !-->
					<template v-if="notification.actions.length === 1">
						<button
							type="button"
							class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-turquoise hover:text-turquoise focus:outline-none focus:ring-2 focus:ring-turquoise"
							@click="handleAction(notification.actions[0])">
							{{ $t("notification.actions." + notification.actions[0].label) }}
						</button>
					</template>
					<!-- / One Action !-->
					<!-- Multi Actions !-->
					<template v-else>
						<div class="flex h-0 flex-1">
							<!-- Action !-->
							<button
								type="button"
								class="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-turquoise hover:text-turquoise focus:z-10 focus:outline-none focus:ring-2 focus:ring-turquoise"
								@click="handleAction(notification.actions[0])">
								{{ $t("notification.actions." + notification.actions[0].label) }}
							</button>
						</div>
						<div class="flex h-0 flex-1">
							<!-- Action !-->
							<button
								type="button"
								class="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-turquoise"
								@click="handleAction(notification.actions[1])">
								{{ $t("notification.actions." + notification.actions[1].label) }}
							</button>
						</div>
					</template>
					<!-- / Multi Actions !-->
				</div>
			</template>
			<!-- / Actions !-->
			<!-- Loading !-->
			<Loading v-if="isActionLoading" use-spinner spinner-color="text-turquoise"/>
		</div>
	</div>
</template>

<script>
import Loading from "@/components/Loading.vue"
import HandleCloseMixin from "./mixins/handle_close.js"
import HandleActionMixin from "./mixins/handle_action.js"

export default {
	name: "SimpleNotification",
	mixins: [
		HandleCloseMixin,
		HandleActionMixin
	],
	props: {
		notification: {
			type: Object,
			required: true
		}
	},
	components: {Loading},
	computed: {
		/**
		 * Calculate notification type icon class
		 * @returns {*}
		 */
		notificationIconClass() {
			return {
				'success': 'fa-check-circle text-green-500',
				'warning': 'fa-warning text-yellow-500',
				'error': 'fa-times-circle text-red-500',
			}[this.notification.notification_status]
		}
	},
	methods: {
		// .. handleAction
		// .. handleClose
		// .. delayClose
	}
}
</script>
