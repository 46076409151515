<template>
    <div>
        <template v-if="src">
            <img :src="src" alt="Foto" :class="[`h-${size} w-${size}`, `rounded-${rounded}`, shadow]">
        </template>
        <template v-else-if="letters">
            <span class="inline-flex items-center justify-center bg-gray-400" :class="[`rounded-${rounded}`,`h-${size} w-${size}`]">
              <span class="font-medium leading-none text-white" :class="{
                  'text-xs': size <= 8,
                  'text-sm': size > 8 && size <= 10,
                  'text-md': size > 10 && size <= 12,
                  'text-lg': size > 12 && size <= 14,
                  'text-xl': size > 14,
              }">{{ twoLetters }}</span>
            </span>
        </template>
        <template v-else>
            <span class="block overflow-hidden bg-gray-100" :class="[`rounded-${rounded}`,`h-${size} w-${size}`]">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                </svg>
            </span>
        </template>
    </div>
</template>

<script>
    export default {
        name: "ProfileImage",
        props: {
            src: {
                type: String,
                // default: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
            },
            letters: String,
            rounded: {
                type: String,
                default: 'full'
            },
            size: {
                type: [String, Number],
                default: 8
            },
            noShadow: Boolean
        },
        computed: {
            twoLetters(){
                if(! this.letters) return this.letters;

                const fragments = this.letters.trim().split(' ');

                if(fragments.length == 1) return this.letters.substr(0, 2)

                return fragments[0][0] + (fragments[1][0] ?? '');
            },
            shadow(){
                return this.noShadow ? '' : 'shadow'
            }
        }
    }
</script>
