var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'relative' : _vm.loading}},[_c(_vm.tag,_vm._g({tag:"component",staticClass:"group relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 focus:outline-none font-medium rounded-sm text-white transition duration-150 ease-in-out cursor-pointer focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-turquoise",class:[
                _vm.buttonClass,
                ( _obj = {
                    'shadow' : !_vm.noShadow,
                    'pl-8' : _vm.icon && _vm.iconPosition == 'left',
                    'pr-8' : _vm.icon && _vm.iconPosition == 'right',
                    'border-gray-300' : ! _vm.color && !_vm.noBorder,
                    'text-gray-700' : ! _vm.color}, _obj[("bg-" + _vm.color + "-500 hover:bg-" + _vm.color + "-400 active:bg-" + _vm.color + "-600")] = (_vm.disabled === false || !_vm.disabled), _obj["bg-gray-300 text-gray-400"] = (_vm.disabled === true || _vm.disabled == 'disabled'), _obj )],attrs:{"disabled":_vm.disabled}},_vm.$listeners),[(_vm.icon && _vm.iconPosition == 'left')?_c('span',{staticClass:"absolute left-0 inset-y-0 flex items-center pl-3"},[_c('i',{staticClass:"transition ease-in-out duration-150",class:[_vm.icon, ( _obj$1 = {}, _obj$1[("text-" + _vm.iconColor + " group-hover:text-" + _vm.iconHoverColor)] = (_vm.disabled === false || !_vm.disabled), _obj$1 ) ]})]):_vm._e(),_vm._t("default"),(_vm.icon && _vm.iconPosition == 'right')?_c('span',{staticClass:"absolute right-0 inset-y-0 flex items-center pr-3"},[_c('i',{staticClass:"transition ease-in-out duration-150",class:[_vm.icon, ( _obj$2 = {}, _obj$2[("text-" + _vm.iconColor + " group-hover:text-" + _vm.iconHoverColor)] = (_vm.disabled === false || !_vm.disabled), _obj$2 )]})]):_vm._e()],2),(_vm.loading)?_c('Loading',{attrs:{"use-spinner":"","spinner-color":"blue-900"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }