<i18n src="@/i18n/error.json"/>
<template>
    <div id="Auth">
        <div class="w-full absolute flex justify-center items-center sm:top-0 z-30">
            <AppModal/>
        </div>
        <router-view/>
		<!-- Notifications !-->
		<NotificationsContainer/>
		<!-- Alerts !-->
		<AlertsContainer />
    </div>
</template>

<script>
import {dispatch} from "vuex-pathify"
import AlertsContainer from "@/components/Alerts/AlertsContainer.vue"
import NotificationsContainer from "@/components/Notifications/NotificationsContainer.vue"
import AppModal from "@/layout/partials/AppModal.vue"

export default {
    name: "Auth",
    components: {
		AlertsContainer,
		NotificationsContainer,
		AppModal
    },
    async created() {
        let root = document.documentElement;

        for (let cssVar in window.CSS_VARS) {
            root.style.setProperty('--' + cssVar, window.CSS_VARS[cssVar]);
        }

        await dispatch("Auxiliar/init")
		await dispatch("Notifications/init")
        await dispatch("Locales/init")
    }
};
</script>
<style type="text/css">
#Auth {
    background: url('@/assets/Background.png') no-repeat center top;
    background-size: cover;
}
</style>
