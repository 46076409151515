import {make} from "vuex-pathify"
import User from "@/API/User"

const state = {
    isLoading: false,
    current: { },
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    async load({commit, dispatch}) {

        dispatch('loading')

        const [err, res] = await User.current()

        dispatch('loaded')

        if (err) {
            console.error(err)
            commit('SET_CURRENT', {})
            return false
        }

        commit('SET_CURRENT', res.data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
