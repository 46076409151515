<i18n src="@/i18n/enums/support_ticket_status.json"></i18n>
<template>
    <span :class="badgeClass" class="px-2.5 py-0.5 inline-flex items-center rounded-full font-medium leading-4">
        {{ badgeLabel }}
    </span>
</template>

<script>
import {hash as StatusOptions} from "@/enums/support_ticket_status.js"

export default {
	name: "SupportTicketStatusBadge",
	props: {
		support_ticket: Object
	},
	computed: {
		status() {
			return translateEnum(this, StatusOptions, 'support_ticket_status.')
		},
		badgeClass() {
			return this.status[this.support_ticket.support_ticket_status]?.class
		},
		badgeLabel() {
			return this.status[this.support_ticket.support_ticket_status]?.text
		}
	}
}
</script>