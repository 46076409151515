import Vue from "vue"
import Vuex from "vuex"
import pathify from 'vuex-pathify'

import APP from "./stores/APP"
import Auxiliar from "./stores/Auxiliar"
import Domain from "./stores/Domain"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        APP,
        Auxiliar,
        Domain
    },
    plugins: [ pathify.plugin ]
})
