<template>
	<button class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm">
		<span>Meus Filtros</span>
		<i class="fa fa-caret-down ml-2 text-xs"></i>
	</button>
</template>

<script>
export default {
	name: "ShipmentCustomFilterButton"
}
</script>