<template>
	<div class="relative text-xs sm:leading-6">
		<div class="relative">
			<!-- List !-->
			<ul class="flex flex-wrap items-center space-x-2">
				<template v-for="(item, index) in address">
					<!-- Item !-->
					<li class="relative inline-flex items-center font-medium" :class="tagClass(item.address_type, index)">
						<!-- Tag Icon !-->
						<i class="mr-2" :class="addressTypeIcon(item.address_type)"></i>
						<!-- Tag Label !-->
						{{ localized(item) }}
						<!-- Option Selected !-->
						<template v-if="item.option">
							<i class="fal fa-chevron-right mx-2"></i>
							<div>
								<i class="fa mx-2" :class="addressTypeIcon(item.option.address_type)"></i>
								<span>{{ item.option ? item.option.label : '' }}</span>
							</div>
						</template>
						<template v-if="index != address.length-1">
							<i class="ml-2 fa fa-pipe text-gray-400"></i>
						</template>
					</li>
					<!-- / Item !-->
				</template>
			</ul>
			<!-- /List !-->
		</div>
	</div>
</template>

<script>

import Loading from "@/components/Loading.vue"

import SupportTickets from "@/API/backoffice/SupportTickets.js"

export default {
	name: "SupportTicketAddressList",
	components: {Loading},
	model: {
		prop: 'address',
		event: 'update:address'
	},
	props: {
		address: {
			type: [Array],
		},
		dropClass: String,
	},
	data() {
		return {
			searchEventThrottle: null,
			text: '',
			eventBind: false,
			showResult: false,
			showOptionsResult: {},
			isLoading: false,
			isLoadingOptions: {},
			isSearchLoading: false,
			optionList: {},
			searchList: [],
			currentTagIndex: null,
			currentSearchIndex: null,
		}
	},
	computed: {
		/**
		 * Calculate if has items
		 * @returns {boolean}
		 */
		hasItems() {
			return this.searchList?.length > 0
		},
		/**
		 * Calculate if can show result
		 * @returns {boolean}
		 */
		canShowResult() {
			return this.showResult && this.text
		},
	},
	methods: {
		/**
		 * Format label for any item
		 * @param item
		 * @returns {string}
		 */
		localized(item) {
			const label = []

			if (item.code) {
				label.push(`${item.code} - `)
			}

			if (typeof item.label === 'string') {
				label.push(item.label)
			} else {
				label.push(this.$localize(item.label))
			}

			return label.join('')
		},
		/**
		 * Calculate item has options
		 * @param address_type
		 * @returns {*}
		 */
		hasOptions(address_type) {
			return address_type !== 'user'
		},
		/**
		 * Calculate search item icon
		 * @param address_type
		 * @returns {*}
		 */
		addressTypeIcon(address_type) {
			return {
				'user': 'fal fa-user',
				'company': 'fa fa-building',
				'partner': 'fa fa-building',
				'provider': 'fal fa-truck',
				'department': 'fal fa-layer-group',
			}[address_type]
		},
		/**
		 * Calculate search tag icon
		 * @param address_type
		 * @returns {*}
		 */
		tagClass(address_type) {
			// return {
			// 	'user': 'text-gray-800',
			// 	'company': 'text-gray-800',
			// 	'partner': 'text-gray-800',
			// 	'provider': 'text-gray-800',
			// 	'department': 'text-gray-800',
			// }[address_type]
		}
	}
}
</script>