import {make} from "vuex-pathify"
import TrackingEvents from "@/API/TrackingEvents.js"

const state = {
    isLoading: false,
    isSelectLoading: false,
    form: {
        code: null,
        description: null,
        short_description: null,
        title_locale: {},
        description_locale: {},
        short_description_locale: {},
    },
    page: [],
    select: []
}

const mutations = make.mutations(state)

const actions = {
    async loadSelect({commit, state}) {

        if(state.isSelectLoading) return false

        commit('SET_IS_SELECT_LOADING', true)

        const [err, res] = await TrackingEvents.getSelect()

        commit('SET_IS_SELECT_LOADING', false)

        if (err) {
            console.error("Store/TrackingEvents@loadSelect: Error on loading select.", err)
            commit('SET_SELECT', [])
            return false
        }

        commit('SET_SELECT', res.data.data)

        return true
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
