<i18n src="@/i18n/components/TableFilter.json"></i18n>
<template>
	<!-- Wrap !-->
	<header class="relative">
		<!-- Filtro Basicos !-->
		<div>
			<!-- Content !-->
			<div class="px-4 sm:px-0 flex flex-wrap">
				<!-- Bulk Actions !-->
				<template v-if="actions">
					<div class="mr-4 flex-shrink-0 items-center hidden sm:flex">
						<FormSelect :default-option-label="actions.label ? actions.label : $t('table_filter.actions')" :options="actions.options" v-model="action"
									:optLabel="actions.optLabel ? actions.optLabel : 'label'" @input="handleAction"/>
					</div>
				</template>
                <!-- Bulk Actions !-->
                <template v-if="actions">
                    <div class="mr-4 flex-shrink-0 items-center hidden sm:flex">
                        <slot name="after-action"></slot>
                    </div>
                </template>
				<!-- Filtros !-->
<!--				<span class="float-right sm:hidden select-none font-medium"-->
<!--					  :class="{ 'text-blue-700' : !show, 'text-red-600' : show }" @click="handleFilterBlock">-->
<!--					<i v-if="hasFilter" class="fa fa-eraser mr-1"></i>-->
<!--					{{ showFilter }}-->
<!--				</span>-->
				<!-- Titulo Mobile !-->
<!--				<h4 class="mr-4 sm:hidden text-base flex-shrink-0 cursor-pointer sm:cursor-auto" @click="handleFilterBlock">-->
<!--					<i class="fal fa-filter"></i>-->
<!--					<span class="font-semibold">{{ $t('table_filter.filter') }}</span>-->
<!--				</h4>-->
                <template v-if="selects && selects.length">
                    <div class="flex items-center">
                        <!-- Title !-->
                        <h4 class="mr-4 text-base hidden sm:block flex-shrink-0 cursor-pointer sm:cursor-auto" @click="handleFilterBlock">
                            <i class="fal fa-filter"></i>
                            <span class="ml-2 hidden md:inline">{{ $t('table_filter.filters') }}</span>
                        </h4>
                        <!-- Selects !-->
                        <template v-for="select in selects">
                            <FormSelect
                                :default-option-label="select.label ? select.label : $t('table_filter.all_filters')" :options="select.options" v-model="filter[select.filter]"
                                :optLabel="select.optLabel ? select.optLabel : 'label'"
                                :optValue="select.optValue ? select.optValue : 'id'"
                                @change="handleFilter" class="w-full sm:w-auto sm:mr-2"/>
                        </template>
                        <button v-if="$slots['advanced-filter']" class="flex-shrink-0 ml-2 focus:outline-none" @click="avancado = !avancado">
                            <i v-if="!avancado" class="mr-2 fa fa-plus text-green-400"></i>
                            <i v-else class="mr-2 fa fa-minus text-red-400"></i> {{ $t('table_filter.view_more') }}
                        </button>
                        <button v-if="hasFilter" class="hidden sm:block select-none font-medium cursor-pointer text-red-600" @click="handleClearFilters">
                            <i class="fa fa-eraser mr-1"></i>
                            {{ $t('table_filter.clear_filters') }}
                        </button>
                    </div>
                </template>
				<!-- Filtro Textual !-->
				<label class="w-full mt-3 sm:mt-0 sm:ml-auto sm:w-auto sm:ml-auto mb-0 sm:flex-shrink-0">
                    <div class="md:max-w-xs rounded-md shadow-sm flex relative">
                        <FormInput v-model="filter.search" @enter="handleFilter"/>
                        <button @click="handleFilter" class="mx-3 top-0 right-0 absolute rounded focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-turquoise z-10" style="margin-top: 9px">
                            <i class="fa fa-search text-gray-400 "></i>
                        </button>
                    </div>
				</label>
				<!-- /Filtros !-->
			</div>
			<!-- /Content !-->
		</div>
		<!-- Filtro Avancados !-->
		<div v-if="avancado" ref="filterAdvanced" class="mt-4 p-4 bg-gray-100">
			<div class="p-4 pb-6 bg-white rounded shadow-sm">
                <h3 class="font-bold">Filtro avançado</h3>
                <div class="mt-4">
                    <slot name="advanced-filter"></slot>
                </div>
            </div>
		</div>
	</header>
	<!-- /Wrap !-->
</template>
<script>
import FormInput from "@/components/FormInput.vue"
import FormSelect from "@/components/FormSelect.vue"

export default {
	name: "TableFilter",
	props: {
		value: Object,
		selects: Array,
		actions: Object
	},
	components: {
		FormSelect,
        FormInput
	},
	data() {
		return {
			show: false,
			filter: {
				search: null
			},
			action: null,
			avancado: false
		}
	},
	computed: {
		hasFilter() {
			return Object.keys(this.filter).some(key => key == 'page' ? false : this.filter[key])
		},
		showFilter() {
			if (this.hasFilter) return this.$t('table_filter.clear_filters') // 'Limpar filtros'
			if (this.show) return this.$t('table_filter.hide_filters') // Esconder filtros
			return this.$t('table_filter.show_filters') // Mostar filtros
		}
	},
	methods: {
		handleClearFilters() {
			Object.keys(this.filter).forEach(key => this.filter[key] = null)
			this.handleFilter()
		},
		handleFilter() {
			this.$emit('input', this.filter)
			this.$emit('filter', this.filter)
		},
		handleFilterBlock() {
			if (this.hasFilter) {
				this.handleClearFilters()
				return false
			}
			this.show = !this.show
			// this.$refs.filterBlock.classList.toggle('hidden')
		},
		handleAction() {
			this.$emit('bulk-action', this.action, this.actions.options[this.action])
			setTimeout(() => this.action = "", 0)
		}
	}
}
</script>
