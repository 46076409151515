<i18n src="@/i18n/components/BackButton.json"></i18n>
<template>
    <button
        class="items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm border border-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        @click="handleBack">
        <i class="fa fa-arrow-turn-down-left mr-2"></i>
        <slot>{{ $t('back_button.label') }}</slot>
    </button>
</template>
<script>
export default {
    name: "BackButton",
	methods: {
		/**
		 * Handle back button click
		 */
		handleBack() {
			this.$router.back()
		}
	}
}
</script>
