import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get(window.API_PREFIX + `/warehouses`, {params}))
    },
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/warehouses/${id}`))
    },
    async getSelect(params = {}) {
        return this.list({_type: "select", ...params})
    },
    async insert(data) {
        return to(Http.post(window.API_PREFIX + `/warehouses`, data))
    },
    async update(id, data) {
        data.append("_method", "patch")
        return to(Http.post(window.API_PREFIX + `/warehouses/${id}`, data))
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/warehouses/${id}`, {'_method': 'delete'}))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.insert(data)
    },
    async providerList(provider, id, params) {
        return to(Http.get(window.API_PREFIX + `/provider-warehouses/${id}`, params))
    },
}

