import {sync} from "vuex-pathify"

import AlertMixin from "@/mixins/alert"
import SlideoverMixin from "@/mixins/slideover"
import ModalMixin from "@/mixins/modal"
import NotificationMixin from "@/mixins/notification"

export default {
    mixins: [AlertMixin, ModalMixin, NotificationMixin, SlideoverMixin],
    computed: {
        ...sync('APP', ['alert', 'modal', 'slideover'])
    }
}
