<template>
	<!-- Reply !-->
	<button type="button"
			class="relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
			v-on="$listeners">
		<i class="fa fa-arrow-turn-down-left text-gray-600 mr-1"></i>
		Responder
	</button>
</template>

<script>
export default {
	name: "SupportTicketReplyButton"
}
</script>