import {make} from "vuex-pathify"
import Clients from "@/API/Clients"

const state = {
    isLoading: false,
    form: {},
    page: {},
    select: [],
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    async loadSelect({commit, dispatch}) {
        dispatch('loading')

        const [err, res] = await Clients.list({"_type": "select"});

        dispatch('loaded')

        if (err) {
            console.error(err)
        }

        commit('SET_SELECT', res.data?.data ?? [])
    },
    clearSelect({commit}) {
        commit('SET_SELECT', [])
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
