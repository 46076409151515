<template>
	<div class="fixed inset-0 px-4 py-6 sm:items-start sm:p-6 flex justify-center items-center top-0 z-[100] pointer-events-none">
		<!-- Notification List !-->
		<transition
			tag="div"
			appear
			appear-active-class="transform ease-out duration-300 transition"
			appear-class="-translate-y-2 opacity-0"
			appear-to-class="translate-y-0 opacity-100"
			enter-active-class="transform ease-out duration-300 transition"
			enter-class="-translate-y-2 opacity-0"
			enter-to-class="translate-y-0 opacity-100"
			leave-active-class="transition ease-in duration-100"
			leave-class="opacity-100"
			leave-to-class="opacity-0"
			class="flex w-full flex-col items-center space-y-4 sm:items-end">
			<SimpleAlert v-if="alert.show" v-bind="alert" @close="alert.show = false"/>
		</transition>
	</div>
</template>

<script>
import SimpleAlert from "./SimpleAlert.vue"
import {sync} from "vuex-pathify"

export default {
	name: "AlertsContainer",
	components: {SimpleAlert},
	computed: {
		...sync('APP', ['alert'])
	}
}
</script>
