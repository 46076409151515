<template>
	<div aria-live="assertive" class="pointer-events-none fixed flex flex-col inset-0 items-end px-4 py-6 sm:items-start sm:p-6 z-50">
		<!-- Notification List !-->
		<transition-group
			tag="div"
			type="transition"
			appear
			appear-active-class="transform ease-out duration-300 transition"
			appear-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			appear-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			enter-active-class="transform ease-out duration-300 transition"
			enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			leave-active-class="transition ease-in duration-200"
			leave-class="opacity-100"
			leave-to-class="opacity-0 -translate-y-2"
			class="flex w-full flex-col items-center space-y-4 sm:items-end"
			@after-leave="recalcShowNotifications">
			<template v-for="notification in notificationsToShow" >
				<component v-if="notification.visible" :key="`notification-${notification.id}`" :is="selectComponentByNotificationType(notification)" :notification="notification" @close="notification.visible = false" />
			</template>
		</transition-group>
		<!-- / Notification List !-->
		<!-- Has more notifications ... !-->
		<div class="mt-6 flex w-full flex-col items-center space-y-4 sm:items-end">
			<template v-if="countMoreNotifications > 0">
				<div class="w-full max-w-sm flex justify-center">
					<button class="px-3 py-1 bg-gray-700 bg-opacity-10 rounded-full shadow text-xs">+{{ countMoreNotifications }} notificações</button>
				</div>
			</template>
		</div>
		<!-- / Has more notifications !-->
		<transition-group
			tag="div"
			appear
			appear-active-class="transform ease-out duration-300 transition"
			appear-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			appear-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			enter-active-class="transform ease-out duration-300 transition"
			enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			leave-active-class="transition ease-in duration-100"
			leave-class="opacity-100"
			leave-to-class="opacity-0"
			class="mt-auto flex w-full flex-col items-center space-y-4 sm:items-end">
			<template v-for="(notification, index) in visibleProcess">
				<ProgressNotification v-if="visibleProcess" :key="`process-${notification.ref}`" :notification="notification" @close="notification.visible = false" />
			</template>
		</transition-group>
	</div>
</template>

<script>
import Notifications from "@/stores/Notifications.js"
import SimpleNotification from "./SimpleNotification.vue"
import ProgressNotification from "./ProgressNotification.vue"
import CondensedNotification from "./CondensedNotification.vue"
import SuccessNotification from "./SuccesNotification.vue"

export default {
	name: "NotificationsContainer",
	props: {
		showMax: {
			type: Number,
			default: 3
		}
	},
	components: {
		SimpleNotification,
		CondensedNotification,
		ProgressNotification
	},
	data() {
		return {
			// List of notifications to show
			notificationsToShow: [],
			processToShow: [],
		}
	},
	computed: {
		// Notifications in notifyLIst
		notifications() {
			return Notifications.state.notifyList
		},
		// Count total of hidden notifications
		countMoreNotifications(){
			if (this.notificationsToShow - this.showMax < 0) return 0
			return this.visibleNotifications.length - this.showMax
		},
		// Filter only visible notifications
		visibleNotifications(){
			return this.notifications?.filter(item => item.visible && item.notification_type !== 'process')
		},
		// Filter only visible process
		visibleProcess(){
			return this.notifications?.filter(item => item.visible && (item.notification_type === 'process' || item.notification_type === 'progress') )
		}
	},
	methods: {
		handleLeave(e){

		},
		/**
		 * Select correct Notification component by notification.type
		 * @param notification
		 * @returns {*}
		 */
		selectComponentByNotificationType(notification) {
			return {
				"text": 'SimpleNotification',
				"condensed": 'CondensedNotification',
			}[notification.notification_type]
		},
		/**
		 * Limit notifications to show
		 */
		recalcShowNotifications() {
			if(this.notificationsToShow.length -1 === this.showMax) return;
			this.notificationsToShow = this.visibleNotifications.slice(0, this.showMax)
		},
	},
	watch: {
		// Watch for new notifications
		notifications: {
			handler() {
				this.recalcShowNotifications()
			}
		}
	},
	// Prepare notifications
	mounted() {
		this.recalcShowNotifications()
	}
}
</script>
