<i18n src="@/i18n/shared/support/list.json"></i18n>
<template>
	<Page
		:title="$t('Suporte')"
		icon="fa fa-user-headset text-turquoise"
		class="min-h-full"
		:titleLoading="isBgLoading"
		@title-action="handleLoad(true)">
		<!-- Action !-->
		<template v-slot:after-title>
			<!-- Create !-->
			<CreateButton v-if="hasData" :to="{ name: 'supports.create' }" class="ml-auto"/>
		</template>
		<!-- Filters !-->
		<!-- Status Filters !-->
		<FilterStatusBar :page="page" :selected="filter.status" @select="handleSelectStatusFilter"/>
		<!-- Card !-->
		<Card title="Lista de chamados">
			<!-- Table !-->
			<template v-if="hasData">
				<AutoTable v-bind="table()" v-model="page.data" @row-click="handleSelected">
					<template #col-subject="{ data: { row } }">
						<p>{{ $localize(row.subject.title_locale) }}</p>
						<div class="mt-px inline-flex space-x-2 text-xs">
							<p class="inline-flex space-x-2">
								<span>
									<i class="fal fa-user text-xs"></i>
								</span>
								<span>{{ row.created_by.name }}</span>
							</p>
							<p class="inline-flex space-x-2">
								<span>
									<i class="fal fa-arrow-right text-xs"></i>
								</span>
								<span>{{ destinationAddresses(row) }}</span>
							</p>
						</div>
					</template>
					<template #col-status="{ data: { row } }">
						<!-- {{ row.support_ticket_status }} -->
						<SupportTicketStatusBadge :support_ticket="row"/>
					</template>
<!--					<template #col-@="{ data }">-->
<!--						<Dropdown :items="actions" dropClass="w-56" @input="handleAction($event, row, rowIndex)">-->
<!--							<button class="focus:outline-none">-->
<!--								<i class="fa fa-ellipsis-v text-gray-400 text-base"></i>-->
<!--							</button>-->
<!--						</Dropdown>-->
<!--					</template>-->
				</AutoTable>
				<Pagination v-bind="page.meta" @select-page="handleSelectPage"/>
			</template>
			<!-- / Table !-->
			<!-- Empty Filters !-->
			<template v-else-if="hasFilter">
				<p class="p-4 text-base text-center">
					{{ $t('support_list.no_results_for_filter') }}
				</p>
			</template>
			<!-- Empty !-->
			<template v-else>
				<div class="p-4 h-96 space-y-8 flex flex-col text-base justify-center items-center">
					<p>{{ $t('support_list.empty') }}</p>
					<CreateButton :to="{ name: 'supports.create' }" />
				</div>
			</template>
			<!-- / Table !-->
		</Card>
		<!-- / Card !-->
	</Page>
</template>

<script>
import Mixin from "./mixins/index.js"

import Page from "@/components/Page.vue"
import Card from "@/components/Card.vue"
import Loading from "@/components/Loading.vue"
import AutoTable from "@/components/AutoTable.vue"
import Pagination from "@/components/Pagination.vue"
import TableFilter from "@/components/TableFilter.vue"
import Dropdown from "@/components/Dropdown.vue"

import FormLine from "@/components/FormLine.vue"
import FormRadio from "@/components/FormRadio.vue"
import FormInput from "@/components/FormInput.vue"
import FormSelect from "@/components/FormSelect.vue"
import FormDatePicker from "@/components/FormDatePicker.vue"
import SupportTicketStatusBadge from "@/domain/support_tickets/SupportTicketStatusBadge.vue"
import FilterStatusBar from "./partials/FilterStatusBar.vue"
import CreateButton from "@/components/CreateButton.vue"

export default {
	name: "IndexPage",
	mixins: [Mixin],
	components: {
		CreateButton,
		Page,
		Card,
		Loading,
		FormLine,
		FormInput,
		FormSelect,
		FormRadio,
		FormDatePicker,
		AutoTable,
		Pagination,
		TableFilter,
		Dropdown,
		SupportTicketStatusBadge,
		FilterStatusBar
	},
	computed: {
		/**
		 * Check if has data
		 * @returns {boolean}
		 */
		hasData() {
			return this.page?.meta?.total > 0
		},
		hasFilter() {
			return false
		},
		actions() {
			return []
		}
	},
	methods: {
		/**
		 * Handle selected row
		 * @param row
		 */
		handleSelected(row) {
			this.$router.push({name: 'support_tickets.show', params: {id: row.id}})
		},
		/**
		 * Handle select page
		 * @param page
		 */
		handleSelectPage(page) {
			this.filter.page = page
			this.load()
		},
        /**
		 * Handle select filter status
         * @param evt
         */
		handleSelectStatusFilter(evt){
			this.filter.status = evt
			this.load()
		},
		destinationAddresses(row) {
			return row.addresses.map(item => {
				if(item.address_type === 'user') {
					return item.label
				}

				return this.$localize(item.label)
			}).join(', ')
		},
	},
	mounted() {
		this.init()
	}
}
</script>
