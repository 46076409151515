var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"container",staticClass:"flex flex-col bg-gray-50 border border-gray-200 pointer-events-auto rounded-md transform hover:shadow-xl transition-all",class:{
			'shadow-lg translate-y-0' : _vm.open,
			'w-[220px] shadow-lg translate-y-[calc(100%-50px)]' : !_vm.open ,
			'w-[500px] h-[700px]' : _vm.expanded && _vm.open,
			'w-[340px] h-[400px]' : !_vm.expanded && _vm.open,
			'h-[400px]' : !_vm.expanded && !_vm.open
		}},[_c('header',{staticClass:"p-4 flex items-center cursor-pointer",class:{ 'hover:bg-gray-100' : !_vm.open },on:{"click":_vm.handleOpen}},[_c('h3',{staticClass:"font-semibold truncate",class:{
			'max-w-[160px]' : !_vm.open,
			'max-w-[220px]' : _vm.open && !_vm.expanded,
			'max-w-[380px]' : _vm.open && _vm.expanded,
			}},[_vm._v(_vm._s(_vm.title))]),(_vm.open)?[_vm._m(0),_c('button',{staticClass:"ml-auto p-3 rounded-full text-[12px] hover:bg-gray-200 flex justify-center items-center",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.handleExpand.apply(null, arguments)}}},[_c('i',{staticClass:"absolute fal",class:_vm.expanded ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'})])]:_vm._e(),_c('button',{staticClass:"p-3 rounded-full text-[12px] hover:bg-gray-200 flex justify-center items-center",class:{ 'ml-auto' : !_vm.open, 'ml-3' : _vm.open },attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClose.apply(null, arguments)}}},[_c('i',{staticClass:"absolute fal fa-times text-lg"})])],2),(_vm.room.expanded && _vm.room.is_group)?_c('div',{staticClass:"p-2 flex space-x-2 border-y bg-gray100 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"},[_vm._l((_vm.room.members),function(member,index){return [_c('p',{key:("member-" + index),staticClass:"whitespace-nowrap bg-gray-100 rounded-md py-1.5 px-2.5 text-xs"},[_vm._v(_vm._s(member.name))])]})],2):_vm._e(),_c('div',{staticClass:"flex-1 flex flex-col p-2 border-t border-gray-200"},[_c('ChatMessageContainer',{ref:"scroll",attrs:{"members":_vm.room.members,"messages":_vm.room.messages}})],1),_c('div',{staticClass:"p-2 flex space-x-2 items-end"},[_c('textarea',{ref:"editor",staticClass:"form-input rounded w-full resize-none",attrs:{"rows":"1"},on:{"input":_vm.handleInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSend.apply(null, arguments)}}}),_vm._v(" "),_c('button',{staticClass:"py-3.5 px-3 flex space-x-2 items-center bg-blue-500 rounded text-white hover:bg-blue-600",attrs:{"type":"button"},on:{"click":_vm.handleSend}},[_c('i',{staticClass:"fa fa-paper-plane"})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"ml-3 rounded-full text-[12px] hover:bg-gray-200 flex justify-center items-center tabular-nums",attrs:{"type":"button"}},[_c('i',{staticClass:"ml-2 fal fa-users"})])}]

export { render, staticRenderFns }