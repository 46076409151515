import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async get() {
        return to(Http.get(`/locale`))
    },
    async set(locale) {
        return to(Http.post("/locale", {locale}))
    },
}

