import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get(window.API_PREFIX + "/packages", {params}))
    },
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/packages/${id}`))
    },
    async create(data) {
        return to(Http.post(window.API_PREFIX + `/packages`, data))
    },
    async update(id, data) {
        return to(Http.post(window.API_PREFIX + `/packages/${id}`, {...data, '_method': 'patch'}))
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/packages/${id}`, {'_method': 'delete'}))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.create(data)
    },
    async activate(id) {
        return to(Http.post(window.API_PREFIX + `/packages/${id}/activate`))
    },
    async deactivate(id) {
        return to(Http.post(window.API_PREFIX + `/packages/${id}/deactivate`))
    },
}

