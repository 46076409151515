import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import messages from "./i18n/exceptions.json"

const lang = document.getElementsByTagName("html")[0].lang
const v18n = new VueI18n({
    locale: lang.replace('_', '-'),
    fallbackLocale: 'en',
    messages
})

export async function loadLocale(file){
    v18n.setLocaleMessage('pt', messages[v18n.locale])
}

export default v18n