<template>
	<div ref="container" class="fixed inset-0 px-4 h-screen sm:items-start flex justify-end items-baseline z-[100] pointer-events-none">
		<div class="h-full w-full flex justify-end items-end space-x-6">
			<!-- Open Rooms !-->
			<template v-for="room in showRoom">
				<ChatRoom :key="`room-${room.id}`" :room="room" :open.sync="room.open" :expanded.sync="room.expanded" :show="room.show" @update:show="handleShowRoom(room, $event)"/>
			</template>
			<!-- Room Creation !-->
			<ChatRoomCreation v-if="openRoomCreation" :open.sync="openRoomCreation" @room-created="handleRoomCreated"/>
			<!-- APP !-->
			<ChatApp v-if="showMessenger" :hasNewMessages="false" :newMessagesCount="newMessagesCount" :rooms="rooms" :open.sync="openChat" @openRoom="handleOpenRoom" @createRoom="handleCreateRoom" @close="handleCloseApp"/>
		</div>
	</div>
</template>

<script>
import ChatApp from "@/domain/chat/ChatApp.vue"
import ChatRoom from "@/domain/chat/ChatRoom.vue"
import {useElementSize, useWindowSize} from "@vueuse/core";
import ChatRoomCreation from "@/domain/chat/ChatRoomCreation.vue";
import {get, sync} from "vuex-pathify";

export default {
	name: "ChatContainer",
	props: {},
	components: {
		ChatRoomCreation,
		ChatApp,
		ChatRoom
	},
	data() {
		return {
			hasNewMessages: true,
			windowSize: null,
			rooms: [
				// {
				// 	id: 1,
				// 	title: 'Yuri Ruano do Nascimento',
				// 	thumb: 't',
				// 	last_message: "Uma mensagem muito longa aqui por favor",
				// 	messages: [
				// 		{id: 1, body: "Uma mensagem muito longa aqui por favor", from_member_id: 1, created_at: new Date()},
				// 		{id: 2, body: "Uma mensagem muito longa aqui por favor", from_member_id: 2, created_at: new Date()},
				// 		{id: 3, body: "Uma mensagem muito longa aqui por favor", from_member_id: 2, created_at: new Date()},
				// 		{id: 4, body: "Uma mensagem muito longa aqui por favor", from_member_id: 1, created_at: new Date()},
				// 		{
				// 			id: 5,
				// 			body: "Uma mensagem muito longa aqui por favor\nUma mensagem muito longa aqui por favor\nUma mensagem muito longa aqui por favor",
				// 			from_member_id: 2,
				// 			created_at: new Date()
				// 		},
				// 		{id: 6, body: "Uma mensagem muito longa aqui por favor", from_member_id: 2, created_at: new Date()},
				// 		{id: 7, body: "Uma mensagem muito longa aqui por favor", from_member_id: 1, created_at: new Date()},
				// 		{id: 8, body: "Uma mensagem muito longa aqui por favor", from_member_id: 1, created_at: new Date()},
				// 		{id: 9, body: "Uma mensagem muito longa aqui por favor", from_member_id: 1, created_at: new Date()},
				// 		{id: 10, body: "Uma mensagem muito longa aqui por favor", from_member_id: 2, created_at: new Date()},
				// 		{id: 11, body: "Uma mensagem muito longa aqui por favor", from_member_id: 2, created_at: new Date()},
				// 		{id: 12, body: "Uma mensagem muito longa aqui por favor\nUma mensagem muito longa aqui por favor", from_member_id: 2, created_at: new Date()},
				// 		{id: 13, body: "Uma mensagem muito longa aqui por favor", from_member_id: 2, created_at: new Date()},
				// 	],
				// 	members: [
				// 		{id: 1, name: 'Yuri Ruano do Nascimento', thumb: 't'},
				// 		{id: 2, name: 'Henry Jooji Kitadani Sato', thumb: 't'}
				// 	],
				// 	show: false,
				// 	open: false,
				// 	expanded: false,
				// 	is_group: false,
				// },
				// {id: 2, title: 'Cristiano Rossmann', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 3, title: 'Henry Jooji Kitadani Sato', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 4, title: 'Ana Belato', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 5, title: 'Ricardo Teixeira', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 6, title: 'Laura Scala', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 7, title: 'Micael Keaton Santos', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 8, title: 'Vinicius Silva', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 9, title: 'Grupo de conversa', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: true},
				// {id: 10, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 11, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 12, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 13, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 14, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 15, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 16, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 17, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// {id: 18, title: 'teste', thumb: 't', last_message: "Ultima menssagem", messages: [], show: false, open: false, expanded: false, is_group: false},
				// // {id: 19, title: 'teste', thumb: 't', last_message: {body: "Ultima menssagem"}, messages: [], show: false, open: false, expanded: false},
			],
			openChat: false,
			openChatRoom: false,
			openRoomCreation: false,
			newMessagesCount: 0,
		}
	},
	computed: {
		showMessenger: sync('APP/showMessenger'),
		showRoom() {
			return this.rooms.filter(room => room.show)
		}
	},
	methods: {
		handleCloseApp(){
			this.showMessenger = false
			this.openRoomCreation = false
			this.rooms.forEach(room => {
				room.show = false
				room.open = false
				room.expanded = false
			})
		},
		handleShowRoom(room, e) {
			room.show = e
			room.expanded = e
		},
		handleCreateRoom() {
			this.openRoomCreation = true
		},
		handleOpenRoom(e) {
			this.rooms[e.index].show = true
			this.rooms[e.index].open = true
			this.calculateOpenBySize();
		},
		calculateOpenBySize() {
			const appSize = 220 + 24
			const roomSize = 300 + 24
			const roomOpenSize = 340 + 24
			const roomOpenExpandedSize = 500 + 24

			const total = appSize + (this.showRoom.reduce((acc, room) => {
				if (room.open) {
					if (room.expanded) {
						return acc + roomOpenExpandedSize
					}
					return acc + roomOpenSize
				}
				return acc + roomSize
			}, 0))

			if (total > this.windowSize) {
				this.showRoom[this.showRoom.length - 1].show = false
				this.showRoom[this.showRoom.length - 1].open = false
			}
		},
		handleRoomCreated(room) {
			this.rooms.push(room)
		}
	},
	watch: {
		showMessenger(value) {
			if (value) {
				this.openChat = true
			}
		}
	},
	mounted() {
		this.windowSize = this.$refs.container.getBoundingClientRect().width
	}
}
</script>