import {enum_to_hash} from "@/helpers.js"

export default {
    "CLOSED": 0,
    "OPEN": 10,
    "WAITING": 20,
    "ARCHIVED": 99,
}

export const options = [
    {id: 0, text: 'Closed', class: "bg-gray-200"},
    {id: 10, text: 'Open', class: "bg-green-200 text-green-800"},
    {id: 20, text: 'Waiting', class: "bg-yellow-200 text-yellow-800"},
    {id: 99, text: 'Archived', class: "bg-gray-200"},
]

export const hash = enum_to_hash(options)