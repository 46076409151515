<template>
	<div class="mt-4 min-w-sm pointer-events-auto" :class="{'absolute top-0 mt-6' : float}">
		<div class="w-full px-4 py-3 rounded-md shadow-md" :class="classByVariant">
			<div class="flex relative items-center">
				<div v-if="typeIcon" class="flex-shrink-0">
					<i :class="[typeIcon, ]"></i>
				</div>
				<div class="w-full" :class="{ 'ml-3' : typeIcon }">
					<h3 v-if="title" :class="titleColor" class="text-sm leading-5 font-medium">{{ title }}</h3>
					<template v-if="current">
						<div v-if="current.length > 1" class="text-sm leading-5" :class="[{ 'mt-2' : title }]">
							<ul :class="{ 'list-disc pl-5' : title && current.length }">
								<li v-for="(item, index) in current" :key="index">
									a {{ item }}
								</li>
							</ul>
						</div>
						<div v-else class="text-sm leading-5" :class="[{ 'mt-2' : title }]" v-html="current[0]"></div>
					</template>
					<div v-if="verMais" class="mt-4">
						<div class="-mx-2 -my-1.5 flex">
							<button @click="handleViewMore" :class="titleColor" class="px-2 py-1.5 rounded-md text-sm leading-5 font-medium transition ease-in-out duration-150">
								Ver detalhes
							</button>
							<button @click="handleDiscard" :class="titleColor" class="ml-3 px-2 py-1.5 rounded-md text-sm leading-5 font-medium transition ease-in-out duration-150">
								Descartar
							</button>
						</div>
					</div>
				</div>
				<p v-if="link" class="mt-3 text-sm leading-5 md:mt-0 md:ml-6">
					<a :href="link" class="whitespace-no-wrap font-medium transition ease-in-out duration-150">
						Detalhes <i class="fa fa-chevron-right"></i>
					</a>
				</p>
				<button v-if="!link && withClose" class="ml-4 flex-shrink-0 focus:outline-none focus:shadow-outline-red" @click="handleClose">
					<i class="fa fa-times"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SimpleAlert",
	props: {
		value: Boolean,
		variant: {
			type: String,
			default: 'message'
		},
		float: Boolean,
		message: [String, Array, Object],
		title: String,
		link: String,
		time: {
			type: [Number, Boolean],
			default: 5000
		},
		verMais: Function,
		withClose: Boolean
	},
	data() {
		return {
			timeout: null,
			cssTypes: {
				'error': 'bg-red-400 text-white',
				'info': 'bg-blue-500 text-white',
				'warning': 'bg-yellow-500 text-white',
				'success': 'bg-green-500 text-white',
				'default': 'bg-gray-500 text-white',
			},
			titleColors: {
				'error': 'text-red-800',
				'info': 'text-blue-800',
				'warning': 'text-yellow-800',
				'success': 'text-green-800',
				'default': 'text-blue-800',
			},
			icons: {
				'error': 'text-lg text-lg fa fa-times-circle',
				'warning': 'text-lg fa fa-exclamation-triangle',
				'info': 'text-lg fa fa-info-circle',
				'success': 'text-lg fa fa-check-circle',
				'default': ''
			}
		}
	},
	computed: {
		current() {
			if (typeof this.message === 'string') return [this.message]
			if (typeof this.message === 'object') {

				let list = [];

				Object.values(this.message).forEach(item => {
					list = [...list, ...item]
				})

				return list
			}

			return this.message;
		},
		icon() {
			switch (this.variant) {
				case 'message':
					return 'gray'
			}

			return ''
		},
		color() {
			switch (this.variant) {
				case 'message':
					return 'gray'
			}

			return ''
		},
		classByVariant() {
			return this.cssTypes[this.variant] ?? this.cssTypes['default']
		},
		titleColor() {
			return this.titleColors[this.variant] ?? this.titleColors['default']
		},
		typeIcon() {
			return this.icons[this.variant] ?? this.icons['default']
		}
	},
	methods: {
		handleClose() {
			this.close()
		},
		handleViewMore() {
			if (this.verMais) this.verMais()
			this.close()
		},
		handleDiscard() {
			this.close()
		},
		close() {
			this.$emit('close', false)
		},
		startTimeOut() {
			if (this.timeout) clearTimeout(this.timeout)
			if (this.time) {
				this.timeout = setTimeout(this.close, this.time)
			}
		}
	},
	mounted() {
		this.startTimeOut()
	}
}
</script>
