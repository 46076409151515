import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    page: [],
    form: {},
    view: {
        form: {},
        can: {}
    }
}

const mutations = make.mutations(state)

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
