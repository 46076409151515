import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    form: {
        person_type: 1,
        document_type: 1
    }
}

const mutations = make.mutations(state)

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
