<template>
    <section class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div class="max-w-md w-full">
            <!-- header !-->
            <header>
                <!-- Logo !-->
	            <div class="w-52 mx-auto mt-4">
                    <img src="/images/logo.gif" alt="">
                </div>
                <!-- title !-->
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Recupere seu acesso
                </h2>
                <p class="mt-2 text-center text-sm leading-5 text-gray-600">
                    Ou
                    <router-link to="/" class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                        entre com sua conta
                    </router-link>
                </p>
            </header>
            <!-- /header !-->
            <!-- content !-->
            <form class="mt-8" action="/register" method="POST">
                <!-- Credentials !-->
                <div class="rounded-md">
                    <label>
                        <span class="mb-1 block text-sm font-medium leading-5 text-gray-700">Email de acesso</span>
                        <FormInput v-model="fields.email" :validation="v$fields.email"/>
                    </label>
                </div>
                <!-- / Credentials !-->
                <!-- Actions !-->
                <div class="mt-6">
                    <DefaultButton icon="fa fa-save" color="blue" class="w-full" @click.prevent="handleSend">Recuperar</DefaultButton>
                </div>
            </form>
            <!-- /content !-->
        </div>

        <Loading v-if="isLoading" overlay-opacity="75"/>
    </section>
</template>

<script>
    import PageMixin from "@/pages/partner/auth/mixins/password_recovery"

    import Loading from "@/components/Loading"
    import FormInput from "@/components/FormInput"
    import DefaultButton from "@/components/DefaultButton"

    export default {
        name: "PasswordRecoveryPage",
        mixins: [PageMixin],
        components: {
            DefaultButton, Loading, FormInput
        },
        methods: {
            handleSend() {
                this.send()
            }
        }
    }
</script>
