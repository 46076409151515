<template>
	<div ref="container" tabindex="1" class="relative flex-1 flex flex-col">
		<div ref="scroll" class="relative flex-1 flex flex-col overflow-y-scroll">
			<div class="h-0 flex flex-col px-1 space-y-4">
				<template v-for="(message, index) in messages">
					<ChatMessage :showMember="is_group || !chatRoomState.expanded" :member="findMember(message)" :message="message" :key="message.id" :direction="message.from_member_id === 1 ? 'end' : 'start'" />
				</template>
			</div>
		</div>
		<div v-if="chatRoomState.open && !autoscroll" class="absolute bottom-0 left-0 right-0 flex justify-center pointer-events-none">
			<button type="button" class="rounded bg-blue-500 hover:bg-blue-600 text-white font-semibold px-2.5 py-1.5 text-xs shadow-lg pointer-events-auto" @click="scrollEnd('smooth')">
				<i class="fal fa-angle-down"></i>
				<span class="ml-2">Mensagens mais recentes</span>
			</button>
		</div>
	</div>
</template>

<script>
import { useScroll} from "@vueuse/core"
import ChatMessage from "@/domain/chat/ChatMessage.vue"

export default {
	name: "ChatMessageContainer",
	props: {
		members: Array,
		messages: Array,
		is_group: Boolean,
	},
	components: {
		ChatMessage,
	},
	inject: ['chatRoomState'],
	data(){
		return {
			showNewMessageAnchor: false,
			autoscroll: true,
			isScrolling: false,
			scroll: null
		}
	},
	methods: {
		findMember(message) {
			return this.members.find(member => member.id === message.from_member_id)
		},
		scrollEnd(method = 'auto'){
			this.$refs.scroll.scrollTo({
				top: this.$refs.scroll.scrollHeight,
				behavior: method
			})
		}
	},
	watch: {
		'scroll.arrivedState.bottom': function (val) {
			this.autoscroll = val
		},
		'chatRoomState.expanded': function (val) {
			setTimeout(() => {
				this.scrollEnd('smooth')
			}, 300)
		}
	},
	mounted() {
		this.scroll = useScroll(this.$refs.scroll)

		this.$refs.container.addEventListener('mouseenter', () => {
			document.body.classList.add('relative', 'overflow-hidden')
		})
		this.$refs.container.addEventListener('mouseleave', () => {
			document.body.classList.remove('relative', 'overflow-hidden')
		})
	}
}
</script>