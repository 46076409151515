import {serialize} from 'object-to-formdata'
import { storageAvailable } from "./support/storage.js"

/**
 *
 * @param {Array} arr
 * @returns {*}
 */
export function array_options(arr, filter) {
    if (!arr.length) {
        let keys = Object.getOwnPropertyNames(arr)
        if (filter) keys = keys.filter(filter)

        return keys.map((key) => {
            return {"label": arr[key], "id": key}
        })
    }

    if (filter) arr = arr.filter(filter)

    return arr.map((value, key) => {
        return {"label": value, "id": key}
    })
}

window.storageAvailable = storageAvailable

window.array_options = array_options

export function translateEnum(base, enumType, baseProp = '') {
    return enumType.map(item => {
        item.label = base.$t(baseProp + item.text)
        return item
    })
}

window.translateEnum = translateEnum

export function enum_to_hash(arr, key = 'id') {
    const obj = []

    for (let i = 0; i < arr.length; i++) {
        let item = arr[i]
        obj[item[key]] = item
    }

    return obj
}

window.enum_to_hash = enum_to_hash

export function object_to_formdata(obj, opt = {}) {
    return serialize(obj, opt);
}

window.object_to_formdata = object_to_formdata

export function copy_obj(obj) {
    if (!obj) return {}

    // Use native if available
    if(structuredClone) return structuredClone(obj)

    return JSON.parse(JSON.stringify(obj))
}

window.copy_obj = copy_obj

export function nl2br(text) {
    if (!text) return text

    return text.replace(/\n/g, "<br />")
}

window.nl2br = nl2br

export function scrollToError() {
    setTimeout(() => {
        const field = document.querySelector('.error_bag');
        if (!field) return
        const top = field.parentNode.getBoundingClientRect().top + window.pageYOffset - 32
        window.scrollTo({
            top,
            behavior: 'smooth'
        });
    }, 200)
}

export function scrollToTop() {
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, 200)
}

export function sprintf(str, ...values) {

    if (values.length) {
        let t = typeof values[0];
        let key;
        let args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(values)
            : values[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
}

export function data_get(obj, key, def, p, undef) {
    key = key.split ? key.split('.') : key;
    for (p = 0; p < key.length; p++) {
        obj = obj ? obj[key[p]] : undef;
    }
    return obj === undef ? def : obj;
}

function blank(value) {
    // Array
    if (Array.isArray(value)) return value.length === 0;

    // Date
    if (value instanceof Date) return false;

    // Object
    if (typeof value === 'obiect' && value !== null) {
        return Object.getOwnPropertyNames(value).length === 0
    }

    // String, Null, Undefined
    return ['', null, undefined].includes(value);
}

window.blank = blank

function filled(value) {
    return !blank(value)
}

window.filled = filled