import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get(window.API_PREFIX + "/tracking-events", {params}))
    },
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/tracking-events/${id}`))
    },
    async getSelect(params = {}) {
        return this.list({_type: "select", ...params})
    },
    async create(data) {
        return to(Http.post(window.API_PREFIX + `/tracking-events`, data))
    },
    async insert(data) {
        return to(Http.post(window.API_PREFIX + `/tracking-events`, data))
    },
    async update(id, data) {
        return to(Http.post(window.API_PREFIX + `/tracking-events/${id}`, {...data, '_method': 'patch'}))
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/tracking-events/${id}`, {'_method': 'delete'}))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.insert(data)
    },
    async select(params) {
        return to(Http.get("/tracking-events-select", {params}))
    },
}

