var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"relative flex items-center"},[(_vm.leftIcon)?_c('div',{staticClass:"absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"},[_c('i',{class:_vm.leftIcon})]):_vm._e(),_c('input',{ref:"input",staticClass:"form-checkbox h-4 w-4 transition duration-150 ease-in-out text-turquoise",class:[
                    _vm.inputClass,
                    {
                        'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red' : _vm.validation && _vm.validation.$error,
                        'pl-10' : _vm.leftIcon,
                        'pr-10' : _vm.rightIcon
                    }
                ],attrs:{"type":"checkbox","placeholder":_vm.placeholder},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.checked)}}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.placeholder))]),(_vm.rightIcon)?_c('div',{staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[_c('i',{class:_vm.rightIcon})]):_vm._e(),(_vm.validation && _vm.validation.$error)?_c('div',{staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[_c('i',{staticClass:"fa fa-exclamation-circle text-red-500"})]):_vm._e(),(_vm.loading)?_c('Loading',{attrs:{"use-spinner":""}}):_vm._e()],1),(_vm.validation && !_vm.hideValidationMessage)?_c('ErrorBag',_vm._b({attrs:{"placeholder":_vm.placeholder,"validation":_vm.validation}},'ErrorBag',_vm.error,false)):_vm._e(),(_vm.$slots.info)?_c('div',{staticClass:"mt-2 text-sm text-gray-500 flex items-center"},[_vm._t("info")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }