import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    page: [],
    form: {
        name: null,
        nickname: null,
        // Address
        country_id: null,
        postal_code: null,
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        // Contact
        email: null,
        phone: null
    }
}

const mutations = make.mutations(state)

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
