import {sync} from "vuex-pathify"

export default {
    computed: {
        ...sync('APP', ['alert'])
    },
    methods: {
        $alert(message, opt) { // variant = 'default', timeout = 5, withClose = false

            if (typeof message !== 'string') opt = message

            const defaults = {
                title: null,
                variant: 'default',
                time: 5000,
                withClose: true,
                show: true,
                message
            }

            const config = {...defaults, ...opt}

            Object.getOwnPropertyNames(config).forEach((key) => this.alert[key] = config[key])
        },
        $alertSuccess(message, opt) {
            this.$alert(message, {variant: 'success', withClose: true, ...opt})
        },
        $alertInfo(message, opt) {
            this.$alert(message, {variant: 'info', withClose: true, ...opt})
        },
        $alertWarning(message, opt) {
            this.$alert(message, {variant: 'warning', withClose: true, ...opt})
        },
        $alertError(message, opt) {
            this.$alert(message, {variant: 'error', withClose: true, ...opt})
            return false
        },
        $alertMessage(message, opt) {
            this.$alert(message, {variant: 'message', withClose: true, ...opt})
        },
        $alertFormSaved(opt) {
            return this.$alert(this.$t('form.saved'), {variant: 'success', withClose: true, ...opt})
        },
        $alertFormDeleted(opt) {
            return this.$alert(this.$t('form.deleted'), {variant: 'success', withClose: true, ...opt})
        },
        $alertFormFailure(opt) {
            this.$alert(this.$t('form.failure'), {variant: 'success', withClose: true, ...opt})
            return false
        },
    }
}
