var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{
    	'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start lg:grid-cols-4' : !_vm.inline,
    	'w-full flex flex-col' : _vm.inline,
    	'border-t border-gray-200 pt-5' : _vm.divider,
    	'mt-6 sm:mt-5' : !_vm.noMarginTop && !_vm.inline }
    	]},[_c('label',{staticClass:"text-sm font-medium leading-5 sm:mt-px text-gray-900 flex items-center relative",class:[_vm.labelClass, { 'sm:pt-2' : !_vm.text }]},[(_vm.empty)?[_c('div')]:[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}]},[_vm._v(_vm._s(_vm.label))])],(_vm.tooltip)?[_c('i',{staticClass:"fa fa-circle text-[5px] absolute -left-3 text-gray-300"})]:_vm._e(),(_vm.locale)?[_c('p',{staticClass:"ml-2 flex items-center"},[_vm._v(" [ "),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.locale.alpha2 ? _vm.locale.alpha2.toUpperCase() : _vm.locale.toUpperCase()))]),_c('img',{staticClass:"w-5 h-4 mx-2",attrs:{"src":("/img/flags/4x3/" + (_vm.locale.alpha2 ? _vm.locale.alpha2.toLowerCase() : _vm.locale.toLowerCase()) + ".svg"),"alt":""}}),_vm._v(" ] ")])]:_vm._e(),(_vm.required)?[_c('i',{staticClass:"fa fa-asterisk text-[8px] -mt-2 ml-1.5 text-red-300"})]:_vm._e()],2),_c('div',{staticClass:"mt-1 sm:mt-0 sm:col-span-2 lg:col-span-3",class:[_vm.containerClass, {
        	'pt-1' : _vm.inline,
        	'flex flex-wrap sm:flex-no-wrap items-center': this.$slots.default && this.$slots.default.length > 1
        }]},[(_vm.empty)?[_c('div',{staticClass:"h-8 mt-px"})]:[_vm._t("default")]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }