import Account from "@/API/Account.js"

import LoadingMixin from "@/mixins/loading.js"
import SystemMixin from "@/mixins/system.js"
import ValidationMixin from "@/mixins/validation.js"
import HandleValidationError from "@/mixins/handle_request_errors.js"

// TODO: Remove
import {get, sync} from "vuex-pathify"

export default {
    mixins: [LoadingMixin, SystemMixin, ValidationMixin, HandleValidationError],
    computed: {
        ...sync('Account', ['form'])
    },
    data() {
        return {
            currentLanguage: null
        }
    },
    methods: {
        /**
         * Action validate
         * @returns {Promise<boolean>}
         */
        async validate() {
            let valid = await this.v$.$validate()
            console.debug('Account/FormPage@validate: Validating data.', {valid})
            return valid
        },
        /**
         * Save form
         * @returns {Promise<boolean>}
         */
        async save() {
            if (!await this.validate()) return false

            const data = copy_obj(this.form)

            console.debug('Account/FormPage@save: Savinf data.', { data })

            const formData = object_to_formdata(this.form)

            this.loading()

            const [err, res] = await Account.update(this.form.id, formData)

            this.loaded()

            if (err) {
                console.error('Account/FormPage@save: Error saving data.', err)
                return this.$alertError(this.handleRequestError(err))
            }

            this.form = res.data.data

            console.debug('Account/FormPage@save: Data saved.')

            return true
        },
        /**
         * Action load
         * @param background
         * @returns {Promise<boolean>}
         */
        async load(background = false) {

            console.debug('Account/FormPage@load: Loading data.')

            background ? this.bgLoading() : this.loading()

            const [err, res] = await Account.get()

            if (err) {
                console.error('Account/FormPage@load: Error loading data.', err)
                return this.$alertError(this.handleRequestError(err))
            }

            this.form = res.data.data

            this.loaded()

            console.debug('Account/FormPage@load: Data loaded.')

            return true
        },
        /**
         * INIT
         * @returns {Promise<void>}
         */
        async init() {
            await this.load()

            this.currentLanguage = this.form.language_id
        }
    }
}
