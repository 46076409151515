import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    current: null,
    editingLocale: null,
    lang: 'en-US'
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    init(ctx){
        const lang = document.getElementsByTagName("html")[0].lang
        ctx.commit('SET_LANG', lang)
        console.debug("Locales@init", lang)
    },
    change(ctx, payload){
        document.querySelector('html')
            .setAttribute('lang', payload.lang)

        ctx.commit('SET_LANG', payload.lang)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
