<i18n src="@/i18n/enums/document_type.json"/>
<i18n src="@/i18n/locales.json"></i18n>
<i18n src="@/i18n/client/auth/register.json"/>
<template>
    <section class="mx-auto sm:px-4 md:px-0 max-w-4xl text-white">

        <!-- header !-->
        <header class="pt-4 flex px-4 sm:px-0">
            <!-- Logo !-->
            <div class="w-52">
                <router-link to="/login">
                    <img :src="$domain.logo" alt="Logo">
                </router-link>
            </div>
            <template v-if="currentLocale">
                <div class="ml-auto relative">
                    <button class="text-white text-sm flex items-center" @click="showLocales = !showLocales">
                        <img :src="`/img/flags/4x3/${currentLocale.alpha2.toLowerCase()}.svg`" alt="" class="w-4 h-3 mr-2">
                        <span>{{ $localize(currentLocale.name_locale) }}</span>
                        <i class="ml-2 fa fa-chevron-down text-xs opacity-75"></i>
                    </button>
                    <transition>
                        <div v-if="showLocales" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50">
                            <div class="rounded-md bg-white shadow-xs">
                                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    <template v-for="locale in locales">
                                        <button
                                            class="w-full flex items-center space-x-2 px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                            role="menuitem"
                                            @click="handleLocale(locale)">
                                            <img :src="`/img/flags/4x3/${locale.alpha2.toLowerCase()}.svg`" alt="" class="w-4 h-3">
                                            <span>{{ locale.name }}</span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </template>
        </header>
        <!-- /header !-->

        <Page
            :title="$t(userId ? 'register.complete_account' : 'register.create_new_account')"
            title-class="text-white"
            class="py-8"
        >

            <div class="max-w-4xl mx-auto">
                <p class="text-sm text-white px-6 py-4">{{ $t('register.about') }}</p>
            </div>

            <Card :title="$t('register.general')" class="mt-4">
                <div class="p-6">

                    <div class="max-w-2xl flex flex-col mx-auto">

                        <div class="prose">
                            <p>
                                {{ $t('register.personal_legal') }}
                            </p>
                        </div>

                        <FormLine :label="$t('register.person_type')">
                            <div class="flex space-x-3 mt-2">
                                <FormRadio name="person_type" :key="`pessoa-1`" :label="$t('register.personal')" v-model="form.person_type" radio-value="1"/>
                                <FormRadio name="person_type" :key="`pessoa-2`" :label="$t('register.company')" v-model="form.person_type" radio-value="2"/>
                            </div>
                        </FormLine>

                        <!-- Individual Perso n !-->
                        <template v-if="form.person_type == 1">
                            <FormLine :label="$t('register.name')">
                                <FormInput v-model="form.name" :validation="v$.form.name"/>
                            </FormLine>
                            <FormLine :label="$t('register.document')">
                                <div class="flex space-x-2">
                                    <FormSelect :options="documentTypes" :default-option="false" v-model="form.document_type" :validation="v$.form.document_type" class="w-38 flex-shrink-0"/>
                                    <FormInput v-model="form.tax_id" :validation="v$.form.tax_id" class="w-full"/>
                                </div>
                            </FormLine>
                            <FormLine :label="$t('register.birthday')">
                                <FormDatePicker v-model="form.birthday" :validation="v$.form.birthday" class="w-48"/>
                            </FormLine>
                            <FormLine :label="$t('register.phone')">
                                <FormPhone v-model="form.phone" :validation="v$.form.phone" no-type/>
                            </FormLine>
                        </template>
                        <!-- / Individual Person !-->

                        <!-- Legal Person !-->
                        <template v-if="form.person_type == 2">
                            <FormLine :label="$t('register.company_name')">
                                <FormInput v-model="form.name" :validation="v$.form.name"/>
                            </FormLine>
                            <FormLine :label="$t('register.trading_name')">
                                <FormInput v-model="form.trading_name" :validation="v$.form.trading_name"/>
                            </FormLine>
                            <FormLine :label="$t('register.id_company')">
                                <FormInput v-model="form.tax_id" :validation="v$.form.tax_id" class="w-56"/>
                            </FormLine>

                            <template v-if="form.country_id == 32">
                                <FormLine :label="$t('register.br_ie')">
                                    <FormInput v-model="form.br_ie" :validation="v$.form.br_ie" class="w-56"/>
                                </FormLine>
                            </template>

                            <FormLine :label="$t('register.responsible')" divider>
                                <FormInput v-model="form.responsible" :validation="v$.form.responsible"/>
                            </FormLine>

                            <FormLine :label="$t('register.email')">
                                <FormInput v-model="form.email" :validation="v$.form.email" class="w-56"/>
                            </FormLine>

                            <FormLine :label="$t('register.phone')">
                                <FormInput v-model="form.phone" :validation="v$.form.phone" class="w-48"/>
                            </FormLine>
                        </template>
                        <!-- / Legal Person !-->

                    </div>

                </div>
            </Card>

            <Card :title="$t('register.address')" class="mt-4">
                <div class="p-6">

                    <div class="max-w-2xl flex flex-col mx-auto">

                        <div class="prose">
                            <p>
                                {{ $t('register.address_about') }}
                            </p>
                        </div>

                        <HAddressLink ref="AddressLink" :address="form" v-model="form" #default="{ data }">
                            <!-- Pais !-->
                            <FormLine :label="$t('register.country')">
                                <FormSelect opt-label="name_locale" localized text-camelize :options="countries" v-model="form.country_id" :validation="v$.form.country_id"/>
                            </FormLine>
                            <!-- Codigo Postal !-->
                            <FormLine :label="$t('register.postcode')">
                                <FormInput v-model="form.postcode" :loading="data.isPostcodeLoading" :validation="v$.form.postcode" @enter="handlePostcode" @blur="handlePostcode"
                                           class="w-48"/>
                            </FormLine>
                            <!-- Address !-->
                            <FormLine :label="$t('register.address')">
                                <div class="flex flex-col space-y-2">
                                    <FormInput v-model="form.address_1" :validation="v$.form.address_1"/>
                                    <FormInput v-model="form.address_2" :validation="v$.form.address_2"/>
                                </div>
                            </FormLine>
                            <!-- Address Number !-->
                            <FormLine :label="$t('register.address_number')" no-margin-top class="mt-2">
                                <FormInput v-model="form.address_number" :validation="v$.form.address_number" class="w-48"/>
                            </FormLine>
                            <!-- City !-->
                            <FormLine :label="$t('register.city')">
                                <FormInput v-model="form.city" :validation="v$.form.city"/>
                            </FormLine>
                            <!-- State !-->
                            <FormLine :label="$t('register.state')">
                                <FormSelect :options="data.states" opt-label="name" v-model="form.state_id" :validation="v$.form.state_id" :loading="data.isStateLoading"/>
                            </FormLine>
                        </HAddressLink>

                        <template v-if="userId">
                            <FormLine>
                                <SaveButton @click="handleRegister">{{ $t('register.register') }}</SaveButton>
                            </FormLine>
                        </template>

                    </div>

                </div>
            </Card>

            <template v-if="!userId">
                <Card :title="$t('register.access')" class="mt-4">
                    <div class="p-6">

                        <div class="max-w-2xl flex flex-col mx-auto">

                            <div class="prose">
                                <p>
                                    {{ $t('register.access_about') }}
                                </p>
                            </div>

                            <FormLine :label="$t('register.access_email')">
                                <FormInput v-model="form.email" :validation="v$.form.email"/>
                            </FormLine>

                            <FormLine :label="$t('register.password')">
                                <FormInput type="password" v-model="form.password" :validation="v$.form.password"/>
                            </FormLine>
                            <FormLine :label="$t('register.password_confirmation')">
                                <FormInput type="password" v-model="form.password_confirmation" :validation="v$.form.password_confirmation"/>
                            </FormLine>

                            <FormLine>
                                <SaveButton @click="handleRegister">{{ $t('register.register') }}</SaveButton>
                            </FormLine>

                        </div>

                    </div>
                </Card>
            </template>

        </Page>
        <Loading fullscreen v-if="isLoading"/>
    </section>

</template>

<script>
import PageMixin from "./mixins/register"

import Page from "@/components/Page"
import Card from "@/components/Card"
import Loading from "@/components/Loading"
import HAddressLink from "@/components/HAddressLink"
import SaveButton from "@/components/SaveButton"
import FormLine from "@/components/FormLine"
import FormRadio from "@/components/FormRadio"
import FormInput from "@/components/FormInput"
import FormPhone from "@/components/FormPhone"
import FormSelect from "@/components/FormSelect"
import FormDatePicker from "@/components/FormDatePicker"

export default {
    name: "RegisterPage",
    mixins: [PageMixin],
    props: ['userId'],
    components: {
        Page, Card,
        Loading,
        FormLine, FormInput, FormSelect, FormRadio, FormPhone,
        FormDatePicker, HAddressLink, SaveButton
    },
    data() {
        return {
            showLocales: false
        }
    },
    methods: {
        async handleRegister() {
            if (await this.register()) {

                this.$modalSuccess(
                    this.$t('register.modal.success.title'),
                    this.$t('register.modal.success.text')
                )

                setTimeout(() => {
                    window.location = '/'
                }, 2000)
            }
        },
        handlePostcode() {
            this.$refs.AddressLink.loadPostcode()
        },
        handleLocale(locale){
            this.setLocale(locale.uti)
            this.showLocales = false
        }
    },
    created() {
        this.init()
    }
}
</script>
