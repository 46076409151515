import SupportTickets from "@/API/backoffice/SupportTickets.js"
import SystemMixin from "@/mixins/system.js"
import LoadingMixin from "@/mixins/loading.js"
import HandleRequestError from "@/mixins/handle_request_errors.js"

export default {
    mixins: [
        SystemMixin,
        LoadingMixin,
        HandleRequestError
    ],
    methods: {
        /**
         * Action delete
         * @returns {Promise<boolean>}
         */
        async delete() {

            console.debug('SupportTicketDeleteButton: Deleting SupportTicket.', {id: this.support_ticket.id})

            this.loading()

            const [err, res] = await SupportTickets.delete(this.support_ticket.id)

            this.loaded()

            if (err) {
                console.error('SupportTicketDeleteButton: SupportTicket error.', {id: this.support_ticket.id, err})
                return this.$alertError(this.handleRequestError(err))
            }

            console.info('SupportTicketDeleteButton: SupportTicket deleted.', {id: this.support_ticket.id})

            return true
        }
    }
}