import Auth from "@/API/Auth"
import LoadingMixin from "@/mixins/loading"
import AlertMixin from "@/mixins/alert"
import ValidationMixin from "@/mixins/validation.js"
import { required, email } from "@vuelidate/validators"

export default {
    mixins: [LoadingMixin, AlertMixin, ValidationMixin],
    data() {
        return {
            fields: {
                email: "",
                password: "",
                remember_me: ""
            }
        }
    },
    validations: {
        fields: {
            email: {
                required,
                email
            },
            password: {
                required
            }
        }
    },
    methods: {
        validate() {
            this.v$.$touch()

            console.debug('Auth/login@validade validando dados')

            return !this.v$.$invalid
        },
        async send() {

            if (!this.validate()) {

                if (this.v$.fields.email.$error) this.$refs.email.focus()
                else if (this.v$.fields.password.$error) this.$refs.senha.focus()

                return false
            }

            console.debug('Auth/login@send autenticando')

            const data = this.fields;

            // Prevent Typo
            data.email = data.email.toLowerCase();

            this.loading()
            const [err, res] = await Auth.login(data)
            this.loaded()

            if (err) {
                console.error('Auth/login@send arro ao autenticar', err.response)

                try {
                    this.$alertError(err.response.data.errors.email[0])
                } catch(ex){
                    this.$alertError('Error desconhecido, aguarde uns instantes e tente novamente.')
                }

                return false
            }

            return true
        }
    }
}
