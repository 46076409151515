import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async load() {
        return to(Http.get(window.API_PREFIX + "/config"))
    },
    async profile() {
        return to(Http.get(window.API_PREFIX + "/profile"))
    }
}
