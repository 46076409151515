<template>
    <div>
        <div class="sm:hidden">
            <select aria-label="Selected tab" class="form-select block w-full" :value="value" @input="$emit('input', $event.target.value)">
                <option v-for="(label, index) in itens" :key="label" :value="index">{{ label }}</option>
            </select>
        </div>
        <div class="hidden sm:block">
            <nav class="flex">
                <button
                    v-for="(label, index) in itens"
                    :key="label"
                    :class="{'bg-white shadow border-b-2 border-turquoise' : index == value }"
                    class="px-4 py-2 font-medium text-sm leading-5 rounded-sm text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-turquoise focus:bg-white focus:border-white"
                    @click="$emit('input', index)">
                    {{ label }}
                </button>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tabs",
        props: {
            value: [String, Number],
            itens: Array
        }
    }
</script>
