import {enum_to_hash} from "../helpers.js"

export default {
    "CM": 10,
    "IN": 20
}

export const options = [
    {id: 10, label: 'Centimeters'},
    {id: 20, label: 'Inches'},
]

export const hash = enum_to_hash(options)