import {make} from "vuex-pathify"
import User from "@/API/User"

const state = {
    isLoading: false,
    form: {
        origin: {
            country_id: null,
            state_id: null,
            postcode: null,
            city: null
        },
        destination: {
            country_id: null,
            state_id: null,
            postcode: null,
            city: null
        },
        package: {
            width: null,
            length: null,
            height: null,
            weight: null,
            total_value: ""
        },
        pickup: false
    },
}

const mutations = make.mutations(state)

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
