<i18n src="@/i18n/components/FormEmail.json"></i18n>
<template>
    <div>
        <!-- input wrap !-->
        <div class="relative flex space-x-2">
            <!-- Left icon !-->
            <div v-if="leftIcon" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i :class="leftIcon"></i>
            </div>

            <!-- Input !-->
            <input
                ref="input"
                class="block w-full min-w-0 rounded-md sm:text-sm"
                :class="computedClass"
                :type="type"
                :value="value"
                :placeholder="placeholder"
                :disabled="disabled || readOnly"
                @input="$emit('input', $event.target.value)"
                @keypress.enter="$emit('enter', $event.target.value)"
                @blur="$emit('blur', $event.target.value)"
            />

			<template v-if="!noType">
                <select class="form-select rounded-md shadow-sm sm:text-sm" v-model="data.type">
                    <option value="0">{{ $t('FormEmail.personal') }}</option>
                    <option value="1">{{ $t('FormEmail.professional') }}</option>
                </select>
            </template>

            <!-- Right Icon !-->
            <div v-if="rightIcon" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i :class="rightIcon"></i>
            </div>
            <!-- Validation Icon !-->
            <div v-if="validation && validation.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i class="fa fa-exclamation-circle text-red-500"></i>
            </div>
            <!-- Loading !-->
            <Loading v-if="loading" use-spinner />
        </div>
        <!-- /input wrap !-->
        <!-- ErrorBag !-->
        <ErrorBag v-if="validation && !hideValidationMessage" :placeholder="placeholder" :validation="validation" v-bind="error" />
        <!-- Info !-->
        <div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
            <slot name="info" />
        </div>
    </div>
</template>

<script>
    import Loading from "@/components/Loading"
    import ErrorBag from "@/components/ErrorBag"
	import { get } from "vuex-pathify"

    export default {
        name: "FormInput",
        components: { Loading, ErrorBag },
        props: {
            value: [String, Number, Object],
            inputClass: String,
            placeholder: String,
            validation: Object,
            hideValidationMessage: Boolean,
            leftIcon: String,
            rightIcon: String,
            error: Object,
            loading: Boolean,
	        disabled: Boolean,
	        readOnly: Boolean,
	        noType: Boolean,
            type: {
                type: String,
                default: 'text'
            },
            inputDefault: {
                type: String,
                default: 'form-input'
            },
            defaultInputClass: {
                type: String,
                default: 'form-input'
            },
            errorClass: {
                type: String,
                default: 'error'
            },
        },
		computed: {
        	...get('Auxiliar', ['DDI']),
            computedClass(){
                return [
                    this.defaultInputClass,
                    {
                        [this.errorClass]: this.hasError
                    }
                ]
            },
            hasError(){
                return this.validation?.$error
            }
		},
        data(){
            return {
                flag: null,
                data: {
                    value: "",
                    type: "0",
                }
            }
        },
        methods: {
            focus(){
                this.$refs.input.focus()
            },
            init(){
                if(!this.value) return;
                // [this.data.email, this.data.emailType] = this.value.split('|')

                if(this.value.length) {
                    this.data = {
                        value: this.value,
                        type: 0
                    }
                } else {
                    this.data = this.value
                }

                if(!this.data.type) {
                    this.data.type = "0"
                }
            },
            getValue(){
                // const email = [
                //     this.data.email ?? '',
                //     this.data.emailType ?? ''
                // ]
                // return email.join('|')

                return this.data.value
            }
        },
        created() {
            this.init()
        },
        watch: {
            value(){
                this.init()
            },
            data: {
                deep: true,
                handler(){
                    this.$emit('input', this.getValue())
                }
            },
        }
    }
</script>
