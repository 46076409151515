import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    isBgLoading: false,
    languages: [],
    form: {
        origin: {
            person_type: 1
        },
        destination: {
            person_type: 1
        },
        packages: [],
        service: {},
        services: [],
        payment: {},
        resume: {}
    },
    resume: {
        total_freight: 0,
        total_insurance: 0,
        total_tax: 0,
        total: 0
    },
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    clear({commit}){
        // commit('SET_FORM', {
        //     origin: {
        //         address_id: null,
        //         is_new_address: false,
        //         send_for_another_person: false,
        //         save_address: null,
        //     },
        //     destination: {
        //         address_id: null,
        //         save_address: null,
        //     },
        //     packages: [
        //         {
        //             package_id: null,
        //             packing: {
        //                 width: null,
        //                 length: null,
        //                 height: null,
        //                 weight: null
        //             },
        //             itens: []
        //         }
        //     ],
        //     services: {
        //         freight: [ ],
        //         insurance: 20,
        //         with_pickup: true,
        //         with_advanced_post: false,
        //         with_insurance: false,
        //         data: {
        //             service_class_id: null,
        //             with_pickup: true,
        //             with_advanced_post: false,
        //             with_insurance: false
        //         }
        //     },
        //     payment: {
        //         payment_selected_id: 0,
        //         data: {
        //             cc_number: null,
        //             cc_token: null
        //         }
        //     },
        //     resume: {
        //         total_itens: 0,
        //         total_freight: 0,
        //         total_taxes: 0,
        //         total: 0
        //     },
        // })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
