import {make} from "vuex-pathify"
import Packages from "@/API/Packages"

const state = {
    isLoading: false,
    page: [],
    form: {},
    select: []
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    async loadSelect({commit, dispatch}, payload = {}) {
        dispatch('loading')

        const [err, res] = await Packages.list({"_type": "select", ...payload});

        dispatch('loaded')

        if (err) {
            console.error(err)
            return false
        }

        commit('SET_SELECT', res.data?.data ?? [])

        return true
    },
    clearSelect({commit}) {
        commit('SET_SELECT', [])
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
