<i18n src="@/i18n/backoffice/shipments/ticket.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<div>
		<template v-if="true">
			<!-- / Actions !-->
			<FormGroup>
				<!-- Address !-->
				<FormLine :label="'Destinatários'">
					<div class="max-w-2xl">
						<SupportTicketAddress ref="support_ticket_address" v-model="form.addresses" />
						<!-- Address Actions !-->
						<div class="px-2 flex text-gray-600">
							<!-- Add Client !-->
							<template v-if="!hasClient">
								<button type="button" class="text-xs " @click="handleAddClient">Incluir o cliente</button>
							</template>
							<!-- Clear !-->
							<template v-if="hasAddress">
								<button type="button" class="ml-auto text-xs" @click="handleClearAddress">remover todos</button>
							</template>
						</div>
						<!-- / Address Actions !-->
					</div>
				</FormLine>
				<!-- Subject !-->
				<FormLine :label="$t('shipment_ticket.form.subject')">
					<SupportSubjectSelect v-model="form.support_subject_id" @input="handleSelectSubject" class="max-w-2xl"/>
				</FormLine>
				<!-- Body !-->
				<FormLine :label="$t('shipment_ticket.form.body')">
					<FormTextarea v-model="form.body" :rows="6" class="max-w-2xl"/>
				</FormLine>
				<!-- Attachment !-->
				<FormLine :label="$t('shipment_ticket.form.attachments')">
					<div>
						<FormFile name="attachments" v-model="form.attachments" :validation="v$.form.attachments" class="max-w-xs"/>
					</div>
				</FormLine>
				<hr class="my-4 sm:my-6" />
				<!-- Send !-->
				<div class="mt-4 flex justify-between">
					<!-- Cancel !-->
					<CancelButton @click="handleCancel"/>
					<!-- Send !-->
					<SendButton @click="handleSave"/>
				</div>
			</FormGroup>
		</template>
		<template v-else>
			<Card>
				<div class="p-4 h-96 space-y-8 flex flex-col text-base justify-center items-center">
					<p>{{ $t('shipment_ticket.empty') }}</p>
					<button type="button" @click.prevent="handleShowForm">
						<i class="fa fa-plus-circle text-green-500"></i>
						{{ $t('shipment_ticket.create') }}
					</button>
				</div>
			</Card>
		</template>
	</div>
</template>

<script>
import Mixin from "./mixins/support_ticket_form.js"
import Card from "@/components/Card.vue"
import FormLine from "@/components/FormLine.vue"
import FormTextarea from "@/components/FormTextArea.vue"
import FormInput from "@/components/FormInput.vue"
import SendButton from "@/components/SendButton.vue"
import CancelButton from "@/components/CancelButton.vue"
import FormFile from "@/components/FormFile.vue"
import FormGroup from "@/components/FormGroup.vue"
import FormCheckbox from "@/components/FormCheckbox.vue"
import CancelTextButton from "@/components/CancelTextButton.vue"
import SupportSubjectSelect from "@/domain/support_subjects/SupportSubjectSelect.vue"
import SupportTicketAddress from "@/domain/support_tickets/SupportTicketAddress.vue"

export default {
	name: "SupportTicketFormPartial",
	mixins: [Mixin],
	components: {
		Card,
		FormLine,
		FormTextarea,
		CancelButton,
		FormInput,
		SendButton,
		FormFile,
		FormGroup,
		CancelTextButton,
		FormCheckbox,
		SupportSubjectSelect,
		SupportTicketAddress
	},
	data() {
		return {
			showForm: false,
			form: {
				shipment_id: null,
				subject: null,
				body: null,
				attachments: {},
				addresses: []
			},
			lastDepartment: null
		}
	},
	computed: {
		/**
		 * Check if has client in addresses
		 * @returns {boolean}
		 */
		hasClient(){
			return this.form.addresses.findIndex(item => item.address_type === 'partner' && item.id == this.shipment.partner_id) !== -1
		},
		/**
		 * Check if has address
		 * @returns {boolean}
		 */
		hasAddress(){
			return this.form.addresses?.length > 0
		}
	},
	methods: {
		/**
		 * Handle Send
		 */
		async handleSave() {
			if (await this.save()) {
				this.$alertSuccess(this.$t('form.success'))
				this.showForm = false
				this.$emit('created')
				return true
			}

			return false
		},
		/**
		 * Handle add client
		 */
		handleAddClient() {
			this.addClient()
		},
		/**
		 * Handle show form
		 */
		handleShowForm() {
			this.showForm = true
		},
		/**
		 * Handle Show Form
		 */
		handleToggleForm() {
			this.showForm = !this.showForm
		},
		/**
		 * Handle Cancel
		 */
		handleCancel() {
			this.showForm = false
			this.form = {}
			this.$emit('close')
		},
		/**
		 * Handle clear address
		 */
		handleClearAddress() {
			this.form.addresses = []
		},
		/**
		 * Handle select subject
		 * @param evt
		 * @returns {Promise<boolean|boolean>}
		 */
		async handleSelectSubject(evt){
			await this.$refs.support_ticket_address.removeItem('department', this.lastDepartment)

			this.lastDepartment = evt

			if(!evt) return false

			return this.$refs.support_ticket_address.addItem('department', evt)
		}
	},
	mounted() {
		this.init()
	}
}
</script>