import {get} from "vuex-pathify"

export default {
    install(vue) {
        vue.mixin({
            computed: {
                ...get('Locales', ['current', 'lang'])
            },
            methods: {
                $localize(locale, prop = null){

                    if(!locale) return ""

                    if(prop) locale = locale[prop];

                    if(!locale) return ""

                    return locale[this.current ?? this.lang] ? locale[this.current ?? this.lang] : locale['en-US']
                }
            }
        })

    }
}
