export default {
    methods: {
        loading(loadingAttribute = 'isLoading') {
            this.$emit(loadingAttribute, true)
            return this
        },
        loaded(loadingAttribute = 'isLoading') {
            this.$emit(loadingAttribute, false)
            this.$emit('isBgLoading', false)
            return this
        },
        bgLoading() {
            this.$emit('isBgLoading', true)
            return this
        },
        bgLoaded() {
            this.$emit('isBgLoading', false)
            return this
        }
    }
}
