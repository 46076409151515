<template>
    <section class="relative flex flex-col items-center" :class="[background, loading ? 'max-h-screen overflow-hidden' : '']" @mouseleave="$emit('mouseleave', $event)">
        <!-- Header !-->
        <header v-if="title" :class="[headerDefaultClass, headerClass, maxWidth]">
            <!-- Title Wrap !-->
            <div class="flex relative">
                <!-- Title !-->
                <div class="flex items-center sm:items-start">
                    <!-- Icon Back !-->
                    <template v-if="iconBack">
                        <button class="mt-px mr-2 text-turquoise hover:opacity-75 focus:shadow-outline-blue active:opacity-75 text-3xl focus:outline-none transition duration-150 ease-in-out"
                                @click="$router.back()">
                            <i class="fa fa-chevron-circle-left text-turquoise" :class="icon"></i>
                        </button>
                    </template>
                    <!-- Icon !-->
                    <template v-else-if="icon">
                        <i class="mt-px mr-2 text-2xl text-turquoise" :class="icon"></i>
                    </template>
                    <div class="h-[38px]">
                        <!-- Title !-->
                        <div class="flex items-center">
                            <h1 @dblclick="$emit('title-action')"
                                :class="[titleDefaultClass, titleClass, {'cursor-pointer select-none' : $listeners['title-action']}]">
                                {{ title }}
                            </h1>
                            <!-- Title Loading !-->
                            <template v-if="titleLoading">
                                <i class="ml-3 text-xl spinner-icon fal fa-spinner-third text-blue-500 normal"></i>
                            </template>
                        </div>
                        <!-- / Title !-->
                        <!-- In Title !-->
                        <slot name="in-title"/>
                    </div>
                </div>
                <!-- / Title !-->
            </div>
            <!-- Title Wrap !-->
            <slot name="after-title"/>
            <slot name="header"/>
        </header>
		<slot name="before-content"/>
        <!-- Content !-->
        <div class="flex-1 flex flex-col" :class="[contentDefaultClass, contentClass, maxWidth]">
            <div class="flex-1 flex flex-col py-4">
                <slot/>
            </div>
        </div>
        <!-- /Content !-->
        <Loading v-if="loading" fullscreen/>
    </section>
</template>
<script>
import Loading from "@/components/Loading"

export default {
    name: "Page",
    props: {
        title: String,
        maxWidth: {
            type: String,
            default: "w-full max-w-7xl"
        },
        headerClass: {
            type: String,
            default: "sm:mt-2 2xl:mt-4 sm:px-2 2xl:px-4 flex items-center"
        },
        headerDefaultClass: {
            type: String,
            default: "sm:px-2 2xl:px-4 flex items-center"
        },
        titleClass: {
            type: String,
            default: "text-dark-blue-gray"
        },
        titleDefaultClass: {
            type: String,
            default: "text-xl sm:text-3xl font-bold"
        },
        contentDefaultClass: {
            type: String,
            default: "sm:px-2 2xl:px-4 sm:mt-4"
        },
        contentClass: {
            type: String,
        },
        titleLoading: [String, Boolean],
        icon: String,
        iconBack: Boolean,
        containerClass: String,
        background: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
		fullscreenLoading: Boolean
    },
    components: {Loading}
}
</script>
