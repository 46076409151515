import {get} from "vuex-pathify"

export default {
    install(vue) {
        vue.mixin({
            computed: {
                $domain: get('Domain/current')
            },
            methods: {

            }
        })

    }
}
