<i18n src="@/i18n/components/FormPhone.json"></i18n>
<template>
    <div>
        <!-- input wrap !-->
        <div class="relative sm:flex sm:space-x-2">
            <!-- Left icon !-->
            <div v-if="leftIcon" class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i :class="leftIcon"></i>
            </div>

            <div class="rounded-md border border-gray-300 shadow-sm flex flex-grow divide-x divide-gray-200">

                <div v-if="flag" class="ml-2 p-2 flex justify-center items-center flex-shrink-0">
                    <img :src="`/img/flags/1x1/${flag}.svg`" alt="flag" class="w-4 h-4"/>
                </div>

                <!-- DDI !-->
                <select
                    class="form-select pl-2 pr-8 flex-shrink-0 border-none rounded-none text-gray-700 appearance-none focus:z-10 focus:outline-none focus:ring-2 focus:ring-turquoise sm:text-sm"
                    :class="{'rounded-l-md' : !flag}" v-model="data.country_id">
                    <option :value="null">{{ $t('FormPhone.select') }}</option>
                    <template v-for="ddi in DDI">
                        <option :key="ddi.id" :value="ddi.id">{{ ddi.alpha2 }} +{{ ddi.ddi }}</option>
                    </template>
                </select>
                <!-- Input !-->
                <input
                    ref="input"
                    class="form-input border-0 shadow-none rounded-none rounded-r-md flex-grow text-gray-700 focus:outline-none focus:ring-2 focus:ring-turquoise sm:text-sm"
                    :class="[
                    inputClass,
                    {
                        'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red' : validation && validation.$error,
                        'pl-10' : leftIcon,
                        'pr-10' : rightIcon
                    }
                ]"
                    :type="type"
                    :placeholder="placeholder"
                    :disabled="disabled || readOnly"
                    v-model="data.value"
                    @keypress.enter="$emit('enter', data)"
                    @blur="$emit('blur', data)"
                    v-mask="mask"
                />
            </div>

            <template v-if="!noType">
                <!-- Phone Type !-->
                <select class="form-select w-full sm:w-auto mt-2 sm:mt-0 rounded shadow-sm text-gray-700 focus:outline-none focus:ring-1 focus:ring-turquoise sm:text-sm" v-model="data.type">
                    <option value="0">{{ $t('FormPhone.House') }}</option>
                    <option value="1">{{ $t('FormPhone.Work') }}</option>
                    <option value="2">{{ $t('FormPhone.FAX') }}</option>
                    <option value="3">{{ $t('FormPhone.WhatsApp') }}</option>
                </select>
            </template>

            <!-- Right Icon !-->
            <div v-if="rightIcon" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i :class="rightIcon"></i>
            </div>
            <!-- Validation Icon !-->
            <div v-if="validation && validation.$error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i class="fa fa-exclamation-circle text-red-500"></i>
            </div>
            <!-- Loading !-->
            <Loading v-if="loading" use-spinner/>
        </div>
        <!-- /input wrap !-->
        <!-- ErrorBag !-->
        <ErrorBag v-if="validation && !hideValidationMessage" :placeholder="placeholder" :validation="validation" v-bind="error"/>
        <!-- Info !-->
        <div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
            <slot name="info"/>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/Loading"
import ErrorBag from "@/components/ErrorBag"
import {get} from "vuex-pathify"
import Cleave from "cleave.js";

export default {
    name: "FormInput",
    components: {Loading, ErrorBag},
    props: {
        value: [String, Number, Object],
        inputClass: String,
        placeholder: String,
        validation: Object,
        hideValidationMessage: Boolean,
        leftIcon: String,
        rightIcon: String,
        error: Object,
        loading: Boolean,
        disabled: Boolean,
        readOnly: Boolean,
        noType: Boolean,
        type: {
            type: String,
            default: 'text'
        }
    },
    computed: {
        ...get('Auxiliar', ['DDI']),
        country() {
            if (!this.DDI) return null
            return this.DDI.find(item => item.id == this.data.country_id)
        },
        flag() {
            if (!this.country) return null
            return this.country?.alpha2.toLowerCase()
        },
        mask() {
            if (!this.country?.mask) return '########'
            return this.country?.mask?.split('|')
        }
    },
    data() {
        return {
            data: {
                country_id: null,
                ddi: null,
                value: null,
                type: null,
            }
        }
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        init() {
            if (!this.value) return;
            // [this.data.countryID, this.data.phoneDDI, this.data.phoneNumber, this.data.phoneType] = this.value.split('|')
            this.data = this.value

            if (!this.data.country_id) {
                this.data.country_id = null
            }

            if (!this.data.type) {
                this.data.type = 1
            }

            this.data.type = 1
        },
        getValue() {
            let country;

            if (this.data.countryID) {
                country = this.DDI.find(item => item.id == this.data.countryID)
            }

            this.data.ddi = country?.ddi

            return this.data
        }
    },
    created() {
        this.init()
    },
    watch: {
        value() {
            this.init()
        },
        data: {
            deep: true,
            handler() {
                this.$emit('input', this.getValue())
            }
        },
    }
}
</script>
