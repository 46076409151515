<i18n src="@/i18n/partner/menu_links.json"></i18n>
<template>
	<nav class="py-5 text-gray-300 flex flex-col">

		<div class="px-4 mb-4 flex flex-col space-y-4">
			<div class="flex flex-col space-y-2">
				<!-- Welcome !-->
				<router-link to="/"
				             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
				             exact-active-class="bg-opacity-50 bg-gray-900 text-white">
					<i class="font-light w-6 flex-shrink-0 text-center m-1 fa fa-home text-lg transition ease-in-out duration-150"></i>
					<OpacityScaleTransition enter-extra="translate-x-8">
						<span class="ml-3">{{ $t('menu_links.Dashboard') }}</span>
					</OpacityScaleTransition>
				</router-link>
                <template v-if="can_see_services">
                    <!-- Services !-->
                    <router-link to="/services"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-opacity-50 bg-gray-900 text-white">
                        <i class="w-6 m-1 fa fa-code-branch flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
                        <OpacityScaleTransition enter-extra="translate-x-8">
                            <span class="ml-3">{{ $t('menu_links.Services') }}</span>
                        </OpacityScaleTransition>
                    </router-link>
                </template>
				<!-- Bags !-->
                <template v-if="can_see_bags_master">
                    <router-link to="/bags"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-gray-900 text-white">
                        <i class="w-6 m-1 fa fa-sack flex-shrink-0 text-center text-xl transition ease-in-out duration-150"></i>
                        <OpacityScaleTransition enter-extra="translate-x-8">
                            <span class="ml-3">{{ $t('menu_links.Bags') }}</span>
                        </OpacityScaleTransition>
                    </router-link>

                    <router-link to="/mawbs"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-gray-900 text-white">
                        <i class="w-6 m-1 fa fa-pallet-alt flex-shrink-0 text-center text-xl transition ease-in-out duration-150"></i>
                        <OpacityScaleTransition enter-extra="translate-x-8">
                            <span class="ml-3">{{ $t('menu_links.MAWBS') }}</span>
                        </OpacityScaleTransition>
                    </router-link>
                </template>
				<!-- / Bags !-->

                <template v-if="can_see_shipment_pickup">
                    <!-- Pickups !-->
                    <router-link to="/shipment-pickups"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-opacity-50 bg-gray-900 text-white">
                        <i class="fa fa-dolly w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
                        <span class="ml-3">{{ $t('menu_links.Pickups') }}</span>
                    </router-link>
                </template>

                <template v-if="can_see_shipment_deliveries">
                    <!-- Deliveries !-->
                    <router-link to="/shipment-deliveries"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-opacity-50 bg-gray-900 text-white">
                        <i class="fa fa-truck-loading w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
                        <span class="ml-3">{{ $t('menu_links.Deliveries') }}</span>
                    </router-link>
                </template>

                <!-- All Shipments !-->
                <router-link to="/shipments"
                             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                             active-class="bg-opacity-50 bg-gray-900 text-white">
                    <i class="fa fa-dolly-flatbed-alt w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
                    <span class="ml-3">{{ $t('menu_links.Shipments') }}</span>
                </router-link>

				<!-- Manifests !-->
				<router-link :to="{ name: 'manifests' }"
							 class="px-3 py-2 group flex flex-shrink-0 items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
							 active-class="bg-opacity-50 bg-gray-900 text-white">
					<i class="fa fa-book-user w-6 flex-shrink-0 text-center m-1 text-lg transition ease-in-out duration-150"></i>
					<OpacityScaleTransition enter-extra="translate-x-8">
						<span class="ml-3">{{ $t('menu_links.Manifests') }}</span>
					</OpacityScaleTransition>
				</router-link>

                <!-- Tracking !-->
                <router-link to="/tracking"
                             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                             active-class="bg-gray-900 text-white">
                    <i class="w-6 flex-shrink-0 text-center m-1 fa fa-bullseye-pointer text-xl transition ease-in-out duration-150"></i>
                    <OpacityScaleTransition enter-extra="translate-x-8">
                        <span class="ml-3">{{ $t('menu_links.Tracking') }}</span>
                    </OpacityScaleTransition>
                </router-link>

                <template v-if="can_see_stock">
                    <!-- Stocks !-->
                    <router-link to="/stocks"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-opacity-50 bg-gray-900 text-white">
                        <i class="w-6 m-1 fa fa-code-branch flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
                        <OpacityScaleTransition enter-extra="translate-x-8">
                            <span class="ml-3">{{ $t('menu_links.Stock') }}</span>
                        </OpacityScaleTransition>
                    </router-link>
                </template>

                <template v-if="can_see_clients">
                    <!-- Clients !-->
                    <router-link to="/clients"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-opacity-50 bg-gray-900 text-white">
                        <i class="fa fa-users w-6 flex-shrink-0 text-center m-1 text-lg transition ease-in-out duration-150"></i>
                        <OpacityScaleTransition enter-extra="translate-x-8">
                            <span class="ml-3">{{ $t('menu_links.Clients') }}</span>
                        </OpacityScaleTransition>
                    </router-link>
                </template>

				<!-- Providers !-->
<!--				<router-link to="/providers"-->
<!--				             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"-->
<!--				             active-class="bg-opacity-50 bg-gray-900 text-white">-->
<!--					<i class="fa fa-user-hard-hat w-6 flex-shrink-0 text-center m-1 text-lg transition ease-in-out duration-150"></i>-->
<!--					<OpacityScaleTransition enter-extra="translate-x-8">-->
<!--						<span class="ml-3">{{ $t('menu_links.Providers') }}</span>-->
<!--					</OpacityScaleTransition>-->
<!--				</router-link>-->

<!--                <template v-if="can_see_integrations">-->
<!--                    &lt;!&ndash; Integrations !&ndash;&gt;-->
<!--                    <router-link to="/integrations"-->
<!--                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"-->
<!--                                 active-class="bg-opacity-50 bg-gray-900 text-white">-->
<!--                        <i class="w-6 m-1 fa fa-exchange-alt flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>-->
<!--                        <OpacityScaleTransition enter-extra="translate-x-8">-->
<!--                            <span class="ml-3">{{ $t('menu_links.Integrations') }}</span>-->
<!--                        </OpacityScaleTransition>-->
<!--                    </router-link>-->
<!--                    <router-link to="/imports"-->
<!--                                 class="px-3 py-2 group flex flex-shrink-0 items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"-->
<!--                                 active-class="bg-opacity-50 bg-gray-900 text-white">-->
<!--                        <i class="fa fa-cloud-upload-alt w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>-->
<!--                        <span class="ml-3">{{ $t('menu_links.Upload') }}</span>-->
<!--                    </router-link>-->
<!--                </template>-->

				<!-- Tour Virtual !-->
<!--				<router-link to="/virtual_tour"-->
<!--				             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"-->
<!--				             active-class="bg-opacity-50 bg-gray-900 text-white">-->
<!--					<i class="w-6 flex-shrink-0 text-center m-1 fa fa-pennant text-lg transition ease-in-out duration-150"></i>-->
<!--					<OpacityScaleTransition enter-extra="translate-x-8">-->
<!--						<span class="ml-3">{{ $t('menu_links.Virtual_tour') }}</span>-->
<!--					</OpacityScaleTransition>-->
<!--				</router-link>-->
				<!-- Countries !-->

                <template v-if="can_see_auxiliar">

                    <a to="#/auxiliar"
                       class="px-3 py-2 cursor-pointer group flex items-center text-sm leading-5 font-medium rounded-md text-gray-300 hover:text-white focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150"
                       active-class="bg-gray-900 text-white" @click.prevent="showAuxiliar = !showAuxiliar">
                        <i class="w-6 m-1 fa fa-cogs flex-shrink-0 text-center text-xl transition ease-in-out duration-150"></i>
                        <div class="flex-1 ml-3 flex justify-between items-center">
                            <span>{{ $t('menu_links.Auxiliar') }}</span>
                            <i class="w-6 opacity-75 text-white fa fa-caret-down flex-shrink-0 text-center text-sm transition ease-in-out duration-150"
                               :class="{'fa-caret-down' : !showAuxiliar, 'fa-caret-up' : showAuxiliar}"></i>
                        </div>
                    </a>

                    <OpacityScaleTransition enter-extra="translate-x-8">
                        <div v-if="showAuxiliar" class="py-2 pl-3 pr-0 space-y-2">
    <!--						&lt;!&ndash; Zones !&ndash;&gt;-->
    <!--						<router-link to="/auxiliar/zones"-->
    <!--						             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"-->
    <!--						             active-class="bg-opacity-50 bg-gray-900 text-white">-->
    <!--							<i class="fa fa-map w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>-->
    <!--							<span class="ml-3">{{ $t('menu_links.Zones') }}</span>-->
    <!--						</router-link>-->

                            <!-- Packages !-->
                            <router-link to="/auxiliar/packages"
                                         class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                         active-class="bg-opacity-50 bg-gray-900 text-white">
                                <i class="fa fa-box w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
                                <span class="ml-3">{{ $t('menu_links.Packages') }}</span>
                            </router-link>
                        </div>
                    </OpacityScaleTransition>
                </template>
            </div>
			<hr class="opacity-25">

			<div class="flex flex-col space-y-2">
                <!-- Reports !-->
                <router-link :to="{ name: 'reports' }"
                             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                             active-class="bg-gray-900 text-white">
                    <i class="w-6 flex-shrink-0 text-center m-1 fa fa-chart-pie-alt text-xl transition ease-in-out duration-150"></i>
                    <OpacityScaleTransition enter-extra="translate-x-8">
                        <span class="ml-3">{{ $t('menu_links.Reports') }}</span>
                    </OpacityScaleTransition>
                </router-link>
				<!-- Notifications !-->
				<router-link to="/notifications"
				             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
				             active-class="bg-opacity-50 bg-gray-900 text-white">
					<i class="fa fa-bell w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
					<OpacityScaleTransition enter-extra="translate-x-8">
						<span class="ml-3">{{ $t('menu_links.Notifications') }}</span>
					</OpacityScaleTransition>
				</router-link>
                <template v-if="can_see_reviews">
                    <!-- Reviews !-->
                    <router-link to="/reviews"
                                 class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
                                 active-class="bg-opacity-50 bg-gray-900 text-white">
                        <i class="fa fa-star w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
                        <span class="ml-3">{{ $t('menu_links.Reviews') }}</span>
                    </router-link>
                </template>
				<!-- My Account !-->
				<router-link to="/my-account"
				             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
				             active-class="bg-opacity-50 bg-gray-900 text-white">
					<i class="w-6 flex-shrink-0 text-center m-1 fa fa-pennant text-lg transition ease-in-out duration-150"></i>
					<OpacityScaleTransition enter-extra="translate-x-8">
						<span class="ml-3">{{ $t('menu_links.My Account') }}</span>
					</OpacityScaleTransition>
				</router-link>
			</div>

<!--			<hr class="opacity-25">-->
<!--			<div class="flex flex-col space-y-2"></div>-->
		</div>

		<hr class="mt-auto opacity-25 my-4">

<!--		<div class="px-4">-->
<!--			<router-link to="/faq"-->
<!--			             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"-->
<!--			             active-class="bg-opacity-50 bg-gray-900 text-white">-->
<!--				<i class="m-1 fa fa-question-circle w-6 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>-->
<!--				<OpacityScaleTransition enter-extra="translate-x-8">-->
<!--					<span class="ml-3">{{ $t('menu_links.FAQ') }}</span>-->
<!--				</OpacityScaleTransition>-->
<!--			</router-link>-->
<!--		</div>-->

		<div class="px-4">
			<router-link to="/supports"
			             class="px-3 py-2 group flex items-center text-sm leading-5 font-medium rounded-md  hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
			             active-class="bg-opacity-50 bg-gray-900 text-white">
				<i class="m-1 fa fa-user-headset w-6 flex-shrink-0 text-center text-lg transition ease-in-out duration-150"></i>
				<OpacityScaleTransition enter-extra="translate-x-8">
					<span class="ml-3">{{ $t('menu_links.Support') }}</span>
				</OpacityScaleTransition>
			</router-link>
		</div>

	</nav>
</template>

<script>
import OpacityScaleTransition from "@/components/OpacityScaleTransition"
import {get} from "vuex-pathify"

export default {
	name: "MenuLinks",
	components: {OpacityScaleTransition},
	props: {
		showLabels: {
			type: [Boolean, Object],
			default: true
		}
	},
	data() {
		return {
			showPacotes: false,
			showAuxiliar: false,
			showShipments: false,

            can_see_clients: false,
            can_see_integrations: true,
            can_see_stock: true,
            can_see_services: false,
            can_see_bags_master: false,
            can_see_auxiliar: false,
            can_see_shipment_deliveries: false,
            can_see_reviews: false,
            can_see_shipment_pickup: false
		}
	},
	computed: {
		hasNewAvaliacoes() {
			return false;
		},
		hasNewNotifications() {
			return false
		},
		// hasNewNotifications: get('Notifications/hasNewNotifications')
	}
}
</script>
