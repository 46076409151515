<template>
	<section :class="{'self-end' : direction === 'end'}">
		<ContextMenu @update:open="isContextMenuOpen = $event">
			<div class="w-fit px-3 py-2 rounded shadow-md cursor-default border" :class="isContextMenuOpen ?  'bg-gray-100 border-gray-400' : 'border-transparent bg-white'">
				<p v-if="showMember && !chatRoomState.currentUserId !== member.id" class="font-semibold">{{ member.name }}</p>
				<p v-if="showMember && chatRoomState.currentUserId === member.id" class="font-semibold">Você</p>
				<p>{{ message.body }}</p>
			</div>
			<template #items>
				<!-- Emotions !-->
				<div class="p-2 flex justify-between">
					<template v-for="(emotion, index) in emotionList">
						<button type="button" @click.stop.prevent="handleReaction(emotion)" class="rounded-full hover:ring-blue-200 hover:ring-2 p-3.5 flex justify-center items-center">
							<span v-html="emotion.icon" class="text-lg absolute"></span>
						</button>
					</template>
				</div>
				<!-- Copy !-->
				<ContextMenuItem icon="fal fa-copy" text="Copiar" divider />
				<!-- Delete !-->
				<ContextMenuItem icon="fal fa-eraser" text="Apagar" divider />
			</template>
		</ContextMenu>
<!--		<div class="flex items-center space-x-2">-->
<!--			<div class="w-fit px-3 py-2 bg-white rounded shadow-md">-->
<!--				<p v-if="showMember" class="font-semibold">{{ member.name }}</p>-->
<!--				<p>{{ message.body }}</p>-->
<!--			</div>-->
<!--			<time class="text-xs">{{ $displayTime(message.created_at) }}</time>-->
<!--		</div>-->
	</section>
</template>

<script>
import ContextMenu from "@/components/ContextMenu.vue"
import ContextMenuItem from "@/components/ContextMenuItem.vue"

export default {
	name: "ChatMessage",
	components: {ContextMenu,ContextMenuItem},
	props: {
		showMember: Boolean,
		member: Object,
		message: Object,
		direction: String
	},
	inject: ['chatRoomState'],
	data(){
		return {
			isContextMenuOpen: false,
			emotionList: [
				{ icon: '&#128077;', code: 'like' },
				{ icon: '&#128078;', code: 'unlike'},
				{ icon: '&#128512;', code: 'happy'},
				{ icon: '&#128514;', code: 'smiling'},
				{ icon: '&#128540;', code: 'funny'},
				{ icon: '&#128542;', code: 'sad'},
			]
		}
	},
	methods: {
		handleReaction(emotion){
			console.log(emotion);
		}
	}
}
</script>