import Auth from "@/API/Auth"
import User from "@/API/User"
import SystemMixin from "@/mixins/system"
import LocaleMixin from "@/mixins/locale"
import LoadingMixin from "@/mixins/loading"
import {get, sync} from "vuex-pathify"
import ValidationMixin from "@/mixins/validation.js"
import {email, minLength, required, sameAs} from "@vuelidate/validators"

import {scrollToTop} from "@/helpers";

import {options as documentTypes} from "@/enums/document_type"

export default {
    mixins: [LoadingMixin, SystemMixin, ValidationMixin, LocaleMixin],
    computed: {
        ...sync('Register', ['form']),
        ...get('Auxiliar', ['countries']),
        documentTypes() {
            return translateEnum(this, documentTypes, 'documentType.')
        }
    },
    methods: {
        scrollToTop,
        validate() {
            this.v$.$touch()

            console.debug('Register/Form@validade validando dados.')

            return !this.v$.$invalid
        },
        async register() {
            if (!this.validate()) return false

            console.debug('Register/Form@validade sending data.', copy_obj(this.form))

            this.loading()

            let [err, res] = []

            if (this.userId) {
                [err, res] = await Auth.registerSocial(
                    object_to_formdata(this.form)
                )
            } else {
                [err, res] = await Auth.register(
                    object_to_formdata(this.form)
                )
            }

            this.loaded()

            if (err) {
                scrollToTop()

                if (err.response?.status == 422) {
                    this.$alertError(err.response.data.errors)
                } else {
                    this.$alertError("Ops, houve um erro ao se registrar!")
                }

                return false
            }

            return true
        },
        async loadUser(){
            console.debug('Register/Form@loadUser loading current user.', this.userId)

            this.loading()
            const [err, res] = await User.current();
            this.loaded()

            if(err) {
                return false
            }

            this.form.name = res.data.data.name

            return true
        },
        init(){
            this.form.user_id = this.userId

            if(this.userId) this.loadUser()
        }
    },
    validations() {
        let form = {
            name: {required},
            country_id: {required},
            postcode: {required},
            address_1: {required},
            city: {required},
            state_id: {required},
            phone: {required}
        }

        if (!this.userId) {
            form = {
                ...form,
                email: {required, email},
                password: {
                    required,
                    minLength: minLength(6)
                },
                password_confirmation: {
                    required,
                    sameAs: sameAs('password')
                },
            }
        }

        return {form}
    }
}
