<template>
	<FormSelect :default-option="false" default-value="10" :options="sizeUnits" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import {options as SizeUnitsOptions} from "@/enums/measure_size_units.js"
import FormSelect from "@/components/FormSelect.vue"

export default {
	name: "FormSizeUnits",
	components: {
		FormSelect
	},
	computed: {
		sizeUnits: () => SizeUnitsOptions
	}
}
</script>