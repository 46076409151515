import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
// import excetions from "./exceptions.json"


export default {
    'pt' : {
        ou: 'ou',
        login: {
            entrar_em_sua_conta: "Entre com sua conta",
            ou_se: "ou",
            cadastre: "cadastre-se",
            manter_conectado: "Manter conectado",
            esqueceu_senha: "Esqueceu sua senha?",
            username: "E-mail",
            password: "Senha",
            entrar: "Entrar"
        },
        password_recovery: {
            recupere_seu_acesso: "Recupere seu acesso",
            recover: "Recuperar",
            entre_com_sua_conta: "entre com sua conta",
            username: "Email de acesso",
        },
        validation: {
            campo_obrigatorio: "Campo obritatório"
        },
        ... excetions.pt
    },
    'pt-PT' : {
        ou: 'ou',
        login: {
            entrar_em_sua_conta: "Entrar em sua conta",
            ou_se: "ou se",
            cadastre: "cadastre",
            manter_conectado: "Manter conectado",
            esqueceu_senha: "Esqueceu sua senha?",
            username: "E-mail",
            senha: "Senha",
            entrar: "Entrar"
        },
        password_recovery: {
            recupere_seu_acesso: "Recupere seu acesso",
            recover: "Recuperar",
            entre_com_sua_conta: "entre com sua conta",
            username: "Email de acesso"
        },
        validation: {
            campo_obrigatorio: "Campo obritatório"
        },
        ... excetions.pt
    },
    'en' : {
        ou: 'or',
        login: {
            entrar_em_sua_conta: "Sign in to your account",
            ou_se: "or",
            cadastre: "register",
            manter_conectado: "Remember me",
            esqueceu_senha: "Forgot your password?",
            username: "E-mail",
            password: "Password",
            entrar: "Login"
        },
        password_recovery: {
            recupere_seu_acesso: "Recupere seu acesso",
            recover: "Recuperar",
            entre_com_sua_conta: "entre com sua conta",
            username: "Email de acesso"
        },
        validation: {
            campo_obrigatorio: "Campo obritatório"
        },
        ... excetions.en
    },
    'es-ES' : {
        ou: 'ou',
        login: {
            entrar_em_sua_conta: "Entrar em sua conta",
            ou_se: "ou se",
            cadastre: "cadastre",
            manter_conectado: "Manter conectado",
            esqueceu_senha: "Esqueceu sua senha?",
            username: "E-mail",
            password: "Senha",
            entrar: "Entrar"
        },
        password_recovery: {
            recupere_seu_acesso: "Recupere seu acesso",
            recover: "Recuperar",
            entre_com_sua_conta: "entre com sua conta",
            username: "Email de acesso"
        },
        validation: {
            campo_obrigatorio: "Campo obritatório"
        },
        ... excetions.pt
    },
}
