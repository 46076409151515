<template>
	<FormSelect :default-option="false" default-value="10" :options="weightUnits" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import {options as WeightUnitsOptions} from "@/enums/measure_weight_units.js"
import FormSelect from "@/components/FormSelect.vue"

export default {
	name: "FormWeightUnits",
	components: {
		FormSelect
	},
	computed: {
		weightUnits: () => WeightUnitsOptions
	}
}
</script>