import Vue from "vue"
import Vuex from "vuex"

// TODO: Remove dependency
import pathify from 'vuex-pathify'

import APP from "./stores/APP.js"
import Register from "./stores/Register.js"
import Account from "./stores/Account.js"
import Calculator from "./stores/Calculator.js"
import Services from "./stores/Services.js"
import ServiceGroups from "./stores/ServiceGroups.js"
import Countries from "./stores/Countries.js"
import States from "./stores/States.js"
import Support from "./stores/Support.js"
import Currencies from "./stores/Currencies.js"
import Locales from "./stores/Locales.js"
import Languages from "./stores/Languages.js"
import Auxiliar from "./stores/Auxiliar.js"
import Domain from "./stores/Domain.js"
import User from "./stores/User.js"
import Users from "./stores/Users.js"
import Profile from "./stores/Profile.js"
import Packages from "./stores/Packages.js"
import Airports from "./stores/Airports.js"
import Partners from "./stores/Partners.js"
import Contacts from "./stores/Contacts.js"
import Providers from "./stores/Providers.js"
import PriceTable from "./stores/PriceTable.js"
import PriceTables from "./stores/PriceTables.js"
import Subsidiaries from "./stores/Subsidiaries.js"
import Clients from "./stores/Clients.js"
import ClientAddresses from "./stores/ClientAddresses.js"
import Shipments from "./stores/Shipments.js"
import Notifications from "./stores/Notifications.js"
import Processes from "./stores/Processes.js"
import BackgroundTasks from "./stores/BackgroundTasks.js"
import ShippingWizard from "./stores/ShippingWizard.js"
import TrackingEvents from "./stores/TrackingEvents.js"
import Banks from "./stores/Banks.js"
import Bags from "./stores/Bags.js"
import Masters from "./stores/Masters.js"
import Mawbs from "./stores/Mawbs.js"
import Manifests from "./stores/Manifests.js"
import Purchases from "./stores/Purchases.js"
import Warehouses from "./stores/Warehouses.js"
import Indications from "./stores/Indications.js"
import Imports from "./stores/Imports.js"
import Inventories from "./stores/Inventories.js"
import Stocks from "./stores/Stocks.js"
import StockItems from "./stores/StockItems.js"
import Documents from "./stores/Documents.js"
import Departments from "./stores/Departments.js"
import SupportSubjects from "./stores/SupportSubjects.js"
import SupportTickets from "./stores/SupportTickets.js"
import Integrations from "./stores/Integrations.js"
import Webhooks from "./stores/Webhooks.js"
import Invoices from "./stores/Invoices.js"
import Orders from "./stores/Orders.js"
import TaxTables from "./stores/TaxTables.js"
import Chat from "./stores/Chat.js"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        APP,
        Register,
        Account,
        Banks,
        Calculator,
        Auxiliar,
        Countries,
        ClientAddresses,
        States,
        Currencies,
        Languages,
        Locales,
        Domain,
        User,
        Users,
        Profile,
        Packages,
        Airports,
        Notifications,
        Processes,
        BackgroundTasks,
        Partners,
        Stocks,
        Providers,
        Contacts,
        Clients,
        Services,
        Subsidiaries,
        ShippingWizard,
        Shipments,
        TrackingEvents,
        Bags,
        Masters,
        Mawbs,
        PriceTables,
        Support,
        Warehouses,
        PriceTable,
        Purchases,
        Indications,
        Imports,
        Inventories,
        Manifests,
        Documents,
        StockItems,
        Departments,
        SupportSubjects,
        SupportTickets,
        Integrations,
        ServiceGroups,
        Webhooks,
        Invoices,
        Orders,
        TaxTables,
        Chat
    },
    plugins: [pathify.plugin]
})
