import {sync} from "vuex-pathify"

export default {
    computed: {
        ...sync('APP', ['modal'])
    },
    methods: {
        $modal(title, message, opt = {}) { // type = 'default', timeout = 5, withClose = false
            this.modal.show = true
            this.modal.title = title
            this.modal.message = message

            // modal: {
            //     show: false,
            //         type: 'success',
            //         iconPosition: 'left',
            //         action: "confirm",
            //         title: "Cadastro efetuado",
            //         message: "Aguarde enquanto sua conta seja verificada.",
            //         withButtons: ['Confirmar', 'Cancelar'],
            //         onAction(){ console.log('Action') }
            // }

            opt = {
                withText: false,
                withTextarea: false,
                action: null,
                buttons: null,
                ... opt
            }

            Object.getOwnPropertyNames(opt).forEach((key) => this.modal[key] = opt[key])
        },
        $modalSuccess(title, message, opt = {}){
            this.$modal(title, message, { type: 'success', ...opt })
        },
        $modalInfo(title, message, opt = {}){
            this.$modal(title, message, { type: 'info', ...opt })
        },
        $modalWarning(title, message, opt = {}){
            this.$modal(title, message, { type: 'warning', ...opt })
        },
        $modalError(title, message, opt = {}){
            this.$modal(title, message, { type: 'error', ...opt })
        },
        $modalMessage(title, message, opt = {}){
            this.$modal(title, message, { type: 'message', ...opt })
        },
        async $modalConfirm(title, message, opt = {}){
            return new Promise((res, rej) => {
                this.$modal(title, message, { type: 'message', action: 'confirm', onAction(act, text) {
                    res((opt.withText || opt.withTextarea) ? [act, text] : act)
                }, ...opt })
            })
        },
        async $modalConfirmTrue(title, message, opt = {}){
            return new Promise((res, rej) => {
                this.$modal(title, message, { type: 'message', action: 'confirm', onAction(act, text) {
                        res((opt.withText || opt.withTextarea) ? [act === 'confirm', text] : act === 'confirm')
                    }, ...opt })
            })
        },
        $modal403(message = null, opt = {}){
            this.$modal("Não autorizado", message ?? "Você não pode executar esta ação!", { type: 'warning', ...opt })
        }
    }
}
