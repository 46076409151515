import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    page: [],
    formPreAlert: {},
    form: {
        partner: null,
        partner_id: null,
        provider: null,
        provider_id: null,
        lot: null,
        expiration_date: null,
        removed_itens: []
    }
}

const mutations = make.mutations(state)

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
