import {make} from "vuex-pathify"
import Auxiliar from "@/API/Auxiliar"
import Countries from "@/API/Countries"

const state = {
    isLoading: false,
    countries: [],
    form: {
        name_locale: {},
    },
    page: [],
    select: [],
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    async loadCountries({commit, dispatch}) {

        dispatch('loading')

        const [err, res] = await Auxiliar.countries()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadCountries', err)
            commit('SET_COUNTRIES', [])
            return false
        }

        commit('SET_COUNTRIES', res.data)
    },
    async loadSelect({commit, dispatch}) {
        dispatch('loading')

        const [err, res] = await Countries.list({"_type": "select"});

        dispatch('loaded')

        if (err) {
            console.error(err)
        }

        commit('SET_SELECT', res.data?.data ?? [])
    },
    clearSelect({commit}) {
        commit('SET_SELECT', [])
    },
    async init({commit, dispatch}) {
        //
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
