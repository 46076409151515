import {sync} from "vuex-pathify"

export default {
    computed: {
        ...sync('APP', ['slideover'])
    },
    methods: {
        $slideover(title, component, componentAttr, opt) { // type = 'default', timeout = 5, withClose = false
            this.slideover.title = title
            this.slideover.show = true
            this.slideover.component = component
            this.slideover.componentAttr = componentAttr

            const defaults = {
                type: 'default'
            }

            const config = {...defaults, ...opt}

            Object.getOwnPropertyNames(config).forEach((key) => this.slideover[key] = config[key])
        },
        $slideEmpresaCard(id, attr = {}, opt = {}) {

            opt = {size: 'max-w-screen-sm', ...opt}

            this.$slideover(false, 'CardClientePage', {id, ...attr}, opt)
        },
        $slideMensagem(to, room, attr = {}, opt = {}) {

            opt = {size: 'max-w-screen-sm', ...opt}

            this.$slideover('Mensagem', 'CardMensagemPage', {to, room, ...attr}, opt)
        }
    }
}
