import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http(window.API_PREFIX + "/partners", {params}))
    },
    async get(id) {
        return to(Http(window.API_PREFIX + `/partners/${id}`))
    },
    async getSelect(params = {}) {
        return this.list({_type: "select", ...params})
    },
    async insert(data) {
        return to(Http.post(window.API_PREFIX + `/partners`, data))
    },
    async update(id, data) {
        data.append('_method', 'patch')
        return to(Http.post(window.API_PREFIX + `/partners/${id}`, data))
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/partners/${id}`, {'_method': 'delete'}))
    },
    async save(data, id = null) {
        return id ? this.update(id, data) : this.insert(data)
    },
    async loadConfig(id, key = null) {
        return to(Http(window.API_PREFIX + `/partner-configs/${id}/${key}`))
    },
    async saveConfig(id, key, config) {
        return to(Http.post(window.API_PREFIX + `/partner-configs/${id}/${key}`, config))
    },
    async loadAddress(id = '') {
        return to(Http.get(window.API_PREFIX + `/partner-address/${id}`))
    },
    async loadServices(id) {
        return to(Http(window.API_PREFIX + `/partners/${id}/partner_services`))
    },
    async loadService(partnerId, id) {
        return to(Http(window.API_PREFIX + `/partners/${partnerId}/partner_services/${id}`))
    },
    async storeService(partnerId, data) {
        return to(Http.post(window.API_PREFIX + `/partners/${partnerId}/partner_services`, data))
    },
    async updateService(partnerId, id, data) {
        data._method = 'PATCH'
        return to(Http.post(window.API_PREFIX + `/partners/${partnerId}/partner_services/${id}`, data))
    },
    async saveService(partnerId, data, id = null) {
        return id ? this.updateService(partnerId, id, data) : this.storeService(partnerId, data)
    },
    async deleteService(partnerId, id) {
        return to(Http.post(window.API_PREFIX + `/partners/${partnerId}/partner_services/${id}`, {'_method': 'delete'}))
    },
}

