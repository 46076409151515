<i18n src="@/i18n/shared/support/form.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<Page
		:title="$t('support.title')"
		icon-back
		:titleLoading="isBgLoading"
		:loading="isLoading">
		<!-- Action !-->
		<template v-slot:after-title>
			<!-- Save !-->
			<SaveButton class="ml-auto" @click="handleSave"/>
		</template>
		<!-- / Action !-->
		<Card :title="$t('support.general')" class="mt-4">
			<FormGroup>
				<!-- Shipment !-->
				<FormLine :label="$t('support.shipment')">
					<FormInput v-model="form.shipment" :validation="v$.form.shipment" class="max-w-xl"/>
				</FormLine>
				<!-- Subject !-->
				<FormLine :label="$t('support.subject')">
					<SupportSubjectSelect v-model="form.support_subject_id" :validation="v$.form.support_subject_id" class="max-w-xl" />
				</FormLine>
				<!-- Body !-->
				<FormLine :label="$t('support.message')">
					<FormTextArea v-model="form.body" rows="10" :validation="v$.form.body" class="max-w-xl"/>
				</FormLine>
				<!-- Phone !-->
				<FormLine :label="$t('support.phone')">
					<FormPhone v-model="form.phone" :validation="v$.form.phone" class="max-w-xl"/>
				</FormLine>
				<!-- Email !-->
				<FormLine :label="$t('support.email')">
					<FormEmail v-model="form.email" :validation="v$.form.email" class="max-w-xl"/>
				</FormLine>
			</FormGroup>
		</Card>
		<!-- Actions !-->
		<div class="mt-4 px-4 sm:px-0 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:justify-between">
			<!-- Cancel !-->
			<CancelButton @click="handleCancel"/>
			<!-- Save !-->
			<SaveButton @click="handleSave"/>
		</div>
		<!-- / Actions !-->
	</Page>
</template>

<script>
import Mixin from "./mixins/form.js"

import Page from "@/components/Page.vue"
import Card from "@/components/Card.vue"
import CancelButton from "@/components/CancelButton.vue"
import SaveButton from "@/components/SaveButton.vue"

import FormGroup from "@/components/FormGroup.vue"
import FormLine from "@/components/FormLine.vue"
import FormInput from "@/components/FormInput.vue"
import FormTextArea from "@/components/FormTextArea.vue"
import FormPhone from "@/components/FormPhone.vue"
import FormEmail from "@/components/FormEmail.vue"
import SupportSubjectSelect from "@/domain/support_subjects/SupportSubjectSelect.vue";

export default {
	name: "FormPage",
	mixins: [Mixin],
	components: {
		Page,
		Card,
		FormTextArea,
		FormGroup,
		FormLine,
		FormInput,
		FormPhone,
		FormEmail,
		SaveButton,
		CancelButton,
		SupportSubjectSelect,
	},
	methods: {
		/**
		 * Handler save
		 * @returns {Promise<void>}
		 */
		async handleSave() {
			if(await this.save()) {
				this.$alertFormSaved()
				this.$router.push({name: "supports.index"})
			}
		},
		/**
		 * Handler cancel
		 */
		handleCancel(){
			this.$router.push({name: "supports.index"})
		}
	},
	mounted() {
		this.init()
	}
}
</script>
