import {enum_to_hash} from "../helpers.js"

export default {
    "INATIVO": 0,
    "ATIVO": 1
}

export const options = [
    { id: 0, text: 'inactives' },
    { id: 1, text: 'actives' },
    { id: 2, text: 'excludes' }
]
