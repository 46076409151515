<i18n src="@/i18n/components/CancelButton.json"></i18n>
<template>
	<button
		class="inline-flex items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-red-500 hover:text-red-800 hover:border-transparent focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
		v-on="$listeners">
		<i class="fa fa-times mr-2"></i>
		<slot>{{ $t('cancel_button.label') }}</slot>
	</button>
</template>
<script>
export default {
	name: "CancelSimpleButton"
}
</script>
