import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    languages: [],
    form: {
        title: null,
        title_locale: [],
        profiles: []
    },
    page: [],
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
