<i18n src="@/i18n/domain/chat/chat_room_creation.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<section ref="container"
			 class="flex flex-col bg-gray-50 border border-gray-200 pointer-events-auto rounded-md transform hover:shadow-xl transition-all"
			 :class="{
		'shadow-lg translate-y-0' : open,
		'w-[220px] shadow-lg translate-y-[calc(100%-50px)]' : !open ,
		'w-[500px] h-[700px]' : expanded && open,
		'w-[340px] h-[400px]' : !expanded && open,
		'h-[400px]' : !expanded && !open
	}">
		<!-- Title, Status, New Messages !-->
		<header class="p-4 flex items-center cursor-pointer" :class="{ 'hover:bg-gray-100' : !open }">
			<!-- Title !-->
			<h3 class="font-semibold truncate"
				:class="{
				'max-w-[160px]' : !open,
				'max-w-[220px]' : open && !expanded,
				'max-w-[380px]' : open && expanded,
				}">{{ $t('chat_room_creation.title') }}</h3>
			<!-- Open/Close !-->
			<button type="button" class="ml-auto p-3 rounded-full text-[12px] hover:bg-gray-200 tabular-nums flex justify-center items-center"
					:class="{ 'ml-auto' : !open, 'ml-3' : open }" @click.stop="handleClose">
				<i class="absolute fal fa-times text-lg"></i>
			</button>
		</header>
		<div class="flex-1 flex flex-col p-4 border-t border-gray-200">
			<!-- Messages !-->
			<div class="flex-1 flex flex-col space-y-2">
				<!-- Group Title !-->
				<div v-if="isGroup">
					<label class="text-sm font-medium leading-5 text-gray-900 flex items-center relative">{{ $t('chat_room_creation.form.group_name') }}</label>
					<FormInput v-model="form.title" />
				</div>
				<!-- Search User !-->
				<div class="flex flex-col">
					<div>
						<label class="text-sm font-medium leading-5 text-gray-900 flex items-center relative">{{ $t('chat_room_creation.form.participants') }}</label>
						<UserSelect v-model="currentMember" @update:user="handleAddMember" />
					</div>
				</div>
				<div class="flex-1 flex flex-col">
					<!-- Sub Title !-->
					<h3 class="text-sm font-medium leading-5 text-gray-900 flex items-center relative sm:pt-2">{{ $t('chat_room_creation.form.participants_chat') }}</h3>
					<!-- ParticipantList !-->
					<div class="flex-grow h-0 overflow-scroll">
						<ul role="list" class="divide-y divide-gray-200">
<!--							<li class="flex gap-x-6 py-5">-->
<!--								<div class="flex flex-grow min-w-0 gap-x-4">-->
<!--									<img class="h-12 w-12 flex-none rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
<!--									<div class="min-w-0 flex-auto">-->
<!--										<p class="text-sm font-semibold leading-6 text-gray-900">{{ user.name }}</p>-->
<!--										<p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ user.company_name }}</p>-->
<!--									</div>-->
<!--								</div>-->
<!--								<div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">-->
<!--									<p class="text-sm leading-6 text-gray-900">{{ user.department }}</p>-->
<!--									&lt;!&ndash;								<p class="mt-1 text-xs leading-5 text-gray-500">Last seen <time datetime="2023-01-23T13:23Z">3h ago</time></p>&ndash;&gt;-->
<!--								</div>-->
<!--							</li>-->

							<UserItemList :user="user" />

							<template v-for="(member, index) in form.members">
								<UserItemList :user="member">
									<div>
										<button type="button" class="inline-flex items-center px-2 py-1.5 text-sm leading-5 font-medium text-red-500  hover:bg-gray-50 focus:outline-none hover:text-red-600" @click.stop.prevent="handleRemoveParticipant(user, index)">
											<i class="fal fa-times"></i>
										</button>
									</div>
								</UserItemList>
<!--								<li class="flex gap-x-6 py-5">-->
<!--									<div class="flex flex-grow min-w-0 gap-x-4">-->
<!--										<img class="h-12 w-12 flex-none rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
<!--										<div class="min-w-0 flex-auto">-->
<!--											<p class="text-sm font-semibold leading-6 text-gray-900">{{ member.label }}</p>-->
<!--											<p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ member.company_name }}</p>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">-->
<!--										<p class="text-sm leading-6 text-gray-900">{{ member.company_rule }}</p>-->
<!--										&lt;!&ndash;									<p class="mt-1 text-xs leading-5 text-gray-500">Last seen <time datetime="2023-01-23T13:23Z">3h ago</time></p>&ndash;&gt;-->
<!--									</div>-->
<!--									<div>-->
<!--										<button type="button" class="inline-flex items-center px-2 py-1.5 text-sm leading-5 font-medium text-red-500  hover:bg-gray-50 focus:outline-none hover:text-red-600" @click.stop.prevent="handleRemoveParticipant(member, index)">-->
<!--											<i class="fal fa-times"></i>-->
<!--										</button>-->
<!--									</div>-->
<!--								</li>-->
							</template>
						</ul>
					</div>
					<!-- / ParticipantList !-->
				</div>
			</div>
			<!--		&lt;!&ndash; Message Input !&ndash;&gt;-->
			<div class="p-2">
				<button type="button" class="w-full py-3.5 px-3 font-semibold rounded text-center text-white"
						:class="hasMembers ? 'bg-blue-500 hover:bg-blue-600' : 'bg-blue-400'" @click="handleSave">
					{{ hasMembers ? (isGroup ? $t('chat_room_creation.form.create_room') : $t('chat_room_creation.form.start_chat')) : $t('chat_room_creation.form.add_participants') }}
				</button>
			</div>
		</div>
	</section>
</template>

<script>
import FormInput from "@/components/FormInput.vue";
import UserSelect from "@/domain/users/UserSelect.vue";
import {get} from "vuex-pathify";
import UserItemList from "@/domain/users/UserItemList.vue";

export default {
	name: "ChatRoomCreation",
	props: {
		open: Boolean,
		expanded: {
			type: Boolean,
			default: true
		},
	},
	components: {
		UserItemList,
		FormInput,
		UserSelect
	},
	data(){
		return {
			currentMember: null,
			form: {
				title: null,
				members: []
			}
		}
	},
	computed: {
		user: get('APP/profile'),
		isGroup(){
			return this.form.members?.length > 1
		},
		hasMembers(){
			return this.form.members?.length > 0
		}
	},
	methods: {
		handleExpand() {
			this.$emit('update:expanded', !this.expanded)
		},
		handleClose() {
			this.$emit('update:open', false)
		},
		handleInput(e) {
			e.target.rows = e.target.value?.search('\n') !== -1 ? e.target.value?.split('\n').length : 1
		},
		handleAddMember(e){
			if(!e.id) return
			this.form.members.push(e)
			this.currentMember = null
		},
		handleRemoveParticipant(member, index){
			this.form.members.splice(index, 1)
		},
		handleSave(){
			const form = copy_obj(this.form)
			form.id = Math.random()
			form.last_message = "..."
			form.show = true;
			form.open = true
			form.expanded = true
			form.is_group = this.isGroup

			if(form.members.length === 1) {
				form.title = form.members[0].label
			}

			this.$emit('room-created', form)
			this.$emit('update:open', false)
		}
	}

}
</script>