import {make} from "vuex-pathify"
import BackgroundTasks from "@/API/BackgroundTasks.js"

const state = {
    isLoading: false,
    list: [],
    hasNewBackgroundTask: 0,
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    async load({commit, dispatch}) {

        const [err, res] = await BackgroundTasks.list()

        if(err) {
            console.error(err)
            return false
        }

        commit('SET_LIST', res.data?.data ?? [])

        dispatch('calcNewBackgroundTask')

        return true
    },
    async addProcess({commit, state, dispatch}, payload) {
        commit('SET_LIST', payload)
        dispatch('calcNewBackgroundTask')
    },
    async setProgress({commit, state, dispatch}, payload) {
        const list = state.list
        const process = list.find((item) => item.id === payload.id)
        if(process) process.progress = payload.progress
    },
    async pushNotification({commit, dispatch, state}, payload) {
        commit('SET_LIST', [...state.list, payload])
        dispatch('calcNewBackgroundTask')

        return true
    },
    calcNewBackgroundTask({commit, dispatch, state}){
        commit('SET_HAS_NEW_BACKGROUND_TASK', state.list.filter((item) => !item.completed_at && !item.failed_at).length)
    },
    async init({commit, dispatch, state}){
        if(state.list.length) return true

        await dispatch('load')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
