import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    page: [],
    form: {
        from_name: null,
        from_trading_name: null,
        from_responsible: null,
        from_document: null,
        from_document_type: null,
        from_address_1: null,
        from_address_2: null,
        from_address_number: null,
        from_address_complement: null,

    },
    view: {
        form: {},
        can: {}
    }
}

const mutations = make.mutations(state)

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
