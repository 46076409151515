<template>
  <div class="sm:max-w-sm w-full absolute bottom-0 sm:right-0 sm:bottom-auto sm:top-0 z-30">
    <div class="flex-col items-end justify-center pointer-events-none sm:items-end sm:justify-center m-4 sm:m-6">
      <transition-group
          enter-active-class="transform ease-out duration-300 transition"
          enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          tag="div">
        <template v-for="(notification, index) in notificationToShow">
          <Notification :key="`notification-${index}`" v-bind="notification.data" @input="handleRemoveNotification(notification, index)" class="mb-2 sm:ml-auto"/>
        </template>
      </transition-group>
    </div>
  </div>
</template>

<script>

import Notification from "@/components/Notification"
import {sync} from "vuex-pathify"

export default {
  name: "AppNotification",
  components: {Notification},
  computed: {
    ...sync('Notifications', ['floatNotifications']),
    notificationToShow() {
      if(this.$router.path == '/notifications') return []
      return this.floatNotifications
    }
  },
  methods: {
    handleRemoveNotification(notification, index) {
      this.floatNotifications.splice(index, 1)
    }
  }
}
</script>
