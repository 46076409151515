import {enum_to_hash} from "../helpers.js"

export default {
    "KG": 10,
    "LBS": 11
}

export const options = [
    {id: 10, label: 'KG'},
    {id: 20, label: 'LBS'}
]

export const hash = enum_to_hash(options)