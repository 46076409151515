<i18n src="@/i18n/error.json"></i18n>
<template>
	<div id="App" class="flex-1 text-sm">
		<router-view/>
		<!-- Imports !-->
<!--		<ImportContainer />-->
		<!-- Notifications !-->
		<NotificationsContainer/>
		<!-- Alerts !-->
		<AlertsContainer/>
		<!-- Chat !-->
		<ChatContainer/>
		<!-- Portals !-->
		<portal-target name="overlay"/>
		<portal-target name="dropdown"/>
	</div>
</template>

<script>
import {dispatch} from "vuex-pathify"
import NotificationsContainer from "@/components/Notifications/NotificationsContainer.vue"
import AlertsContainer from "@/components/Alerts/AlertsContainer.vue"
import ChatContainer from "@/domain/chat/ChatContainer.vue"
import {storageAvailable} from "@/support/storage.js";
import ImportContainer from "@/pages/backoffice/imports/ImportContainer.vue";

export default {
	name: "App",
	components: {
		ImportContainer,
		NotificationsContainer,
		AlertsContainer,
		ChatContainer,
	},
	data(){
		return {
			file: null,
			showDropItem: false
		}
	},
	created() {
		dispatch("Auxiliar/init")
		dispatch("Locales/init")
		dispatch("Notifications/init")
		dispatch("Processes/init")

		if (!storageAvailable('localStorage')) return false
		localStorage.removeItem('shipments.index.filter', null)
	}
};
</script>
<style>
html, body {
	height: 100%;
}
</style>