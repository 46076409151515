<template>
	<div class="relative shadow-sm rounded-md" :class="$tw('bg-blue-500', baseClass)">
		<div ref="base" class="relative">
			<div class="flex divide-x divide-blue-600">
				<div class="w-full relative z-0 flex rounded-md divide-x" :class="$tw('divide-blue-600',wrapperClass)">
					<!-- Current Action !-->
					<button class="w-full relative flex items-center py-2 pl-3 pr-4 border border-transparent rounded-l-md text-white transition-colors"
							:class="[$tw('hover:bg-blue-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-600', buttonActionClass)]"
							@click="handleClickDefault">
						<!-- icon !-->
						<template v-if="icon">
							<i :class="icon"></i>
						</template>
						<!-- label !-->
						<span class="text-sm font-medium" :class="{ 'ml-2.5' : icon }">
                            <template v-if="currentItem">
                                {{ currentItem.label }}
                            </template>
                            <slot v-else name="default"/>
                        </span>
					</button>
					<!-- ShowOptions !-->
					<button type="button"
							class="relative inline-flex items-center p-2 rounded-l-none rounded-r-md text-sm font-medium text-white transition-colors"
							:class="$tw('hover:bg-blue-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-700', buttonDropClass)"
							aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"
							@click="handleShowOptions">
						<i class="fa fa-chevron-down"></i>
					</button>
				</div>
			</div>
			<transition leave-active-class="transition ease-in duration-100" leave-class="opacity-100" leave-to-class="opacity-0">
				<template v-if="showOptions">
					<ul ref="tooltip"
						class="z-30 origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
						:class="listClass"
						tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-0">
						<slot name="list"/>
					</ul>
				</template>
			</transition>
			<Loading v-if="loading" use-spinner spinner-color="text-blue-500">
				<template #after>
					<span class="px-2">{{ progress }}</span>
				</template>
			</Loading>
		</div>
	</div>
</template>

<script>
import {createPopper} from '@popperjs/core'
import Loading from "@/components/Loading.vue"

export default {
	name: "SelectButton",
	components: {
		Loading
	},
	props: {
		value: [String, Number],
		icon: String,
		baseClass: String,
		buttonActionClass: String,
		buttonDropClass: String,
		listClass: String,
		wrapperClass: String,
		loading: Boolean,
		progress: [String, Number]
	},
	data() {
		return {
			currentItem: null,
			showOptions: false,
			popper: null
		}
	},
	methods: {
		/**
		 * Handle show options
		 */
		handleShowOptions() {
			this.showOptions = !this.showOptions
		},
		/**
		 * Handle click default
		 */
		handleClickDefault() {
			if (!this.$listeners['click']) {
				this.showOptions = !this.showOptions
				return;
			}

			this.$emit('click', {item: this.currentItem ?? 'default'})
		},
		/**
		 * click away event
		 * @param event
		 */
		away(event) {
			const closest = event.target.closest('.base')
			if (!closest && closest != this.$refs.base) this.showOptions = false
		},
	},
	mounted() {
		// this.popper = createPopper(
		// 	this.$refs.base,
		// 	this.$refs.tooltip
		// )
	},
	beforeDestroy() {
		// this.popper.
	},
	watch: {
		showOptions(value) {
			if (value) {
				setTimeout(() => document.addEventListener('click', this.away, false), 1)
			} else {
				document.removeEventListener('click', this.away, false)
			}
		}
	}
	// watch: {
	// 	showOptions() {
	//
	// 		setTimeout(() => {
	//
	// 			this.popper = createPopper(
	// 				this.$refs.base,
	// 				this.$refs.tooltip,
	// 				{
	// 					modifiers: [
	// 						{
	// 							name: 'offset',
	// 							options: {
	// 								offset: [5, 5],
	// 							},
	// 						},
	// 					],
	// 				}
	// 			)
	//
	// 		}, 0)
	//
	// 	}
	// }
}
</script>