import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get(window.API_PREFIX + "/countries", {params}))
    },
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/countries/${id}`))
    },
    async insert(data) {
        return to(Http.post(window.API_PREFIX + `/countries`, data))
    },
    async update(id, data) {
        data.append("_method", "patch")
        return to(Http.post(window.API_PREFIX + `/countries/${id}`, data))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.insert(data)
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/countries/${id}`, {'_method': 'delete'}))
    }
}

