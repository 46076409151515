<i18n src="@/i18n/notifications/index.json"></i18n>
<template>
	<ContextMenu @update:open="contextOpen = $event" class="p-1" :class="notification.read_at ? 'bg-gray-100' : 'border-transparent'">
		<div class="group flex justify-between items-center space-x-2 border px-5 py-4 rounded group cursor-pointer hover:bg-gray-50 transition duration-150 ease-in-out"
			 :class="
				 [
					 contextOpen ? 'bg-gray-50 border-gray-300 relative' : 'border-transparent'
				 ]"
				@click="handleClick">
			<div class="-m-1 p-1 flex items-center">
				<!-- Icon !-->
				<span class="group-hover:bg-gray-50">
					<i class="fal" :class="notificationIconClass"></i>
				</span>
				<!-- Content !-->
				<div class="ml-4 flex-1 flex items-center min-w-0 relative">
					<div class="truncate">
						<div class="text-sm leading-5 font-medium text-gray-900 truncate">{{ title }}</div>
						<div v-if="hasMessage" class="text-sm leading-5 text-gray-500 truncate">{{ message }}</div>
					</div>
				</div>
				<!-- / Content !-->
			</div>
			<!-- Delete !-->
			<div class="relative text-left hidden group-hover:inline-block">
				<button class="group relative w-8 h-8 inline-flex items-center justify-center focus:outline-none" id="options-menu-0" aria-haspopup="true" aria-expanded="false"
						@click.stop="handleDelete">
					<i class="fal fa-times text-red-500 text-md"></i>
				</button>
			</div>
		</div>
		<template #items>
			<!-- Mark as read !-->
			<ContextMenuItem v-if="!notification.read_at" text="Marcar como lida" icon="fal fa-check" @click="handleRead"/>
			<!-- Mark as Unread !-->
			<ContextMenuItem v-else text="Marcar como não lida" icon="fal fa-check" @click="handleUnread" />
			<!-- Delete !-->
			<ContextMenuItem text="Excluir" icon="fal fa-trash-alt text-red-500" @click="handleDelete" divider/>
		</template>
	</ContextMenu>
</template>
<script>
import ContextMenu from "@/components/ContextMenu.vue"
import ContextMenuItem from "@/components/ContextMenuItem.vue"
import Notifications from "@/API/Notifications.js"
import SystemMixin from "@/mixins/system.js"
import LoadingMixin from "@/mixins/loading.js"
import {dispatch} from "vuex-pathify";

export default {
	name: "NotificationItem",
	mixins: [SystemMixin, LoadingMixin],
	props: {
		notification: {
			type: Object,
			required: true
		}
	},
	components: {
		ContextMenuItem,
		ContextMenu
	},
	data() {
		return {
			contextOpen: false
		}
	},
	computed: {
		/**
		 * Calculate notification type icon class
		 * @returns {VueI18n.TranslateResult}
		 */
		title() {
			return this.$t(`notification.${this.notification.data.code}.title`, this.notification.data.params)
		},
		/**
		 * Calculate notification message
		 * @returns {VueI18n.TranslateResult}
		 */
		message() {
			return this.$t(`notification.${this.notification.data.code}.message`, this.notification.data.params)
		},
		/**
		 * Check if notification has a message
		 * @returns {boolean}
		 */
		hasMessage() {
			return this.message !== `notification.${this.notification.data.code}.message`
		},
		/**
		 * Calculate notification type icon class
		 * @returns {*}
		 */
		notificationIconClass() {
			return {
				'success': 'fa-check-circle text-green-500 text-2xl',
				'warning': 'fa-warning text-yellow-500 text-2xl',
				'error': 'fa-times-circle text-red-500 text-2xl',
				'info': 'fa-info-circle text-gray-500 text-2xl',
			}[this.notification.data.notification_status] ?? false
		},
	},
	methods: {
		/**
		 * Handler click notification
		 * @returns {Promise<boolean>}
		 */
		async handleClick(){
			console.debug('Notifications/List@handleClick: Clicking notification.', {id: this.notification.id})

			const routeAction = this.notification.data.actions?.find(item => item.route != null)

			if(routeAction !== null) {

				const route = {name: routeAction.route}

				if (routeAction.route_params) {
					route.params = routeAction.route_params
				}

				if (routeAction.route_query) {
					route.query = routeAction.route_query
				}

				if (routeAction.route_hash) {
					route.hash = routeAction.route_hash
				}

				await this.$router.push(route).catch(() => {})

				console.info('Notifications/List@handleClick: Notification clicked.', {id: this.notification.id})

				this.$emit("click", { notification: this.notification })
			}

			await this.handleRead()

			return true
		},
		/**
		 * Handler read notification
		 * @returns {Promise<boolean>}
		 */
		async handleRead(){
			console.debug('Notifications/List@readNotification: Marking notification as read.', {id: this.notification.id})

			this.loading()
			const [err, res] = await Notifications.markAsReaded(this.notification.id)
			this.loaded()

			if (err) {
				console.error('Notifications/List@readNotification: Error on read Notification.', {id: this.notification.id})
				return this.$alertError(this.handleRequestError(err))
			}

			console.info('Notifications/List@readNotification: Notification marked as read.', {id: this.notification.id})

			dispatch('Notifications/readNotification', { id: this.notification.id }, {root: true})

			this.$emit("read")

			return true
		},
		async handleUnread(){
			console.debug('Notifications/List@readNotification: Marking notification as not read.', {id: this.notification.id})

			this.loading()
			const [err, res] = await Notifications.markAsNotReaded(this.notification.id)
			this.loaded()

			if (err) {
				console.error('Notifications/List@readNotification: Error on unread Notification.', {id: this.notification.id})
				return this.$alertError(this.handleRequestError(err))
			}

			console.info('Notifications/List@readNotification: Notification marked as not read.', {id: this.notification.id})

			dispatch('Notifications/unreadNotification', { id: this.notification.id }, {root: true})

			this.$emit("read")

			return true
		},
		/**
		 * Handler remove notification
		 * @returns {Promise<boolean>}
		 */
		async handleDelete() {
			console.debug('Notifications/List@handleRemoveNotification: Removing notification.', {id: this.notification.id})

			this.loading()
			const [err, res] = await Notifications.delete(this.notification.id)
			this.loaded()

			if (err) {
				console.error('Notifications/List@readNotification: Error on delete Notification.', {id: this.notification.id})
				return this.$alertError(this.handleRequestError(err))
			}

			console.info('Notifications/List@handleRemoveNotification: Notification removed.', {id: this.notification.id})

			this.$emit("deleted", { notification: this.notification })

			return true
		},
	}
}
</script>