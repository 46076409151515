<template>
	<section ref="container"
			 class="flex flex-col w-[300px] h-[600px] bg-gray-50 border border-gray-200 pointer-events-auto rounded-md transform transition-transform"
			 :class="{
		'translate-y-0 shadow-lg hover:shadow-xl' : open,
		'translate-y-[calc(100%-50px)] shadow-md hover:shadow-xl': !open
	}">
		<!-- Title, Status, New Messages !-->
		<header class="p-4 flex items-center cursor-pointer rounded-t-md" :class="{ 'hover:bg-gray-100' : !open, 'animate-blinkingBg' : !open && hasNewMessages }" @click="handleOpen">
			<!-- Status !-->
			<div class="mr-2 flex-none rounded-full p-1 text-red-400 bg-red-400/10">
				<div class="h-2 w-2 rounded-full bg-current"></div>
			</div>
			<!-- Title !-->
			<h3 class="font-semibold">Menssagens</h3>
			<!-- Badge New Messages !-->
			<div v-if="newMessagesCount > 0" class="ml-3 p-3 rounded-full text-[12px] bg-red-400 tabular-nums flex justify-center items-center">
				<span class="absolute font-semibold text-white">{{ newMessagesCount }}</span>
			</div>
			<!-- Options !-->
			<Dropdown class="ml-auto">
				<button type="button" class="ml-auto p-3 rounded-full text-[12px] hover:bg-gray-200 tabular-nums flex justify-center items-center">
					<i class="absolute fal fa-ellipsis"></i>
				</button>
				<template #items>
					<DropdownItem icon="fa fa-times" text="Fechar" @click="handleCloseApp"/>
				</template>
			</Dropdown>
			<!-- Create Chat !-->
			<button type="button" class="ml-3 p-3 rounded-full text-[12px] hover:bg-gray-200 tabular-nums flex justify-center items-center" @click.stop.prevent="handleCreateRoom">
				<i class="absolute fal fa-edit"></i>
			</button>
			<!-- Open/Close !-->
			<button type="button" class="ml-3 p-3 rounded-full text-[12px] hover:bg-gray-200 tabular-nums flex justify-center items-center" @click.stop="handleOpen">
				<i class="absolute fal" :class="{'fa-angle-up' : !open, 'fa-angle-down': open}"></i>
			</button>
		</header>
		<div class="relative h-0 flex flex-col flex-grow p-2 border-t border-gray-300">
			<template v-if="hasRooms">
				<div ref="scroll" class="flex-1 overflow-y-scroll overflow-x-hidden">
					<ul class="flex flex-col divide-y">
						<template v-for="(room, index) in rooms">
							<ChatAppItem :room="room" :key="`room-${room.id}`" @click="handleOpenRoom(room, index)"/>
						</template>
					</ul>
				</div>
			</template>
			<template v-else>
				<div class="flex-1 flex flex-col justify-center items-center">
					<div class="text-center">
						<i class="fal fa-comments text-4xl text-blue-400"></i>
						<h3 class="mt-2 text-sm font-semibold text-gray-900">Nenhuma conversa</h3>
						<p class="mt-1 text-sm text-gray-500">Comece agora uma nova conversa.</p>
						<div class="mt-6">
							<button type="button"
									class="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
									@click="handleCreateRoom">
								<svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>
								</svg>
								Criar uma conversa ou grupo
							</button>
						</div>
					</div>
				</div>
			</template>
			<!-- Indicator Up !-->
			<p v-if="!scroll.arrivedState.top" class="absolute flex top-1.5 right-1.5 p-2 overflow-hidden items-center justify-center">
				<i class="absolute fa fa-caret-up text-xs text-gray-500"></i>
			</p>
			<!-- Indicator Down !-->
			<p v-if="!scroll.arrivedState.bottom" class="absolute flex bottom-1.5 right-1.5 p-2 overflow-hidden items-center justify-center">
				<i class="absolute fa fa-caret-down text-xs text-gray-500"></i>
			</p>
		</div>
	</section>
</template>

<script>
import {useScroll} from "@vueuse/core";
import ChatAppItem from "@/domain/chat/ChatAppItem.vue"
import Dropdown from "@/components/Dropdown.vue"
import DropdownItem from "@/components/DropdownItem.vue"

export default {
	name: "ChatApp",
	props: {
		open: Boolean,
		hasNewMessages: Boolean,
		newMessagesCount: Number,
		rooms: {
			type: Array,
			required: true
		},
	},
	components: {
		Dropdown,
		ChatAppItem,
		DropdownItem
	},
	model: {
		prop: 'open',
		event: 'update:open'
	},
	data() {
		return {
			scroll: {
				arrivedState: {
					top: true,
					bottom: true
				}
			}
		}
	},
	computed: {
		hasRooms() {
			return this.rooms.filter(item => item.id > 0).length > 0
		}
	},
	methods: {
		handleCloseApp() {
			this.$emit('close', true)
			this.closed()
		},
		/**
		 * Handle open/close chat
		 */
		handleOpen() {
			this.$emit('update:open', !this.open)
		},
		/**
		 * Handle open room
		 * @param room
		 * @param index
		 */
		handleOpenRoom(room, index) {
			this.$emit('openRoom', {room, index})
		},
		/**
		 * Handle create room
		 */
		handleCreateRoom() {
			this.$emit('createRoom')
			this.$emit('update:open', false)
		},
		closed() {
			document.body.classList.remove('relative', 'overflow-hidden')
		}
	},
	mounted() {
		this.scroll = useScroll(this.$refs.scroll)

		this.$refs.container.addEventListener('mouseenter', () => {
			document.body.classList.add('relative', 'overflow-hidden')
		})
		this.$refs.container.addEventListener('mouseleave', () => {
			this.closed()
		})
	}
}
</script>