import Http from "@/config/axios.js"
import { to } from "await-to-js"

export default {
    /**
     *
     */
    async load(){
         return to(Http( window.API_PREFIX + "/dashboard"))
    },
    /**
     *
     * @param chart
     * @param params
     * @returns {Promise<[(Error | null), (AxiosResponse<any> | undefined)]>}
     */
    async loadChart(chart, params){
        return to(Http( window.API_PREFIX + "/chart/" + chart, params))
    }
}
