import {enum_to_hash} from "@/helpers"

export default {
    "TAX_ID": 1,
    "PASSPORT": 2
}

export const options = [
    {id: 1, text: 'TAX_ID', label: 'TAX_ID'},
    {id: 2, text: 'PASSPORT', label: 'PASSPORT'}
]

export const hash = enum_to_hash(options)