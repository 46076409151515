<template>
	<div :class="[{
    	'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start lg:grid-cols-4' : !inline,
    	'w-full flex flex-col' : inline,
    	'border-t border-gray-200 pt-5' : divider,
    	'mt-6 sm:mt-5' : !noMarginTop && !inline }
    	]">
		<label class="text-sm font-medium leading-5 sm:mt-px text-gray-900 flex items-center relative" :class="[labelClass, { 'sm:pt-2' : !text }]">
			<template v-if="empty">
				<div></div>
			</template>
			<template v-else>
				<span v-tooltip="tooltip">{{ label }}</span>
			</template>
			<template v-if="tooltip">
				<i class="fa fa-circle text-[5px] absolute -left-3 text-gray-300"></i>
			</template>
			<template v-if="locale">
				<p class="ml-2 flex items-center">
					[
					<span class="pl-2">{{ locale.alpha2 ? locale.alpha2.toUpperCase() : locale.toUpperCase() }}</span>
					<img class="w-5 h-4 mx-2" :src="`/img/flags/4x3/${locale.alpha2 ? locale.alpha2.toLowerCase() : locale.toLowerCase()}.svg`" alt="">
					]
				</p>
			</template>
			<template v-if="required">
				<i class="fa fa-asterisk text-[8px] -mt-2 ml-1.5 text-red-300"></i>
			</template>
		</label>
		<div class="mt-1 sm:mt-0 sm:col-span-2 lg:col-span-3" :class="[containerClass, {
        	'pt-1' : inline,
        	'flex flex-wrap sm:flex-no-wrap items-center': this.$slots.default && this.$slots.default.length > 1
        }]">
			<template v-if="empty">
				<div class="h-8 mt-px"></div>
			</template>
			<template v-else>
				<slot/>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	name: "FormLine",
	props: {
		label: String,
		locale: [String, Object],
		tooltip: String,
		required: Boolean,
		inline: Boolean,
		empty: Boolean,
		labelClass: String,
		containerClass: String,
		noMarginTop: Boolean,
		divider: Boolean,
		text: Boolean
	},
	computed: {
		hasLabel(){
			return this.label && !this.empty
		}
	}
}
</script>
