<!--<i18n src="@/i18n/components/ActionButton.json"></i18n>-->
<template>
	<div class="relative">
		<button
			v-on="$listeners"
			:class="$tw('inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500', buttonClass)"
			v-bind="$attrs">
			<template v-if="icon">
				<i :class="[icon, hasContent ? 'mr-2' : null]"></i>
			</template>
			<slot/>
		</button>
		<template v-if="loading">
			<Loading use-spinner/>
		</template>
	</div>
</template>

<script>
import Loading from "@/components/Loading.vue"

export default {
	name: "ButtonAppend",
	props: {
		icon: Boolean,
		loading: Boolean,
		buttonClass: String
	},
	components: {
		Loading
	},
	computed: {
		hasContent(){
			return this.$children.length > 0
		}
	}
}
</script>
