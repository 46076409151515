import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get("/backoffice/support-subjects", {params}))
    },
    async get(id) {
        return to(Http.get(`/backoffice/support-subjects/${id}`))
    },
    async getSelect(params) {
        return this.list({_type: "select", ...params})
    },
    async create(data) {
        return to(Http.post(`/backoffice/support-subjects`, data))
    },
    async update(id, data) {
        return to(Http.post(`/backoffice/support-subjects/${id}`, { '_method' : 'patch', ...data}))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.create(data)
    },
    async delete(id) {
        return to(Http.post(`/backoffice/support-subjects/${id}`, {'_method': 'delete'}))
    }
}

