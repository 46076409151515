import {make} from "vuex-pathify"
import APP from "../API/APP"
import Auth from "../API/Auth"

const state = {
    isLoading: false,
    config: {},
    user: {},
    profile: {},
    partner: {},
    provider: {},
    slideover: {},
    sidebarOpened: false,
    showMessenger: false,
    alert: {show: false},
    notification: {show: false},
    modal: {show: false}
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    async load({commit, dispatch}) {

        dispatch('loading')

        const [err, res] = await APP.load()

        dispatch('loaded')

        if (err) {
            console.error(err)
        }

        commit('SET_CONFIG', res.data)
    },
    async profile({commit, dispatch}) {
        dispatch('loading')

        const [err, res] = await APP.profile()

        dispatch('loaded')

        if (err) {
            console.error(err)
        }

        commit('SET_PROFILE', res.data.data);
    },
    async logout({commit, dispatch}) {
        dispatch('loading')

        const [err, res] = await Auth.logout()

        if (err) {
            console.error(err)
        }

        window.location = '/';
    },
    openSidebar({commit}) {
        commit('SET_SIDEBAR_OPENED', true)
    },
    closeSidebar({commit}) {
        commit('SET_SIDEBAR_OPENED', false)
    },
    async reload({commit, dispatch, state}) {
        await dispatch('profile')
    },

    async setup({commit, dispatch, state}) {
        await dispatch('profile')
    },

    async init({commit, dispatch, state}) {

        console.debug(`Store/App@init: Setup store.`)

        await dispatch('setup')
        await dispatch('Chat/init', null, {root: true})

        console.info(`Store/App@init: Starting listen socket. (App.Models.User.${state.profile.id})`)

        Echo.join("online")
        .here((p, n) => console.debug('online here', {p,n}))
        .joining((p, n) => console.debug('joining', {p,n}))
        .leaving((p, n) => console.debug('leaving', {p,n}))
        .notification(function (payload) {
            console.debug('online notification', payload)
            // console.debug('online notification',{ payload })
            // dispatch('Notifications/push', payload.data, {root: true})
        })

        Echo.private(`App.Models.User.${state.profile.id}`)
            .notification(function (payload) {
                dispatch('Notifications/push', payload.data, {root: true})
            })
            /**
             * Process
             */
            // .listen("\\App\\Domain\\BackgroundTask\\Events\\BackgroundTaskCreated", (e) => {
            //     dispatch('BackgroundTasks/load', {background: true}, {root: true})
            // })
            // .listen('\\App\\Domain\\BackgroundTask\\Events\\BackgroundTaskCompleted', (e) => {
            //     if (e.file_path) {
            //         window.open("/background_tasks_download/" + e.id)
            //     }
            //     dispatch('BackgroundTasks/load', {background: true}, {root: true})
            // })
            // .listen("\\App\\Domain\\BackgroundTask\\Events\\BackgroundTaskProgress", (e) => {
            //     dispatch('BackgroundTasks/setProgress', e, {root: true})
            // })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
