<template>
	<div :class="{'relative' : loading}">
		<component
			:is="tag"
			class="group relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 focus:outline-none font-medium rounded-sm text-white transition duration-150 ease-in-out cursor-pointer focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-turquoise"
			:class="[
                buttonClass,
                {
                    'shadow' : !noShadow,
                    'pl-8' : icon && iconPosition == 'left',
                    'pr-8' : icon && iconPosition == 'right',
                    'border-gray-300' : ! color && !noBorder,
                    'text-gray-700' : ! color,
                    [`bg-${color}-500 hover:bg-${color}-400 active:bg-${color}-600`] : (disabled === false || !disabled),
                    [`bg-gray-300 text-gray-400`] : (disabled === true || disabled == 'disabled')}]"
			:disabled="disabled"
			v-on="$listeners">
            <span v-if="icon && iconPosition == 'left'" class="absolute left-0 inset-y-0 flex items-center pl-3">
                <i class="transition ease-in-out duration-150"
                   :class="[icon, {[`text-${iconColor} group-hover:text-${iconHoverColor}`] : (disabled === false || !disabled)} ]"></i>
            </span>
			<slot/>
			<span v-if="icon && iconPosition == 'right'" class="absolute right-0 inset-y-0 flex items-center pr-3">
                <i class="transition ease-in-out duration-150"
                   :class="[icon, { [`text-${iconColor} group-hover:text-${iconHoverColor}`] : (disabled === false || !disabled) }]"></i>
            </span>
		</component>
		<Loading v-if="loading" use-spinner spinner-color="blue-900"/>
	</div>
</template>
<script>
import Loading from "@/components/Loading"
export default {
	name: "DefaultButton",
	props: {
		color: String,
		tag: {
			type: String,
			default: "button"
		},
		icon: String, // fa fa-lock
		disabled: [String,Boolean],
		buttonClass: [String, Array],
		iconPosition: {
			type: String,
			default: 'left'
		},
		iconColor: {
			type: String,
			default(){
				if(this.color && this.color.indexOf('-') != -1) {
					const intensity = this.iconColor.replace(/\D|\-/g, '')
					return this.iconColor.replace(intensity, 500)
				}
				return `${this.color}-400`
			}
		},
		iconHoverColor: {
			type: String,
			default(){
				if(this.iconColor.indexOf('-') != -1) {
					const intensity = this.iconColor.replace(/\D|\-/g, '')
					return this.iconColor.replace(intensity, intensity - 100)
				}
				return this.iconColor.indexOf('-400') != -1 ? `${this.iconColor}-400` : this.iconColor
			}
		},
		focusColor: {
			type: String,
			default(){
				return this.color
			}
		},
		noShadow: {
			type: Boolean,
			default: false
		},
		noBorder: Boolean,
		loading: Boolean
	},
	components: { Loading }
}
</script>
