import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(filters) {
        return to(Http(window.API_PREFIX + "/users", {params: filters}))
    },
    async get(id) {
        return to(Http(window.API_PREFIX + `/users/${id}`))
    },
    async create(data) {
        return to(Http.post(window.API_PREFIX + `/users`, data))
    },
    async update(id, data) {
        return to(Http.post(window.API_PREFIX + `/users/${id}`, {'_method': 'patch', ...data}))
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/users/${id}`, {'_method': 'delete'}))
    }
}