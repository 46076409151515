<i18n src="@/i18n/backoffice/account/notifications_form.json"></i18n>
<template>
    <div v-if="isActive">
        <!-- Dados pessoais !-->
        <Card :title="$t('notifications.notification_list')" class="mt-4">
        </Card>
    </div>
</template>

<script>
import Card from "@/components/Card"

export default {
    name: "NotificationsForm",
    components: {
        Card
    },
    data() {
        return {
            isActive: false
        }
    },
}
</script>