<i18n src="@/i18n/domain/support_tickets/SupportTicketAddress.json"></i18n>
<template>
	<div ref="target_input" class="target_input relative text-xs sm:leading-6 -mb-2" @click="handleFocusWrapper">
		<div class="relative">
			<!-- List !-->
			<ul class="flex flex-wrap items-center pb-2">
				<template v-for="(item, index) in address">
					<!-- Item !-->
					<li class="mb-2 relative flex mr-2">
						<div :ref="`tag-${index}`"
							 role="button"
							 tabindex="0"
							 class="inline-flex items-center px-3.5 py-2 rounded font-medium focus:ring-2 focus:ring-turquoise focus:outline-none"
							 :class="tagClass(item.address_type, index)"
							 @click.stop="handleClickTag(item, index)"
							 @focus.stop="handleFocusTag(item, index)">
							<!-- Tag Icon !-->
							<i class="mr-2" :class="searchTypeIcon(item.address_type)"></i>
							<!-- Tag Label !-->
							{{ localized(item) }}
							<!-- Option Selected !-->
							<template v-if="item.option">
								<i class="fal fa-chevron-right mx-2"></i>
								<div>
									<i class="fa mx-2" :class="searchTypeIcon(item.option.address_type)"></i>
									<span>{{ item.option ? item.option.label : '' }}</span>
								</div>
							</template>
							<!-- Show Options indicator !-->
							<template v-if="hasOptions(item.address_type)">
								<i class="fa ml-2 px-1" :class="{ 'fa-caret-down' : !showOptionsResult[item.id] , 'fa-caret-up' : showOptionsResult[item.id] }"></i>
							</template>
							<!-- Remove Tag !-->
							<button type="button" class="ml-2 px-1" tabindex="-1" @click.stop="handleDeleteItem(item, index)">
								<i class="fa fa-times"></i>
							</button>
						</div>
						<!-- Item Options !-->
						<div v-show="hasOptions(item.address_type) && showOptionsResult[item.id]" class="absolute left-0 right-0 top-1/2 mt-1">
							<div class="mt-4 bg-white py-2 min-w-full absolute left-0 z-10 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-blue-500">
								<!-- List !-->
								<template v-if="optionList[item.id]">
									<ul class="p-2 -mb-2">
										<!-- All !-->
										<li :ref="`option-${index}-any`"
											tabindex="0"
											class="cursor-pointer flex-grow mb-1 flex items-center px-3.5 py-1 rounded font-medium select-none hover:bg-turquoise hover:text-white focus:ring-2 focus:ring-turquoise focus:outline-none whitespace-nowrap"
											@click.stop="handleSelectItemOptionAny(item, index)">
											<i class="fal fa-user mr-2"></i>
											<span>Todos</span>
										</li>
										<!-- / All !-->
										<!-- Option !-->
										<template v-for="(option, optionIndex) in optionList[item.id]">
											<li :ref="`option-${index}-${optionIndex}`"
												tabindex="0"
												class="cursor-pointer flex-grow mb-1 flex items-center px-3.5 py-1 rounded font-medium select-none hover:bg-turquoise hover:text-white focus:ring-2 focus:ring-turquoise focus:outline-none whitespace-nowrap"
												@click.stop="handleSelectItemOption(item, option, optionIndex, index)">
												<i class="fa mr-2" :class="searchTypeIcon(option.address_type)"></i>
												<span>{{ localized(option) }}</span>
											</li>
										</template>
										<!-- Option !-->
									</ul>
								</template>
								<!-- / List !-->
								<!-- Loading !-->
								<template v-else>
									<div class="flex items-center justify-center p-4">
										<Loading use-spinner spinner-color="text-blue-500"/>
									</div>
								</template>
							</div>
						</div>
						<!-- / Item Options !-->
					</li>
					<!-- / Item !-->
				</template>
				<!-- Search Input !-->
				<li class="mb-2 flex-grow rounded-md shadow-sm relative">
					<input ref="input"
						   type="text"
						   :placeholder="$t('support_ticket_address.destination_address')"
						   class="block w-full min-w-0 rounded-md sm:text-sm"
						   v-model="text"
						   @focus="handleInputFocus"
						   @blur="handleInputBlur"
						   @keydown="handleKeydown"
						   @input="handleInput"/>
				</li>
			</ul>
			<!-- /List !-->
		</div>
		<!-- Search List !-->
		<template v-if="canShowResult">
			<div ref="drop" class="mt-2 bg-white py-2 min-w-full absolute left-0 z-10 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-blue-500" :class="dropClass"
				 @mouseover="handleMouseOverSearch">
				<!-- List !-->
				<template v-if="!isSearchLoading">
					<!-- Search result !-->
					<template v-if="hasItems">
						<!-- List !-->
						<ul class="max-h-32 lg:max-h-64 text-base overflow-auto focus:outline-none sm:text-sm divide-y divide-gray-200"
							tabindex="-1"
							role="listbox">
							<template v-for="(item, index) in searchList">
								<!-- Item !-->
								<li :key="`search-${index}`"
									:ref="`search-${index}`"
									role="option"
									tabindex="0"
									class="cursor-pointer text-gray-900 select-none relative py-2.5 pl-3 pr-9 hover:bg-turquoise hover:text-white focus:outline-none focus:bg-turquoise focus:text-white hover:font-semibold focus:font-semibold group"
									:class="{ 'bg-turquoise text-white text-white font-semibold': currentSearchIndex === index }"
									@mousedown="handleSelectItem(item, index)"
									@keypress.enter="handleSelectItem(item, index)">
									<slot name="item" :data="{ item }">
										<!-- Icon !-->
										<i class="fal mr-2 group-hover:text-white"
										   :class="[searchTypeIcon(item.address_type),{ 'text-gray-400' : currentSearchIndex !== index, 'text-white' : currentSearchIndex === index}]"></i>
										<!-- Label !-->
										<span>{{ localized(item) }}</span>
									</slot>
								</li>
							</template>
						</ul>
						<!-- / List !-->
					</template>
					<template v-else-if="text">
						<p class="p-3 text-center text-xs">Nenhum resultado para: <span>"{{ text }}"</span></p>
					</template>
				</template>
				<!-- / List !-->
				<!-- Loading !-->
				<div v-else class="relative py-6">
					<Loading use-spinner spinner-color="text-blue-500"/>
				</div>
				<!-- / Loading !-->
			</div>
		</template>
		<template v-if="isLoading">
			<Loading use-spinner spinner-color="text-blue-500"/>
		</template>
	</div>
</template>

<script>

import Loading from "@/components/Loading.vue"

import SupportTickets from "@/API/backoffice/SupportTickets.js"

export default {
	name: "SupportTicketAddress",
	components: {Loading},
	model: {
		prop: 'address',
		event: 'update:address'
	},
	props: {
		address: {
			type: [Array],
		},
		dropClass: String,
	},
	data() {
		return {
			searchEventThrottle: null,
			text: '',
			eventBind: false,
			showResult: false,
			showOptionsResult: {},
			isLoading: false,
			isLoadingOptions: {},
			isSearchLoading: false,
			optionList: {},
			searchList: [],
			currentTagIndex: null,
			currentSearchIndex: null,
		}
	},
	computed: {
		/**
		 * Calculate if has items
		 * @returns {boolean}
		 */
		hasItems() {
			return this.searchList?.length > 0
		},
		/**
		 * Calculate if can show result
		 * @returns {boolean}
		 */
		canShowResult() {
			return this.showResult
		},
	},
	methods: {
		/**
		 * Handle select item
		 */
		handleSelectItem(item, index) {

			if (!item) return false

			const data = this.address?.length ? copy_obj(this.address) : []

			if (this.hasOptions(item.address_type)) {
				// Force remove selected option
				item.option = null
			}

			data.push(item)

			this.$emit('update:address', data)

			this.showResult = false
			this.text = ''
			this.currentSearchIndex = null
			this.$nextTick(() => setTimeout(() => this.$refs.input.focus()))

			return true
		},
		/**
		 * Handle select option
		 * @param item { Object }
		 * @param option { Object }
		 * @param optionIndex { Number}
		 * @param itemIndex { Number }
		 * @returns {boolean}
		 */
		handleSelectItemOption(item, option, optionIndex, itemIndex) {

			const data = this.address?.length ? copy_obj(this.address) : []
			item.option = option
			data[itemIndex] = item

			this.$emit('update:address', data)

			this.showOptionsResult[item.id] = false

			return true
		},
		/**
		 * Handle select option any
		 * @param item { Object }
		 * @param itemIndex { Number }
		 * @returns {boolean}
		 */
		handleSelectItemOptionAny(item, itemIndex) {

			const data = this.address?.length ? copy_obj(this.address) : []
			item.option = null
			data[itemIndex] = item

			this.$emit('update:address', data)

			this.showOptionsResult[item.id] = false

			return true
		},
		/**
		 * Handle keydown
		 * @param evt
		 */
		handleKeydown(evt) {
			if (evt.key === 'Enter') {
				// this.address.push({id: null, label: this.text, address_type: 'user'})
				// this.text = ''
				// this.$nextTick(() => this.$refs.input.focus())
			}
		},
		/**
		 * Handle input
		 * @param evt
		 */
		handleInput(evt) {
			if (this.searchEventThrottle) {
				clearTimeout(this.searchEventThrottle)
			}

			this.isSearchLoading = true

			this.searchEventThrottle = setTimeout(this.search, 500)

			this.showResult = true
		},
		handleInputBlur() {
			this.text = ""
		},
		/**
		 * Handle delete tag
		 * @param item
		 * @param index
		 */
		handleDeleteItem(item, index) {
			this.address.splice(index, 1)
			if (!this.address?.length) {
				this.$nextTick(() => this.$refs.input.focus())
			}
		},
		/**
		 * Handle focus on wrapper
		 */
		handleFocusWrapper() {
			this.$refs.input.focus()
			this.bindDocumentEvents()
		},
		/**
		 * Handle clear
		 */
		clear() {
			this.$emit('update:address', [])
		},
		/**
		 * Handle click tag
		 * @param item { Object }
		 * @param index { Number }
		 */
		handleClickTag(item, index) {
			this.currentTagIndex = index

			if (this.hasOptions(item.address_type)) {
				this.$set(this.showOptionsResult, item.id, !this.showOptionsResult[item.id])
			}

			if (this.showOptionsResult[item.id]) {
				this.loadItemOptions(item, index)
			}

			this.bindDocumentEvents()
		},
		/**
		 * Handle focus tag
		 * @param item { Object }
		 * @param index { Number }
		 */
		handleFocusTag(item, index) {
			this.currentTagIndex = index
			this.bindDocumentEvents()
		},
		/**
		 * Handle input focus
		 */
		handleInputFocus() {
			this.currentTagIndex = null
			this.bindDocumentEvents()
		},
		handleMouseOverSearch() {
			this.currentSearchIndex = null
		},
		/**
		 * Handle nativation input
		 * @param evt
		 */
		handleNativation(evt) {

			// Limit listen events
			const actionKeys = [
				'ArrowUp',
				'ArrowDown',
				'ArrowLeft',
				'ArrowRight',
				'Backspace',
				'Delete',
				'Esc',
				'Enter'
			]

			if (!actionKeys.includes(evt.key)) return

			// Clear positions
			if (evt.key === 'Esc') {
				this.currentSearchIndex = null
				this.currentTagIndex = null
				this.$refs.input.focus()
				return
			}

			// Fix input navigation
			if (evt.target.tagName === 'INPUT') {
				if (evt.key === 'ArrowLeft' && evt.target.selectionStart !== 0) {
					return
				}

				if (evt.key === 'ArrowRight' || evt.key === 'Backspace' || evt.key === 'Delete') {
					return
				}
			}

			// Search
			this.searchNavigation(evt)

			// Tags
			this.tagsNatigation(evt)
		},
		/**
		 * Handle search navigation
		 * @param evt
		 */
		searchNavigation(evt) {
			if (evt.key === 'Enter' && this.currentSearchIndex != null && this.currentSearchIndex >= 0) {
				this.handleSelectItem(this.searchList[this.currentSearchIndex], this.currentSearchIndex)
				return
			}

			if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {

				if (this.currentSearchIndex === null) {
					this.currentSearchIndex = 0
					return
				}

				if (evt.key === 'ArrowUp') {
					this.currentSearchIndex--
				}

				if (evt.key === 'ArrowDown') {
					this.currentSearchIndex++
				}

				// Limit start index
				if (this.currentSearchIndex < 0) this.currentSearchIndex = 0

				// Limit last index
				if (this.currentSearchIndex > this.searchList.length - 1) this.currentSearchIndex = this.searchList.length - 1
			}
		},
		/**
		 * Handle tags navigation
		 * @param evt
		 */
		tagsNatigation(evt) {

			if ((evt.key === 'Backspace' || evt.key === 'Delete') && this.currentTagIndex !== null) {
				this.handleDeleteItem(this.address[this.currentTagIndex], this.currentTagIndex)
				if (this.currentTagIndex > this.address.length - 1) this.currentTagIndex = this.address.length - 1
				return
			}

			if (evt.key === 'ArrowLeft') {
				if (this.currentTagIndex === null) {
					this.currentTagIndex = this.address.length - 1
				} else {
					this.currentTagIndex--
				}

				if (this.currentTagIndex < 0) {
					this.currentTagIndex = 0
				}
			}
			if (evt.key === 'ArrowRight') {
				if (this.currentTagIndex === null) {
					this.currentTagIndex = 0
				} else {

					this.currentTagIndex++

					if (this.currentTagIndex >= this.address.length) {
						return this.$refs.input.focus()
					}
				}
			}

			if (this.$refs[`tag-${this.currentTagIndex}`]) this.$refs[`tag-${this.currentTagIndex}`][0].focus()
		},
		/**
		 * Action search
		 * @returns {Promise<boolean>}
		 */
		async search() {

			this.isSearchLoading = true
			const [err, res] = await SupportTickets.searchAddress({
				search: this.text
			})
			this.isSearchLoading = false

			if (err) {
				// TODO: Handle error
				return false
			}

			this.searchList = res.data?.data

			// this.searchList = this.mockSearchList.filter(item => item.label.toLowerCase().includes(this.text.toLowerCase())).filter(item => this.address?.findIndex(i => i.id === item.id) === -1)

			this.searchEventThrottle = null

			if (this.searchList.length > 0) {
				this.currentSearchIndex = 0
			}
		},
		async loadItemOptions(item, index) {

			const [err, res] = await SupportTickets.addressOptions({
				id: item.id,
				address_type: item.address_type
			})

			if (err) {
				// TODO: handler error
				return false
			}

			this.$set(this.optionList, item.id, res.data?.data ?? [])

			return true

		},
		/**
		 * Format label for any item
		 * @param item
		 * @returns {string}
		 */
		localized(item) {
			const label = []

			if (item.code) {
				label.push(`${item.code} - `)
			}

			if (typeof item.label === 'string') {
				label.push(item.label)
			} else {
				label.push(this.$localize(item.label))
			}

			return label.join('')
		},
		/**
		 * Calculate item has options
		 * @param address_type
		 * @returns {*}
		 */
		hasOptions(address_type) {
			return address_type !== 'user'
		},
		/**
		 * Calculate search item icon
		 * @param address_type
		 * @returns {*}
		 */
		searchTypeIcon(address_type) {
			return {
				'user': 'fal fa-user',
				'company': 'fa fa-building',
				'partner': 'fa fa-building',
				'provider': 'fal fa-truck',
				'department': 'fal fa-users',
			}[address_type]
		},
		/**
		 * Calculate search tag icon
		 * @param address_type
		 * @returns {*}
		 */
		tagClass(address_type) {
			return {
				'user': 'bg-blue-100 text-gray-800',
				'company': 'bg-gray-100 text-gray-800',
				'partner': 'bg-gray-100 text-gray-800',
				'provider': 'bg-gray-100 text-gray-800',
				'department': 'bg-yellow-100 text-gray-800',
			}[address_type]
		},
		/**
		 * Handle click outside
		 * @param evt
		 */
		awayEvent(evt) {
			const closest = evt.target.closest('.target_input')
			if (closest && closest === this.$refs.target_input) {
				return false
			}

			this.showResult = false
			this.showOptionsResult = []
			this.unbindDocumentEvents()
		},
		/**
		 * Bind document events
		 */
		bindDocumentEvents() {
			if (this.eventBind) return
			this.eventBind = true
			document.addEventListener('keydown', this.handleNativation)
			document.addEventListener('click', this.awayEvent)
		},
		/**
		 * Unbind document events
		 */
		unbindDocumentEvents() {
			this.eventBind = false
			document.removeEventListener('keydown', this.handleNativation)
			document.removeEventListener('click', this.awayEvent)
		},
		/**
		 * External
		 */
		focus() {
			this.$refs.input.focus()
		},
		/**
		 * External
		 * Add item to address list
		 * @param address_type
		 * @param id
		 * @param push
		 */
		async addItem(address_type, id, push = true) {
			this.isLoading = true
			const [err, res] = await SupportTickets.searchAddress({
				address_type, id
			})
			this.isLoading = false

			if (err) return false

			if (res.data?.data?.length === 0) return false

			// Push or Unshift
			this.$emit('update:address', push ? [...this.address, res.data?.data] : [res.data?.data, ...this.address])

			return true
		},
		/**
		 * External
		 * Remove item from address list
		 * @param address_type
		 * @param id
		 * @returns {Promise<boolean>}
		 */
		async removeItem(address_type, id) {
			this.$emit('update:address', this.address.filter(item => item.address_type !== address_type && item.id != id))

			return true
		},
	}
}
</script>