import SupportTickets from "@/API/backoffice/SupportTickets.js"

import LoadingMixin from "@/mixins/loading.js"
import SystemMixin from "@/mixins/system.js"
import HandleRequestErrorsMixin from "@/mixins/handle_request_errors.js"

import {options as StatusOptions} from "@/enums/status.js"

// TODO: Remove vuex-pathify dependency
import {sync} from "vuex-pathify"

export default {
    mixins: [
        LoadingMixin,
        SystemMixin,
        HandleRequestErrorsMixin
    ],
    props: {
        filters: Object
    },
    data() {
        return {
            filter: {
                tipo: null,
                status: null,
                search: null,
                page: 1,
            },
        }
    },
    computed: {
        ...sync('SupportTickets', ['page']),
        filterSelects() {
            return [
                {
                    label: 'Todos status',
                    options: StatusOptions,
                    filter: 'status'
                }
            ]
        },
        actions() {
            return {
                options: [
                    {label: 'Ativar', id: 'ativar'},
                    {label: 'Desativar', id: 'desativar'},
                    {label: 'Excluir', id: 'excluir'},
                    {label: 'Consulta financeiro', id: 'consulta_financeiro'},
                ]
            }
        },
    },
    methods: {
        /**
         * Action load
         * @param background
         * @returns {Promise<boolean>}
         */
        async load(background = false) {

            const filter = copy_obj(this.filter)

            console.debug('SupportTickets/Index@load: Loading data.', {filter, background})

            background ? this.bgLoading() : this.loading()

            const [err, res] = await SupportTickets.list(filter)

            this.loaded()

            if (err) {
                console.error('SupportTickets/Index@load: Error on loading data.', {err})
                return this.handleRequestError(err)
            }

            this.page = res.data

            console.debug('SupportTickets/Index@load: Data loaded.', {data: res.data})

            return true
        },
        /**
         * DataTable
         * @returns {Object}
         */
        table() {
            return {
                row: {
                    'class': "hover:bg-gray-50",
                    'checkedClass': "bg-gray-100",
                },
                cols: {
                    '#': null,
                    'id': {
                        'th': 'ID',
                    },
                    'subject': {
                        'th': 'Assunto',
                        'thClass': 'w-full',
                        'tdClass': 'group-hover:text-blue-600'
                    },
                    status: {
                        th: this.$t('Status'),
                        thClass: 'text-center'
                    },
                    'last_message_at': {
                        'th': 'Última menssagem',
                        'thClass': 'hidden md:table-cell',
                        'tdClass': 'hidden md:table-cell',
                        formater: 'date:DD/MM/YYYY',
                    },
                    'created_at': {
                        'th': 'Criado em',
                        'thClass': 'hidden md:table-cell',
                        'tdClass': 'hidden md:table-cell',
                        formater: 'date:DD/MM/YYYY',
                    },
                    '@': null
                }
            }
        },
        /**
         * INIT
         */
        async init() {

            if(this.filter.status === null) {
                this.filter.status = -1
            }

            return this.load()
        }
    }
}
