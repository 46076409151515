<i18n src="@/i18n/components/CreateNewButton.json"></i18n>
<template>
	<router-link tag="button" :to="to"
				 class="items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors">
		<i class="mr-2" :class="icon"></i>
		<slot>{{ $t('create_new_button.label') }}</slot>
	</router-link>
</template>
<script>
export default {
	name: "CreateButton",
	props: {
		to: [Object, String],
		icon: {
			type: String,
			default: "fa fa-plus"
		}
	}
}
</script>