<i18n src="@/i18n/components/SendButton.json"></i18n>
<template>
	<button
		class="inline-flex items-center py-2 pl-3 pr-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
		v-on="$listeners">
		<span class="ml-2.5 text-sm font-medium">
            <slot>{{ $t('send_button.label') }}</slot>
        </span>
		<i class="fa fa-paper-plane ml-2"></i>
	</button>
</template>

<script>
export default {
	name: "SendButton"
}
</script>