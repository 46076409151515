import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get(window.API_PREFIX + "/clients", {params}))
    },
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/clients/${id}`))
    },
    async getSelect(params = {}) {
        return this.list({_type: "select", ...params})
    },
    async create(data) {
        return to(Http.post(window.API_PREFIX + `/clients`, data))
    },
    async update(id, data) {
        data.append('_method', 'patch')
        return to(Http.post(window.API_PREFIX + `/clients/${id}`, data))
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/clients/${id}`, {'_method': 'delete'}))
    }
}

