import Http from "@/config/axios.js"
import { to } from "await-to-js";

export default {
    async login(credentials){
        return to(Http.post(window.API_PREFIX + "/login", credentials));
    },
    async register(data){
        return to(Http.post(window.API_PREFIX + "/register", data));
    },
    async registerSocial(data){
        return to(Http.post(window.API_PREFIX + "/register-social", data));
    },
    async passwordRecovery(data){
        return to(Http.post(window.API_PREFIX + "/password/email", data));
    },
    async passwordReset(data){
        return to(Http.post(window.API_PREFIX + "/password/reset", data));
    },
    async logout(){
        return to(Http.post(window.API_PREFIX + "/logout"));
    },
}

