<template>
	<FormSearchableInput2
		ref="searchable"
		:selected="user"
		:list="searchList"
		:is-search-loading="isLoading"
		v-bind="$attrs"
		:search="handleSearch"
		@update:selected="handleSelect"
	/>
</template>
<script>
import Users from "@/API/Users.js"
import LoadingMixins from "@/mixins/loading.js"
import FormSearchableInput2 from "@/components/FormSearchableInput2.vue"

export default {
	name: "UserSelect",
	mixins: [LoadingMixins],
	model: {
		prop: 'user',
		event: 'update:user'
	},
	props: {
		user: [Object, Number, String],
		disabled: Boolean,
		validation: Object,
	},
	components: {
		FormSearchableInput2
	},
	data() {
		return {
			searchList: []
		}
	},
	methods: {
		/**
		 *
		 * @param search
		 */
		async handleSearch(search) {
			const [err, res] = await Users.getSelect({search})

			if (err) {
				// TODO...
				return []
			}

			return res.data.data
		},
		/**
		 *
		 * @param user
		 * @returns Object
		 */
		handleSelect(user) {
			this.$emit('update:user', user)
		},
		focus(){
			this.$refs.searchable.focus()
		},
		clear(){
			this.$refs.searchable.clear()
		}
	}
}
</script>