<template>
  <Modal v-bind="modal" v-model="modal.show"/>
</template>

<script>

import Modal from "@/components/Modal"
import {sync} from "vuex-pathify"

export default {
  name: "AppModal",
  components: {Modal},
  // data(){
  //     return {
  //         modal: {
  //             show: false,
  //             type: 'success',
  //             iconPosition: 'left',
  //             action: "confirm",
  //             title: "Cadastro efetuado",
  //             message: "Aguarde enquanto sua conta seja verificada.",
  //             withButtons: ['Confirmar', 'Cancelar'],
  //             onAction(){ console.log('Action') }
  //         }
  //     }
  // },
  computed: {
    ...sync('APP', ['modal'])
  }
}
</script>
