<template>
    <div class="mt-4">
        <slot />
    </div>
</template>

<script>
export default {
    name: "TabContainers",
    props: ['value'],
    watch: {
        value(){
            this.$children.forEach(tab => tab.isActive = false)
            this.$children[this.value].isActive = true
        }
    },
    mounted() {
        this.$children[this.value].isActive = true
    }

}
</script>
