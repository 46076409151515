<i18n src="@/i18n/enums/support_ticket_status.json"></i18n>
<template>
    <span :class="badgeClass" class="px-2.5 py-0.5 inline-flex items-center rounded-full font-medium leading-4">
        {{ badgeLabel }}
    </span>
</template>

<script>
import {hash as SupportTicketStatus} from "@/enums/support_ticket_status.js"

export default {
	name: "SupportTicketBadge",
	props: {
		supportTicket: Object
	},
	computed: {
		status() {
			return translateEnum(this, SupportTicketStatus, 'support_ticket_status.')
		},
		badgeClass() {
			return this.status[this.supportTicket.support_ticket_status]?.class
		},
		badgeLabel() {
			return this.$t(this.status[this.supportTicket.support_ticket_status]?.text)
		}
	}
}
</script>