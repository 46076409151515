<i18n src="@/i18n/components/Modal.json"></i18n>
<template>
	<transition enter-class="ease-out duration-200"
				enter-active-class="opacity-0"
				enter-to-class="opacity-100"
				leave-class="ease-in duration-200"
				leave-active-class="opacity-100"
				leave-to-class="opacity-0" @after-enter="show = true">

		<!-- Container !-->
		<div v-if="value" class="fixed transition-opacity bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-[101]">

			<!-- Overlay !-->
			<div class="fixed inset-0">
				<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
			</div>

			<transition
				enter-class="ease-out duration-300"
				enter-active-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				enter-to-class="opacity-100 translate-y-0 sm:scale-100"
				leave-class="ease-in duration-800"
				leave-active-class="opacity-100 translate-y-0 sm:scale-100"
				leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				@after-leave="handleClose">

				<!-- Card !-->
				<div v-if="show" class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:w-full sm:p-6" :class="currentSize">

					<!-- Icon Center !-->
					<template v-if="iconPosition == 'center'">
						<div>
							<div v-if="currentIcon" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full" :class="cicleClass">
								<i :class="currentIcon"></i>
							</div>
							<div class="mt-3 text-center sm:mt-5">
								<h3 class="text-lg leading-6 font-medium text-gray-900">
									{{ title }}
								</h3>
								<div class="mt-2">
									<p class="text-sm leading-5 text-gray-500" v-html="nl2br(message)"></p>
								</div>
							</div>
						</div>
					</template>
					<!-- /Icon Center !-->

					<template v-if="withText">
						<div class="mt-2">
							<FormInput :placeholder="withText" v-model="text"/>
						</div>
					</template>

					<template v-if="withTextarea">
						<div class="mt-2">
							<FormTextArea :placeholder="withTextarea" v-model="text"/>
						</div>
					</template>

					<!-- Icon Left !-->
					<template v-else-if="iconPosition == 'left'">
						<div class="sm:flex sm:items-start">
							<div v-if="currentIcon" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10" :class="cicleClass">
								<i :class="currentIcon"></i>
							</div>
							<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
									{{ title }}
								</h3>
								<div class="mt-2">
									<p class="text-sm leading-5 text-gray-500" v-html="nl2br(message)"></p>
								</div>
							</div>
						</div>
					</template>
					<!-- /Icon Left !-->

					<!-- /Confirm Buttons !-->
					<template v-if="action == 'confirm'">
						<div
							:class="{ 'mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex' : buttonsPosition == 'left', 'mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense' : buttonsPosition == 'full'}">
                            <span class="flex w-full rounded-md shadow-sm sm:w-auto">
                                <button type="button"
										class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-turquoise text-base leading-6 font-medium text-white shadow-sm hover:bg-turquoise focus:outline-none focus:border-turquoise transition ease-in-out duration-150 sm:text-sm sm:leading-5"
										@click="handleAction('confirm')">
                                  {{ firstButton }}
                                </button>
                              </span>
							<span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
                                <button type="button"
										class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-gray-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
										@click="handleAction('cancel')">
                                  {{ secondButton }}
                                </button>
                            </span>
						</div>
					</template>
					<!-- /Confirm Buttons !-->
					<!-- Simple Button !-->
					<template v-else-if="button">
						<div class="mt-5 sm:mt-6">
                            <span class="flex w-full rounded-md shadow-sm">
                                <button type="button"
										class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
										@click="handleAction('default')">
                                  {{ button }}
                                </button>
                            </span>
						</div>
					</template>
					<!-- Simple Button !-->
				</div>
				<!-- /Card !-->
			</transition>
		</div>
		<!-- /Container !-->
	</transition>
</template>

<script>

import FormInput from "./FormInput"
import FormTextArea from "./FormTextArea"

export default {
	name: "Modal",
	props: {
		type: {
			type: String,
			default: "default"
		},
		size: {
			type: String,
			default: "md"
		},
		action: String,
		iconPosition: {
			type: String,
			default: "center"
		},
		value: Boolean,
		icon: {
			type: String // fa fa-check
		},
		title: String,
		withTextarea: [Boolean, String],
		withText: [Boolean, String],
		message: String,
		buttons: Array,
		button: {
			type: String,
			default: "Fechar"
		},
		buttonsPosition: {
			type: String,
			default: 'full'
		},
		onAction: Function
	},
	components: {
		FormInput,
		FormTextArea
	},
	computed: {
		firstButton() {
			return this.buttons && this.buttons[0] ? this.buttons[0] : this.$t('modal.confirm')
		},
		secondButton() {
			return this.buttons && this.buttons[1] ? this.buttons[1] : this.$t('modal.cancel')
		},
		currentSize() {

			const sizes = {
				"sm": "sm:max-w-sm",
				"md": "sm:max-w-md",
				"lg": "sm:max-w-lg"
			}

			return sizes[this.size] ?? this.size
		},
		currentIcon() {
			if (this.icon) return this.icon

			const types = {
				'success': "fa fa-check text-green-800",
				'error': "fa fa-times text-red-800",
				'warning': "fa fa-exclamation-triangle text-yellow-400",
				'info': "fa fa-info text-gray-800",
			}

			return types[this.type] ?? false
		},
		cicleClass() {
			const types = {
				'success': "bg-green-100",
				'error': "bg-red-100",
				'warning': "bg-yellow-100",
				'info': "bg-blue-100",
			}

			return types[this.type] ?? 'bg-gray-100'
		}
	},
	data() {
		return {
			show: false,
			text: ""
		}
	},
	methods: {
		handleAction(action) {
			if (this.onAction) {
				this.onAction(action, this.text)
			}

			this.show = false
			this.handleClose()
		},
		handleClose() {
			this.$emit('input', false)
		},
		nl2br(text) {
			return text.replace(/\n/g, "<br />")
		}
	}
}
</script>
