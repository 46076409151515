<i18n src="@/i18n/components/LabelActive.json"></i18n>
<template>
    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full items-center" :class="statusClass">
        {{ $t(status) }}
    </span>
</template>

<script>
export default {
    name: "LabelActive",
    props: {
        prop: {
            type: String,
            default: 'active'
        },
        prop_text: {
            type: String,
            default: 'active_text'
        },
        item: Object
    },
    computed: {
        status(){
           return `label_active.${this.item[this.prop]}`
        },
        statusClass(){
            return !!this.item[this.prop]
	            ? 'bg-green-100 text-green-800'
	            : 'bg-gray-100 text-gray-800'
        }
    }
}
</script>
