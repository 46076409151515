<template>
    <div>
        <!-- input wrap !-->
	    <div class="flex items-center">
		    <input :id="`radio-${label}-${name}`" :name="name" type="radio" class="form-radio h-4 w-4 text-turquoise transition duration-150 ease-in-out focus:ring-turquoise" :checked="radioValue == value" :value="radioValue" @input="$emit('input', $event.target.value)">
		    <label :for="`radio-${label}-${name}`" class="ml-3">
			    <span class="block text-sm leading-5 font-medium text-gray-700">{{ label }}</span>
		    </label>
	    </div>
	    <!-- /input wrap !-->
        <!-- ErrorBag !-->
        <ErrorBag v-if="validation" :placeholder="placeholder" :validation="validation" v-bind="error" />
        <!-- Info !-->
        <div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
            <slot name="info" />
        </div>
    </div>
</template>

<script>
    import Loading from "@/components/Loading"
    import ErrorBag from "@/components/ErrorBag"
    export default {
        name: "FormInput",
        components: { Loading, ErrorBag },
        props: {
            value: [String, Number, Boolean],
            radioValue: [String, Number],
            name: String,
            inputClass: String,
            placeholder: String,
	        label: String,
            validation: Object,
            leftIcon: String,
            rightIcon: String,
            error: Object,
            loading: Boolean,
            type: {
                type: String,
                default: 'text'
            }
        },
        methods: {
            focus(){
                this.$refs.input.focus()
            }
        }
    }
</script>
