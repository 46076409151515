import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import timeZone from "dayjs/plugin/timezone.js"
import utc from "dayjs/plugin/utc.js"
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/pt-br'

dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(timeZone)
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
// Configure default timezone
dayjs.tz.setDefault(Intl.DateTimeFormat()?.resolvedOptions()?.timeZone)

// Configure Locale
dayjs.locale('pt-br')

export default {
    install(vue) {

        Object.defineProperties(vue.prototype, {
            $dayjs: {
                get() {
                    return dayjs
                }
            }
        });

        vue.prototype.$utcDisplayDateTime = (date, invalidOrEmpty) => {
            return date ? dayjs(date).utc().format('L LT') : invalidOrEmpty
        }

        vue.prototype.$utcDisplayDate = (date, invalidOrEmpty) => {
            return date ? dayjs(date).utc().format('L') : invalidOrEmpty
        }

        vue.prototype.$displayDateTime = (date, invalidOrEmpty = null) => {
            return date ? dayjs(date).format('L LT') : invalidOrEmpty
        }

        vue.prototype.$displayTime = (date, invalidOrEmpty = null) => {
            return date ? dayjs(date).format('LT') : invalidOrEmpty
        }

        vue.prototype.$displayDate = (date, invalidOrEmpty) => {
            return date ? dayjs(date).format('L') : invalidOrEmpty
        }

    }
}
