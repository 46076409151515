import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http(window.API_PREFIX + "/support_tickets", {params}))
    },
    async get(id) {
        return to(Http(window.API_PREFIX + `/support_tickets/${id}`))
    },
    async create(params) {
        return to(Http(window.API_PREFIX + `/support_tickets/create`, {params}))
    },
    async edit(id, params) {
        return to(Http(window.API_PREFIX + `/support_tickets/${id}/edit`, {params}))
    },
    async insert(data) {
        return to(Http.post(window.API_PREFIX + `/support-tickets`, data))
    },
    async update(id, data) {
        data.append('_method', 'patch')
        return to(Http.post(window.API_PREFIX + `/support-tickets/${id}`, data))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.insert(data)
    },
}

