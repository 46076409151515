import Http from "@/config/axios.js"
import LoadingMixin from "@/mixins/loading.js"
import SystemMixin from "@/mixins/system.js"
import HandleRequestErrorsMixin from "@/mixins/handle_request_errors.js"
import {to} from "await-to-js"

export default {
    mixins: [LoadingMixin, SystemMixin, HandleRequestErrorsMixin],
    data() {
        return {
            isActionLoading: false
        }
    },
    computed: {
        hasActions() {
            return this.notification.actions?.length > 0
        }
    },
    methods: {
        /**
         * Handler action
         * @param action
         */
        async handleAction(action) {

            if (action.route) {

                const route = {name: action.route}

                if (action.route_params) {
                    route.params = action.route_params
                }

                if (action.route_query) {
                    route.query = action.route_query
                }

                if (action.route_hash) {
                    route.hash = action.route_hash
                }

                await this.$router.push(route).catch(() => {})
                this.delayClose(100)
                return;
            }

            if (action.redirect) {
                this.$router.push({name: action.redirect}).catch(() => {})
                this.delayClose(100)
                return;
            }

            if (action.close) return this.close()

            if (action.file) {
                window.open(action.file, '_blank')
                return this.close()
            }

            if (action.refresh) return window.location.reload()

            if (action.command) {

                this.loading('isActionLoading')

                const command = typeof action.command === 'string' ? {method: 'GET', url: action.command} : action.command

                command.url = window.API_PREFIX + "/" + command.url;

                const [err, res] = await to(Http(command))

                this.loaded('isActionLoading')

                if (err) return this.$alertError(this.handleRequestError(err))

                if (action.refresh_after) return window.location.reload()
                if (action.redirect_after) this.$router.push({name: action.redirect_after})

                this.delayClose()

                return true
            }
        },
        isActionLink(action) {
            return action.target === '_blank'
        }
    }
}