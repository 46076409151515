<template>
	<div>
		<slot v-bind:data="{...data, loadPostcode: loadPostcode}"/>
	</div>
</template>

<script>
import Auxiliar from "@/API/Auxiliar.js"

// TODO: Remove dependency
import {get} from "vuex-pathify"

export default {
	name: "HAddressLink",
	props: {
		value: Object,
		address: Object,
		prefix: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			data: {
				isStateLoading: false,
				isPostcodeLoading: false,
				country_id: null,
				country: null,
				postcode: null,
				countries: [],
				states: [],
				state: null,
				state_id: null,
				city: null,
				cities: [],
				address_1: null,
				address_2: null
			}
		}
	},
	computed: {
		...get('Auxiliar', ['countries'])
	},
	methods: {
		async loadStates(country) {

			if (!country) {
				this.data.states = []
				return;
			}

			this.data.isStateLoading = true
			const [err, res] = await Auxiliar.states(country)
			this.data.isStateLoading = false

			if (err) {
				return;
			}

			this.$emit('input', this.value)

			this.data.states = res.data.data

		},
		async loadPostcode() {
			if (!this.value[`${this.prefix}country_id`] || !this.value[`${this.prefix}postcode`]) return false

			this.data.isPostcodeLoading = true
			const [err, res] = await Auxiliar.postcode(this.value[`${this.prefix}country_id`], this.value[`${this.prefix}postcode`])
			this.data.isPostcodeLoading = false

			if (err) {
				this.data.postcode = null
				this.data.address_1 = null
				this.data.address_2 = null
				this.data.state = null
				this.data.state_id = null
				this.data.city = null

				return;
			}

			if (!res.data.postcode || res.data.postcode === 'undefined') return false

			this.data.postcode = res.data.postcode
			this.data.address_1 = res.data.address_1
			this.data.address_2 = res.data.address_2
			this.data.state = res.data.state
			this.data.state_id = res.data.state_id
			this.data.city = res.data.city

			const clone = copy_obj(this.value)

			clone[`${this.prefix}postcode`] = res.data.postcode
			clone[`${this.prefix}address_1`] = res.data.address_1
			clone[`${this.prefix}address_2`] = res.data.address_2
			clone[`${this.prefix}state`] = res.data.state
			clone[`${this.prefix}state_id`] = res.data.state_id
			clone[`${this.prefix}city`] = res.data.city

			this.$emit('input', clone)

		},
		clear() {
			this.data = {
				country_id: null,
				isStateLoading: false,
				isPostcodeLoading: false,
				postcode: null,
				state_id: null,
				city: null,
				cities: [],
				address_1: null,
				address_2: null
			};

			const clone = copy_obj(this.value)

			clone[`${this.prefix}postcode`] = null
			clone[`${this.prefix}address_1`] = null
			clone[`${this.prefix}address_2`] = null
			clone[`${this.prefix}state`] = null
			clone[`${this.prefix}state_id`] = null
			clone[`${this.prefix}city`] = null

			this.$emit('input', clone)
		}
	},
	mounted() {
		this.data.countries = this.countries

		if (this.value[`${this.prefix}country_id`]) {
			this.loadStates(this.value[`${this.prefix}country_id`])
		}

		this.$watch(`value.${this.prefix}country_id`, () => {
			this.data.country = this.countries.find(country => country.id == this.value[`${this.prefix}country_id`])

			this.loadStates(this.value[`${this.prefix}country_id`])
		})

		this.$watch(`value.${this.prefix}state_id`, () => {
			this.data.city = null
		})
	},
	watch: {
		countries: {
			immediate: true,
			handler(){
				this.data.countries = this.countries
			}
		}
	}
}
</script>
