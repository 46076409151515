export default {
    methods: {
        /**
         * Handler close
         */
        handleClose() {
            this.close()
        },
        close() {
            this.$emit('close', {id: this.notification.id})
        },
        /**
         *
         * @param delay
         */
        delayClose(delay = 4000) {
            setTimeout(() => this.close(), delay)
        }
    },
    mounted() {
        if (this.notification.auto_close) {
            this.notification.auto_close !== true
                ? this.delayClose(this.notification.auto_close)
                : this.delayClose()
        }
    }
}