import LoadingMixin from "@/mixins/loading"
import SystemMixin from "@/mixins/system"

import { options as documentTypes } from "@/enums/document_type"

import ValidationMixin from "@/mixins/validation.js"
import { email, minLength, required, sameAs } from "@vuelidate/validators"

import {get, sync} from "vuex-pathify"

export default {
    mixins: [LoadingMixin, SystemMixin, ValidationMixin],
    computed: {
        ... sync('Account', ['form']),
        documentTypes(){
            return documentTypes.map(item => {
                item.label = this.$t(item.label)
                return item
            })
        }
    },
    validations(){
        let form = {
            name: { required },
            document_type: { required },
            document: { required },
            phone: { required },
            email: { required, email },
        }

        /*
            country_id: { required },
            postcode: { required },
            address_1: { required},
            city: { required},
            state_id: { required },

            password: {
                required,
                minLength: minLength(6)
            },
            password_confirmation: {
                required,
                sameAs: sameAs('password')
            },
         */

        return { form }
    },
    methods: {
        /**
         * Action validate
         * @returns {boolean}
         */
        validate() {
            this.v$.$touch()

            console.debug('Account/Form.GeneralForm@validate: Validating data.')

            return !this.v$.$invalid
        }
    }
}
