import SupportTickets from "@/API/backoffice/SupportTickets.js"

import LoadingMixin from '@/mixins/loading.js'
import SystemMixin from '@/mixins/system.js'
import ValidationMixin from "@/mixins/validation.js"
import HandleRequestErrors from "@/mixins/handle_request_errors.js"

import {required} from "@vuelidate/validators"

// Todo: remove
import {get, sync} from "vuex-pathify"

export default {
    mixins: [LoadingMixin, SystemMixin, ValidationMixin, HandleRequestErrors],
    computed: {
        shipment: get('Shipments@form'),
        page: sync('SupportTickets@page')
    },
    validations: {
        form: {
            body: {
                required
            }
        }
    },
    methods: {
        /**
         * Validate the form and sub forms
         * @returns {Promise<boolean>}
         */
        async validate() {
            let valid = await this.v$.$validate()
            console.debug('Shipments/SupportTicketForm@validade: Validating data.', {valid})
            return valid && !this.isLoading
        },
        /**
         * Action Load
         * @returns {Promise<boolean>}
         */
        async save() {

            if (!await this.validate()) return false

            console.debug("Shipments/SupportTicketForm@save: Saving data.")

            const data = object_to_formdata(this.form, {indices: true})

            this.loading()
            const [err, response] = await SupportTickets.save(data)
            this.loaded()

            if (err) {
                console.error("Shipments/SupportTicketForm@load: Error loading data.", err)
                return this.$alertError(this.handleRequestError(err))
            }

            this.page = response.data

            console.debug("Shipments/SupportTicketForm@save: Data saved.", err)

            return true
        },
        /**
         * Action add client to support ticket address
         * @returns {Promise<boolean>}
         */
        async addClient() {
            if (this.hasClient) return false

            // For partner
            if (this.shipment?.partner_id) {
                await this.$refs.support_ticket_address.addItem('partner', this.shipment.partner_id, false)
            }

            // For provider
            if (this.shipment?.provider_id) {
                await this.$refs.support_ticket_address.addItem('provider', this.shipment.provider_id, false)
            }

            // For client
            if (this.shipment?.client_id) {
                await this.$refs.support_ticket_address.addItem('client', this.shipment.client_id, false)
            }

            return true
        },
        /**
         * INIT
         * @returns {Promise<void>}
         */
        async init() {
            // Nothing to do
            this.form.shipment_id = this.shipment.id
        }
    }
}