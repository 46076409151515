import {make} from "vuex-pathify"
import Chat from "@/API/Chat.js"
import Dexie from "dexie"
import { useWebWorker } from "@vueuse/core"

// const { data, post, terminate, worker } = useWebWorker('/chat_worker.js')

const DB = new Dexie('Tristar');

const state = {
    isLoading: false,
    rooms: [],
    messages: [],
    hasNewMessages: false
}

const mutations = make.mutations(state)

const actions = {
    async init({commit}){
        console.debug('Chat@init: Initializing chat store.')

        DB.version(1).stores({
            rooms: 'id, title, created_at, updated_at, new_messages',
            messages: 'id, room_id, user_id, message, created_at, updated_at'
        });

        DB.open().catch (function (err) {
            console.error('Failed to open db: ' + (err.stack || err));
        });

        // Dexie.on('storagemutated', async (data) => {
        //     // console.log('Data was changed: ', data);
        //
        //     commit('SET_ROOMS', await DB.rooms.toArray());
        // });

        DB.rooms.put({id: 1, title: 'Administradores', 'created_at': new Date(), 'updated_at': new Date(), 'new_messages': 0}).catch(e => {
            console.error(e.stack || e);
        })

        DB.rooms.put({id: 2, title: 'Sala do cliente', 'created_at': new Date(), 'updated_at': new Date(), 'new_messages': 0}).catch(e => {
            console.error(e.stack || e);
        })

        // for(let i = 0; i < 100; i++) {
        //     DB.messages.put({id: i+10, room_id: 2, user_id: 2, message: 'Teste ' + i ,created_at: new Date(), updated_at: new Date()}).catch(e => {
        //         console.error(e.stack || e);
        //     })
        // }
        //
        // for(let i = 0; i < 100; i++) {
        //     DB.messages.put({id: i+2000, room_id: 1, user_id: 2, message: 'Teste ' + i ,created_at: new Date(), updated_at: new Date()}).catch(e => {
        //         console.error(e.stack || e);
        //     })
        // }
    },
    async load({commit}){

        const rooms = await DB.rooms.toArray()

        for(const room of rooms) {
            room.messages = await DB.messages.where({room_id: room.id}).limit(50).toArray()
        }

        commit('SET_ROOMS', rooms);

        // DB.transaction('rw', DB.rooms, async () => {
        //     const rooms = await Chat.list();
        //     rooms.forEach(room => {
        //         DB.rooms.put(room);
        //     });
        // }).catch(e => {
        //     console.error(e.stack || e);
        // });
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
