import {get} from "vuex-pathify"

import UserProfiles from "@/enums/user_profiles"

export default {
    install(vue) {
        vue.mixin({
            computed: {
                $profile: get('APP/profile'),
                $isAdmin(){
                    return this.$user?.user_type === UserProfiles.ADMIN
                },
                $isNotAdmin(){
                    return ! this.$isAdmin
                },
            },
            methods: {
                $hasProfile(profileType){
                    return this.$user?.profiles.some(profile => profile == profileType)
                },
                $hasPartnerProfile(profileType){
                    return false
                    return this.$partner?.profiles.some(profile => profile == profileType)
                },
                $hasProviderProfile(profileType){
                    return false
                    return this.$provider?.profiles.some(profile => profile == profileType)
                }
            }
        })

    }
}
