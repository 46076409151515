import Vue from "vue"
import {make} from "vuex-pathify"
import Notifications from "@/API/Notifications.js"

const state = {
    isLoading: false,
    notifications: [],
    notifyList: [],
    events: [],
    hasNewNotifications: 0,
}

const mutations = make.mutations(state)

const actions = {
    /**
     * Set loading state
     * @param commit
     */
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    /**
     * Set loaded state
     * @param commit
     */
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    /**
     * Push notification to notify list
     * @param commit
     * @param state
     * @param payload
     */
    async push({commit, state, dispatch}, payload) {
        const list = state.notifyList

        payload.key = payload.id

        if (payload.ref && payload.update) {
            let index = list.findIndex(item => item.ref === payload.ref)

            if (index !== -1) {
                let item = list[index]
                // ID changes per notificaiton, so we remove the ID to store not recalculate all the list
                delete payload.id

                if (payload.notification_type === 'progress' || payload.notification_type === 'progress') {
                    item.code = payload.code
                    item.key = payload.key
                    item.progress = payload.progress
                    item.file = payload.file
                    item.notification_type = payload.notification_type
                } else {
                    Object.assign(item, payload)
                }

                Vue.set(list, index, item)

                return true
            }
        }

        list.unshift({...payload, data: {...payload}, visible: true})
        commit('SET_NOTIFY_LIST', list)
        dispatch('calcNewNotifications')
    },
    /**
     * Load notifications
     * @param commit
     * @param dispatch
     * @returns {Promise<boolean>}
     */
    async load({commit, dispatch}) {

        const [err, res] = await Notifications.list()

        if (err) {
            console.error(err)
            return false
        }

        commit('SET_NOTIFICATIONS', res.data.data)

        dispatch('calcNewNotifications')

        return true
    },
    /**
     * Set notifications list
     * @param commit
     * @param state
     * @param dispatch
     * @param payload
     * @returns {Promise<void>}
     */
    async addNotifications({commit, state, dispatch}, payload) {
        commit('SET_NOTIFICATIONS', payload)
        dispatch('calcNewNotifications')
    },
    readNotification({commit, state, dispatch}, payload) {
        const list = state.notifications
        commit('SET_NOTIFICATIONS', list.map((item) => {
            if (item.id === payload.id) {
                item.read_at = new Date()
            }
            return item
        }))

        dispatch('calcNewNotifications')
    },
    unreadNotification({commit, state, dispatch}, payload) {
        const list = state.notifications
        commit('SET_NOTIFICATIONS', list.map((item) => {
            console.debug(item.id, payload.id, item.id === payload.id)
            if (item.id === payload.id) {
                item.read_at = null
            }
            return item
        }))

        dispatch('calcNewNotifications')
    },
    /**
     * Calc new notifications (readed_at not null)
     * @param commit
     * @param dispatch
     * @param state
     */
    calcNewNotifications({commit, dispatch, state}) {
        commit('SET_HAS_NEW_NOTIFICATIONS', state.notifications.filter((item) => !item.read_at).length)
    },
    /**
     * Load notifications
     * @param commit
     * @param dispatch
     * @param state
     * @returns {Promise<boolean>}
     */
    async init({commit, dispatch, state}) {
        if (state.notifications.length) return true

        await dispatch('load')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
