<i18n src="@/i18n/domain/support_tickets/SupportTicketDeleteButton.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<button
		class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
		@click="handleDelete">
		<i class="fa fa-trash-alt mr-2"></i>
		{{ $t('support_ticket_delete_button.title') }}
	</button>
</template>

<script>
import Mixin from "./mixins/support_ticket_delete_button.js"

export default {
	name: "SupportTicketDeleteButton",
	mixins: [Mixin],
	props: {
		support_ticket: Object,
	},
	methods: {
		/**
		 * Handler delete register
		 * @returns {Promise<boolean>}
		 */
		async handleDelete() {
			// Confirm
			if (!await this.$modalConfirmTrue(this.$t("support_ticket_delete_button.message.title"), this.$t("support_ticket_delete_button.message.message"))) return false
			// Action
			if (await this.delete()) this.$alertSuccess(this.$t("form.deleted"))
			// Event
			this.$emit('deleted')
		}
	}
}
</script>