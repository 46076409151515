<template>
    <!-- Overlay !-->
    <div :class="{
        'absolute w-full flex inset-0 justify-center items-center z-50' : !fullscreen,
        'fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50' : fullscreen
        }">
        <div v-if="!fullscreen && overlay" class="absolute w-full h-full inset-0" :class="overlayClass"></div>
        <div v-if="fullscreen && overlay" class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0" :class="[`bg-white/50`]"></div>
        </div>
        <div class="spinner z-10">
            <slot/>
            <template v-if="useSpinner">
                <i class="spinner-icon" :class="[spinner, spinnerVelocity, spinnerColor]" :style="`font-size: ${spinnerSize}`"></i>
            </template>
            <template v-else>
                <div :class="[color]" class="bounce1 rounded-full shadow"></div>
                <div :class="[color]" class="bounce2 rounded-full shadow ml-1"></div>
                <div :class="[color]" class="bounce3 rounded-full shadow ml-1"></div>
            </template>
			<slot name="after"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loading",
        props: {
            fullscreen: Boolean,
            overlay: {
				type: Boolean,
				default: true
			},
            spinner: {
                type: String,
                default: 'fa fa-spinner-third'
            },
            spinnerVelocity: {
                type: String,
                default: 'normal'
            },
            spinnerColor: {
                type: String,
                default: 'text-turquoise'
            },
            spinnerSize: {
                type: String,
                default: '1rem'
            },
            useSpinner: Boolean,
            color: {
                type: String,
                default: 'bg-gray-600'
            },
            overlayClass: {
                type: String,
                default: 'bg-white/50'
            }
        },
        mounted() {
            if(this.fullscreen) document.body.classList.add('max-h-screen', 'overflow-hidden')
        },
        destroyed() {
            if(this.fullscreen) document.body.classList.remove('max-h-screen', 'overflow-hidden')
        }
    }
</script>

<style lang="scss">

    .spinner {
        /*width: 70px;*/
        text-align: center;
    }

    .normal {
        animation: spin .6s linear infinite;
    }

    .fast {
        animation: spin .4s linear infinite;
    }

    .slow {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to { transform: rotate(360deg); }
    }

    .spinner > div {
        width: 18px;
        height: 18px;

        /*border-radius: 100%;*/
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
        0%, 80%, 100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        } 40% {
              -webkit-transform: scale(1.0);
              transform: scale(1.0);
          }
    }
</style>
