<template>
	<FormSelect :options="locales" opt-label="name" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import FormSelect from "@/components/FormSelect.vue"

import {get} from "vuex-pathify"

export default {
	name: "FormSizeUnits",
	components: {
		FormSelect
	},
	computed: {
		locales: get("Auxiliar@locales")
	}
}
</script>