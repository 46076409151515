import Auth from "@/API/Auth"
import LoadingMixin from "@/components/Loading"

import ValidationMixin from "@/mixins/validation.js"
import { required, email } from "@vuelidate/validators"

export default {
    mixins: [ LoadingMixin, ValidationMixin ],
    data(){
        return {
            fields: {
                email: ""
            },
            alert: {
                show: false,
                message: null
            }
        }
    },
    validations: {
        fields: {
            email: {
                required,
                email
            }
        }
    },
    methods: {
        async send(){
            this.v$.$touch()
            if (this.v$.$invalid) return false

            this.loading()

            const [err, res] = await Auth.passwordRecovery(this.fields)
            this.loaded()

            if(err) {
                this.alert = { show: true, message: err.message }
                return false
            }

            console.log(res)
        }
    }
}
