import Http from "@/config/axios.js"
import { to } from "await-to-js"

export default {
    async list(){
         return to(Http.get("/notifications"))
    },
    async markAsReaded(id){
        return to(Http.get(`/notifications/${id}/read`))
    },
    async markAsNotReaded(id){
        return to(Http.get(`/notifications/${id}/unread`))
    },
    async markAllAsReaded(){
        return to(Http.get(`/notifications/all-read`))
    },
    async delete(id){
        return to(Http.delete(`/notifications/${id}`, {'_method': 'delete'}))
    }
}
