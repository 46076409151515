<template>
    <div class="w-full bg-white shadow-lg rounded-lg pointer-events-auto" :class="size">
        <div class="rounded-lg shadow-xs overflow-hidden">
            <div :class="{'flex' : sideAction }">
                <div class="p-4">
                    <div class="flex items-start" :class="{ 'items-start' : !singleAction, 'items-center' : singleAction }">
                        <div v-if="typeIcon" class="flex-shrink-0">
                            <i :class="[typeIcon, iconColor]" class="text-xl"></i>
                        </div>
                        <div class="ml-3 w-0 flex-1">
                            <h3 v-if="title" class="text-sm leading-5 font-medium text-gray-900 flex">
                                {{ title }}
                                <button v-if="singleAction"
                                        class="ml-auto flex-shrink-0 text-sm leading-5 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                                        @click="handleVerMais">
                                    {{ singleAction.label }}
                                </button>
                                <button v-if="!current && link"
                                        class="ml-auto flex text-blue-400 focus:outline-none focus:text-blue-500 hover:text-blue-500 transition ease-in-out duration-150 items-center"
                                        @click="handleVerMais">Ver
                                </button>
                            </h3>
                            <div v-if="current" class="flex">
                                <template>
                                    <div v-if="current.length > 1" class="mt-1 text-sm leading-5 text-gray-500">
                                        <ul :class="{ 'list-disc pl-5' : title && current.length }">
                                            <li v-for="(item, index) in current" :key="index">
                                                {{ item }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="mt-1 text-sm leading-5 text-gray-500">
                                        {{ current[0] }}
                                    </div>
                                </template>
                                <button v-if="link"
                                        class="ml-auto flex text-blue-400 focus:outline-none focus:text-blue-500 hover:text-blue-500 transition ease-in-out duration-150 items-center"
                                        @click="handleVerMais">
                                    <i class="fa fa-external-link mr-2"></i> Ver mais
                                </button>
                            </div>
                        </div>
                        <div v-if="hasCloseButton" class="ml-4 flex-shrink-0 flex">
                            <button class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                                    @click="handleClose">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="sideAction" class="ml-auto flex border-l border-gray-200">
                    <button
                        class="-ml-px flex items-center justify-center w-full border border-transparent rounded-r-lg p-4 text-sm leading-5 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-blue-700 active:bg-gray-50 transition ease-in-out duration-150"
                        @click="handleVerMais">
                        {{ sideAction.label }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";

export default {
    name: "Notification",
    props: {
        value: Boolean,
        type: {
            type: String,
            default: 'message'
        },
        float: Boolean,
        message: [String, Array, Object],
        title: String,
        link: String,
        time: {
            type: Number,
            default: 5000
        },
        verMais: Function,
        isEvent: Boolean,
        withClose: Boolean,
        singleAction: [Object],
        sideAction: [Object],
        size: {
            type: String,
            default: "sm:max-w-sm"
        }
    },
    computed: {
        hasCloseButton() {
            return this.withClose && (!this.sideAction && !this.singleAction)
        },
        current() {
            if (!this.message) return null

            if (typeof this.message === 'string') return [this.message]

            if (typeof this.message === 'object') {

                let list = [];

                Object.values(this.message).forEach(item => {
                    list = [...list, ...item]
                })

                return list
            }

            return this.message
        },
        textColor() {
            switch (this.type) {
                case 'error':
                    return 'text-red-700'
                case 'info':
                    return 'text-blue-700'
                case 'warning':
                    return 'text-yellow-700'
                case 'success':
                    return 'text-green-700'
            }
            return 'text-gray-700'
        },
        iconColor() {
            switch (this.type) {
                case 'error':
                    return 'text-red-500'
                case 'info':
                    return 'text-blue-500'
                case 'warning':
                    return 'text-yellow-400'
                case 'success':
                    return 'text-green-500'
            }

            if (this.isEvent) {
                return 'text-yellow-300'
            }

            return 'text-gray-600'
        },
        typeIcon() {
            switch (this.type) {
                case 'error':
                    return 'fal fa-times-circle'
                case 'warning':
                    return 'fal fa-exclamation-triangle'
                case 'info':
                    return 'fal fa-info-circle'
                case 'success':
                    return 'fal fa-check-circle'
            }

            if (this.isEvent) {
                return 'fa fa-bolt'
            }

            return ''
        },
    },
    methods: {
        handleClose() {
            this.close()
        },
        handleVerMais() {
            console.log('handleVerMais')
            if (this.link) {
                this.$router.push(this.link).catch(() => {
                })
            } else if (this.singleAction) {
                try {
                    this.singleAction.action();
                } catch (e) {
                }
            } else if (this.sideAction) {
                try {
                    this.$router.push(this.link).catch(() => {
                    })
                } catch (e) {
                }
            }

            this.close()
        },
        handleDescartar() {
            this.close()
        },
        close() {
            this.$emit('input', false)
        },
        startTimeOut() {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(this.close, this.time)
        }
    },
    mounted() {
        this.startTimeOut()
    },
    watch: {
        value() {
            if (this.value && this.time) this.startTimeOut()
        }
    }

}
</script>
