import Http from "@/config/axios"
import {to} from "await-to-js"

export default {
    async airports(params) {
        return to(Http("/auxiliar/airports", {params}))
    },
    async advanced_posts(params) {
        return to(Http("/auxiliar/advanced_posts", {params}))
    },
    async countries(params) {
        return to(Http("/auxiliar/countries", {params}))
    },
    async DDI(params) {
        return to(Http("/auxiliar/ddi", {params}))
    },
    async currencies(params) {
        return to(Http("/auxiliar/currencies", {params}))
    },
    async chargeable_currencies(params) {
        return to(Http("/auxiliar/chargeable_currencies", {params}))
    },
    async languages(params) {
        return to(Http("/auxiliar/languages", {params}))
    },
    async locales(params) {
        return to(Http("/auxiliar/locales", {params}))
    },
    async services(params) {
        return to(Http("/auxiliar/services", {params}))
    },
    async states(country, params = {}) {
        return to(Http(`/auxiliar/states/${country}`, {params}))
    },
    async cities(state, params = {}) {
        return to(Http(`/auxiliar/cities/${state}`, {params}))
    },
    async postcode(country, postcode, params = {}) {
        return to(Http(`/auxiliar/postcode/${country}/${postcode}`, {params}))
    },
    async packages(params = {}) {
        return to(Http(`/auxiliar/packages`, {params}))
    },
    async shipment_events(params = {}) {
        return to(Http(`/auxiliar/shipment-events`, {params}))
    },
}

