<template>
	<div v-if="showDropItem" class="inset-0 border border-black flex-1 text-sm fixed w-screen h-screen bg-black/10 backdrop-blur z-[90] flex flex-col justify-center items-center" @drop.prevent="handleDrop">
		<Card>
			<div class="h-32 w-32">
				<ul>
					<template v-for="file in files">
					<li>{{ file.name }}</li>
					</template>
				</ul>
			</div>
		</Card>
	</div>
</template>
<script>
import Imports from "@/API/Imports.js"
import Card from "@/components/Card.vue"

export default {
	name: "ImportContainer",
	components: {
		Card
	},
	data(){
		return {
			files: null,
			showDropItem: false
		}
	},
	methods: {
		async handleDrop(e){
			e.preventDefault()
			e.stopPropagation()

			const [err, res] = await Imports.uploadFiles(object_to_formdata({files: [...e.dataTransfer.files]}))

			if(err) {
				console.error(err)
				return false
			}

			console.debug({res})

			return true
		}
	},
	mounted() {

		const events = ['dragenter', 'dragover', 'dragleave', 'drop']

		function preventDefaults(e) {
			e.preventDefault()
		}

		events.forEach((eventName) => {
			document.body.addEventListener(eventName, preventDefaults)
		})

		document.addEventListener('dragover', (e) => {
			if(document.body.classList.contains('prevent-drag-drop')) return
			this.showDropItem = true
			e.preventDefault();
		}, false);

		document.addEventListener('dragleave', (e) => {
			this.showDropItem = false
			e.preventDefault();
		}, false);
	}
}
</script>