import {make} from "vuex-pathify"
import User from "@/API/User"

const state = {
    isLoading: false,
    form: { },
    page: { },
}

const mutations = make.mutations(state)

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
