<i18n src="@/i18n/shared/support/show.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<Page
		:title="$t('support.title', [form.id])"
		icon-back
		:titleLoading="isBgLoading"
		:loading="isLoading">
		<template #after-title>
			<div class="ml-auto flex space-x-3">
				<SupportTicketDeleteButton :support_ticket="form" @deleted="handleDeleted"/>
				<ActionButton v-if="form.shipment_id" @click="$router.push({name: 'shipments.show', params : { id: form.shipment_id }})">Ver remessa</ActionButton>
			</div>
		</template>
		<Card title="Chamado">
			<section aria-labelledby="message-heading" class="bg-gray-100 relative flex-grow flex h-full min-w-0 flex-grow flex-col overflow-hidden xl:order-last">
				<template v-if="form.support_ticket_status !== 99">
					<!-- Top section -->
					<div class="flex-shrink-0 border-b border-gray-200 bg-white">
						<!-- Toolbar-->
						<div class="flex h-16 flex-col justify-center">
							<!-- Actions -->
							<div class="px-4 sm:px-6 lg:px-8">
								<div class="flex justify-between py-3">
									<div class="isolate flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
										<!-- Left buttons -->
										<template v-if="form.support_ticket_status == 10 || form.support_ticket_status == 20">
											<!-- Left buttons -->
											<span class="inline-flex sm:shadow-sm">
											<!-- Reply !-->
											<SupportTicketReplyButton @click="handleReply"/>
												<!-- Add Address !-->
											<SupportTicketAddAddressButton @click="showAddressForm = true"/>
										</span>
										</template>
										<!-- / Left buttons -->
										<!-- / Right buttons -->
										<span class="hidden space-x-3 lg:flex">
											<!-- Archive !-->
											<SupportTicketArchiveButton :ticket="form" @archived="handleArchived"/>
											<!-- Complete !-->
											<SupportTicketCloseButton :ticket="form" @closed="handleClosed"/>
										</span>
										<!-- / Right buttons -->
									</div>
								</div>
							</div>
							<!-- / Actions -->
						</div>
						<!-- Message header -->
					</div>
				</template>
				<div class="min-h-0 flex-1 overflow-y-auto">
					<div class="bg-white pt-5 shadow">
						<div class="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
							<!-- Header !-->
							<div class="sm:w-0 sm:flex-1">
								<!-- Title !-->
								<h1 id="message-heading" class="text-lg font-medium text-gray-900">{{ $localize($data_get(form, 'subject.title_locale')) }}</h1>
								<!-- From !-->
								<p class="mt-1 truncate text-sm text-gray-500">From: {{ $data_get(form, 'created_by.label') }}</p>
								<!-- Details !-->
								<template v-if="showDetails">
									<div class="mt-1 flex space-x-2 items-center">
										<p class="truncate text-sm text-gray-500">To:</p>
										<div>
											<SupportTicketAddressList :address="addressList" class="text-gray-500"/>
										</div>
									</div>
								</template>
								<!-- / Details !-->
							</div>
							<!-- / Header !-->
							<!-- Badge !-->
							<div class="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
								<SupportTicketBadge :support-ticket="form"/>
							</div>
						</div>
						<!-- See more !-->
						<div class="flex">
							<button type="button" @click="handleShowDetails" class="flex-1 text-center">
								<i class="fa text-gray-300" :class="{'fa-caret-up' : showDetails, 'fa-caret-down' : !showDetails}"></i>
							</button>
						</div>
						<!-- / See more !-->
						<template v-if="showAddressForm">
							<div class="py-6 px-6 border-t border-gray-300 pt-4">
								<!-- Address List !-->
								<SupportTicketAddress ref="addressListAdd" v-model="addAddressList"/>
								<!-- Save !-->
								<div class="mt-4 flex justify-between">
									<!-- Cancel !-->
									<CancelButton @click="handleCancelAddAddress"/>
									<!-- Save !-->
									<SaveButton @click="handleSaveAddress"/>
								</div>
							</div>
						</template>
					</div>
					<!-- Thread section-->
					<ul role="list" class="my-4 space-y-2 py-4 sm:space-y-4 sm:px-6 lg:px-8">
						<!-- Messages !-->
						<template v-for="(message, index) in form.messages">
							<li class="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
								<div class="sm:flex sm:items-baseline sm:justify-between">
									<!-- Sender !-->
									<h3 class="flex space-x-2 text-base font-medium items-baseline">
										<span class="text-gray-900">{{ message.created_by.label }}</span>
										<span class="text-xs text-gray-600">escreveu</span>
									</h3>
									<!-- Create at !-->
									<p class="mt-1 whitespace-nowrap text-sm text-gray-600 sm:mt-0 sm:ml-3">
										<time datetime="2021-01-28T19:24">{{ $displayDateTime(message.created_at) }}</time>
									</p>
								</div>
								<!-- Body !-->
								<div class="mt-4 space-y-6 text-sm text-gray-800 prose-sm">
									<VueShowdown :markdown="message.body"/>
								</div>
							</li>
						</template>
						<!-- / Messages !-->
						<!-- Reply !-->
						<template v-if="showReplyForm">
							<li ref="replyForm" class="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
								<FormTextArea ref="replyBody" v-model="form.body" rows="8"/>
								<div class="mt-2">
									<FormFile name="file" v-model="file"/>
								</div>
								<div class="mt-4 flex justify-between">
									<!-- Cancel !-->
									<CancelButton @click="handleCancelReply"/>
									<!-- Send !-->
									<SendButton @click="handleSaveReply"/>
								</div>
							</li>
						</template>
						<!-- / Reply !-->
					</ul>
				</div>
			</section>
		</Card>
		<!-- Actions !-->
		<div class="flex justify-between mt-6">
			<!-- Cancel !-->
			<BackButton />
		</div>
		<!-- / Actions !-->
	</Page>
</template>

<script>
import Mixin from "./mixins/show.js"

import Page from "@/components/Page.vue"
import Card from "@/components/Card.vue"
import FormLine from "@/components/FormLine.vue"
import FormInput from "@/components/FormInput.vue"
import SendButton from "@/components/SendButton.vue"
import SaveButton from "@/components/SaveButton.vue"
import FormTextArea from "@/components/FormTextArea.vue"
import FormFile from "@/components/FormFile.vue"
import FormGroup from "@/components/FormGroup.vue"
import CreateButton from "@/components/CreateButton.vue"
import CancelButton from "@/components/CancelButton.vue"
import Loading from "@/components/Loading.vue"
import ActionButton from "@/components/ActionButton.vue"
import {VueShowdown} from 'vue-showdown'

import SupportTicketBadge from "@/domain/supports/SupportTicketBadge.vue"
import SupportTicketFormPartial from "./partials/SupportTicketFormPartial.vue"
import TicketNavPartial from "./partials/TicketNavPartial.vue"

import SupportTicketAddress from "@/domain/support_tickets/SupportTicketAddress.vue"
import SupportTicketAddressList from "@/domain/support_tickets/SupportTicketAddressList.vue"
import SupportTicketReplyButton from "@/domain/support_tickets/SupportTicketReplyButton.vue";
import SupportTicketAddAddressButton from "@/domain/support_tickets/SupportTicketAddAddressButton.vue";
import SupportTicketCloseButton from "@/domain/support_tickets/SupportTicketCloseButton.vue";
import SupportTicketArchiveButton from "@/domain/support_tickets/SupportTicketArchiveButton.vue";
import SupportTicketDeleteButton from "@/domain/support_tickets/SupportTicketDeleteButton.vue";

import Http from "@/config/axios.js"
import BackButton from "@/components/BackButton.vue";

export default {
	name: "ShowPage",
	mixins: [Mixin],
	props: {
		id: [Number, String]
	},
	components: {
		BackButton,
		Card,
		Page,
		FormGroup,
		FormLine,
		SendButton,
		FormInput,
		FormFile,
		SupportTicketFormPartial,
		SupportTicketBadge,
		CreateButton,
		TicketNavPartial,
		FormTextArea,
		Loading,
		SupportTicketDeleteButton,
		ActionButton,
		SaveButton,
		CancelButton,
		VueShowdown,
		SupportTicketAddressList,
		SupportTicketArchiveButton,
		SupportTicketCloseButton,
		SupportTicketAddAddressButton,
		SupportTicketReplyButton,
		SupportTicketAddress,
	},
	data() {
		return {
			showForm: false,
			showDetails: false,
			showReplyForm: false,
			showAddressForm: false,
			file: null,
			addAddressList: [],
			replyForm: {
				form: null,
				attachments: []
			}
		}
	},
	computed: {
		/**
		 * Has tickets
		 * @returns {boolean}
		 */
		hasTickets() {
			return this.page?.data?.length > 0
		},
		/**
		 * Destination addresses
		 * @returns {string | undefined}
		 */
		destinationAddresses() {
			return this.ticket?.addresses?.map(address => address.label).join(', ')
		},
		addressList() {
			return this.ticket?.addresses ?? []
		}
	},
	methods: {
		handleCancel() {
			this.$router.back()
		},
		handleDeleted() {
			this.$router.back()
		},
		/**
		 * Handle close ticket
		 */
		handleCloseTicket() {
			this.ticket = {}
			this.$emit('close')
		},
		/**
		 * Handle select ticket
		 * @param evt
		 */
		handleSelectTicket(evt) {
			this.load(evt.id)
		},
		handleShowDetails() {
			this.showDetails = !this.showDetails
		},
		/**
		 * Handle add new address
		 */
		handleAddAddress() {
			this.showAddressForm = true
			this.$nextTick(() => this.$refs.addressListAdd.focus())
		},
		handleCancelAddAddress() {
			this.showAddressForm = false
		},
		handleSaveAddress() {
			// TODO
		},
		/**
		 * Handle archive
		 * @returns {Promise<void>}
		 */
		async handleArchived() {
			this.$alertSuccess('Ticket arquivado com sucesso!')
			this.load(this.form.id)
		},
		/**
		 * Handle closed
		 * @returns {Promise<void>}
		 */
		async handleClosed() {
			this.$alertSuccess('Ticket fechado com sucesso!')
			this.load(this.form.id)
		},
		/**
		 * Handler reply
		 */
		handleReply() {
			this.showReplyForm = true
			this.$nextTick(() => {
				this.$refs.replyForm.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
				// Fix for scroll animation
				setTimeout(() => this.$refs.replyBody.focus(), 500)
			})
		},
		/**
		 * Handle save reply
		 * @returns {Promise<void>}
		 */
		async handleSaveReply() {
			if (await this.reply()) {
				this.$alertSuccess('Messangem enviada com sucesso!')
			}
		},
		/**
		 * Handle cancel reply
		 */
		handleCancelReply() {
			this.replyForm = {
				body: ""
			}
			this.showReplyForm = false
		},
		handleCreate() {
			this.showForm = true
		},
		handleShowForm() {
			this.showForm = true
		},
		handleCreateTicket() {
			this.$emit('create')
		}
	},
	mounted() {
		this.init()
	}
}
</script>