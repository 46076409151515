<template>
	<div class="-mt-6 sm:-mt-5 px-4 py-4 sm:py-6 sm:px-6 lg:px-8">
		<slot />
	</div>
</template>

<script>
export default {
	name: "FormGroup"
}
</script>