import {make} from "vuex-pathify"
import Auxiliar from "@/API/Auxiliar"

const state = {
    isLoading: false,
    countries: [],
    DDI: [],
    currencies: [],
    chargeable_currencies: [],
    locales: [],
    airports: [],
    services: [],
    packages: [],
    advancedPosts: [],
    shipment_events: []
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    },
    async loadShipmentEvents({commit, dispatch, state}, payload = {}) {

        console.debug('Auxiliar@loadShipmentEvents')

        dispatch('loading')

        if(!payload.reload && state.shipment_events?.length) {
            return true
        }

        const [err, res] = await Auxiliar.shipment_events()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadShipmentEvents', err)
            commit('SET_SHIPMENT_EVENTS', [])
            return false
        }

        commit('SET_SHIPMENT_EVENTS', res.data.data)
    },
    async loadAirports({commit, dispatch}) {

        console.debug('Auxiliar@loadAirports')

        dispatch('loading')

        const [err, res] = await Auxiliar.airports()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadAirports', err)
            commit('SET_AIRPORTS', [])
            return false
        }

        commit('SET_AIRPORTS', res.data.data)
    },
    async loadAdvancedPosts({commit, dispatch}) {

        console.debug('Auxiliar@loadAdvancedPosts')

        dispatch('loading')

        const [err, res] = await Auxiliar.advanced_posts()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadAdvancedPosts', err)
            commit('SET_ADVANCED_POSTS', [])
            return false
        }

        commit('SET_ADVANCED_POSTS', res.data.data)
    },
    async loadCountries({commit, dispatch}) {

        console.debug('Auxiliar@loadCountries')

        dispatch('loading')

        const [err, res] = await Auxiliar.countries()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadCountries', err)
            commit('SET_COUNTRIES', [])
            return false
        }

        commit('SET_COUNTRIES', res.data.data)
    },
    async loadDDI({commit, dispatch}) {

        console.debug('Auxiliar@loadDDI')

        dispatch('loading')

        const [err, res] = await Auxiliar.DDI()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadDDI', err)
            commit('SET_DDI', [])
            return false
        }

        commit('SET_DDI', res.data.data)
    },
    async loadPackages({commit, dispatch}) {

        console.debug('Auxiliar@loadPackages')

        dispatch('loading')

        const [err, res] = await Auxiliar.packages()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadPackages', err)
            commit('SET_PACKAGES', [])
            return false
        }

        commit('SET_PACKAGES', res.data)
    },
    async loadCurrencies({commit, dispatch}) {

        console.debug('Auxiliar@loadCurrencies')

        dispatch('loading')

        const [err, res] = await Auxiliar.currencies()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadCurrencies', err)
            commit('SET_CURRENCIES', [])
            return false
        }

        commit('SET_CURRENCIES', res.data)
    },
    async loadServices({commit, dispatch}) {

        console.debug('Auxiliar@loadServices')

        dispatch('loading')

        const [err, res] = await Auxiliar.services()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadServices', err)
            commit('SET_SERVICES', [])
            return false
        }

        commit('SET_SERVICES', res.data.data)
    },
    async loadLocales({commit, dispatch}) {

        console.debug('Auxiliar@loadLocales')

        dispatch('loading')

        const [err, res] = await Auxiliar.locales()

        dispatch('loaded')

        if (err) {
            console.error('stores/Auxiliar@loadLocales', err)
            commit('SET_LOCALES', [])
            return false
        }

        commit('SET_LOCALES', res.data.data)
    },
    async load({context, dispatch}){

        console.debug('Auxiliar@load')

        dispatch('loading')

        await Promise.all([
            dispatch('loadCountries'),
            dispatch('loadAirports'),
            dispatch('loadAdvancedPosts'),
            dispatch('loadDDI'),
            dispatch('loadLocales'),
            dispatch('loadCurrencies'),
            dispatch('loadServices'),
            dispatch('loadPackages'),
        ])

        dispatch('loaded')
    },
    async init({commit, dispatch}) {

        console.debug('Auxiliar@init')

        if (!window.AUXILIAR) return await dispatch('load');

        // if (window.AUXILIAR['advanced_posts']) {
        //     commit('SET_ADVANCED_POSTS', window.DATA['advanced_posts']);
        // } else {
        //     await dispatch('loadAdvancedPosts')
        // }
        // if (window.AUXILIAR['contries']) {
        //     commit('SET_COUNTRIES', window.DATA['contries']);
        // } else {
        //     await dispatch('loadCountries')
        // }
        //
        // if (window.AUXILIAR['DDI']) {
        //     commit('SET_DDI', window.DATA['DDI']);
        // } else {
        //     await dispatch('loadDDI')
        // }
        //
        // if (window.AUXILIAR['packages']) {
        //     commit('SET_PACKAGES', window.DATA['packages']);
        // } else {
        //     await dispatch('loadPackages')
        // }
        //
        // if (window.AUXILIAR['currencies']) {
        //     commit('SET_CURRENCIES', window.DATA['currencies']);
        // } else {
        //     await dispatch('loadCurrencies')
        // }

        if (window.AUXILIAR['languages']) {
            commit('SET_LOCALES', window.DATA['languages']);
        } else {
            await dispatch('loadLanguages')
        }

        // if (window.AUXILIAR['services']) {
        //     commit('SET_SERVICES', window.DATA['services']);
        // } else {
        //     await dispatch('loadServices')
        // }
        //
        // if (window.AUXILIAR['airports']) {
        //     commit('SET_AIRPORTS', window.DATA['airports']);
        // } else {
        //     await dispatch('loadAirports')
        // }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
