<template>
	<li class="flex gap-x-6 py-5">
		<div class="flex flex-grow items-center min-w-0 gap-x-4">
			<!-- Picture !-->
			<UserProfile :user="user" image-class="w-12 h-12" letter-class="w-12 h-12" />
			<!-- Info !-->
			<div class="min-w-0 flex-auto">
				<p class="text-sm font-semibold leading-6 text-gray-900">{{ user.label ? user.label : user.name }}</p>
				<p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ user.company_name }}</p>
			</div>
		</div>
		<div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
			<p class="text-sm leading-6 text-gray-900">{{ user.company_position }}</p>
			<!--									<p class="mt-1 text-xs leading-5 text-gray-500">Last seen <time datetime="2023-01-23T13:23Z">3h ago</time></p>-->
		</div>
		<slot />
	</li>
</template>

<script>
import UserProfile from "@/domain/users/UserProfile.vue"
export default {
	name: "UserItemList",
	props: {
		user: {
			type: Object,
			required: true
		},
	},
	components: {
		UserProfile
	}
}
</script>