<i18n src="@/i18n/components/Notifications.json"></i18n>
<i18n src="@/i18n/notifications/index.json"></i18n>
<template>
	<div class="group pointer-events-auto w-full pl-6" :class="notificationSizeClass">
		<div class="relative w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5" @click.stop="handleClick">
			<div class="flex">
				<!-- Progressbar !-->
				<template v-if="notification.progress !== null && notification.progress >= 0">
					<div class="left-0 right-0 absolute h-[2px] mx-2 bottom-1 overflow-hidden">
						<div class="absolute h-[2px] bg-turquoise rounded-full opacity-50 transition-all duration-300 ease-in-out" :style="`width: ${notification.progress}%`"></div>
					</div>
				</template>
				<!-- Progress spin !-->
				<template v-if="notification.progress !== null && notification.progress >= 0">
					<div class="w-12 relative flex flex-col items-center justify-center">
						<Loading use-spinner spinner-color="text-turquoise" :overlay="false"/>
					</div>
				</template>
				<template v-else-if="notification.notification_status">
					<div class="w-12 relative flex flex-col items-center justify-center">
						<!-- Icon !-->
						<div class="flex-shrink-0">
							<i class="fa text-2xl" :class="notificationIconClass">
							</i>
						</div>
					</div>
				</template>
				<!-- Title !-->
				<div :key="notification.id" class="flex-grow" :class="notification.progress !== null ? 'p-4 pl-0' : 'p-4' ">
					<div class="flex items-start">
						<div class="w-0 flex-1 pt-0.5">
							<p class="text-sm font-medium text-gray-900">{{ title }}</p>
							<div v-if="hasMessage" class="mt-1 text-xs text-gray-500">
								<p v-html="message"></p>
							</div>
						</div>
					</div>
				</div>
				<!-- Progress !-->
				<template v-if="notification.progress">
					<div class="flex p-4 items-center">
						<p>{{ notification.progress }}</p>
						<i class="ml-1 fal fa-percent"></i>
					</div>
				</template>
				<!-- Close !-->
				<div class="flex items-center">
					<button type="button" class="absolute hidden left-0 -ml-4 group-hover:block" @click.stop="handleClose">
						<i class="fa fa-times text-black/50 hover:text-red-600"></i>
					</button>
				</div>
				<!-- Actions !-->
				<template v-if="hasActions">
					<div class="flex border-l border-gray-200 divide-x">
						<!-- One Action !-->
						<template v-if="notification.actions.length === 1">
							<button
								type="button"
								class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-turquoise hover:text-turquoise focus:outline-none focus:ring-2 focus:ring-turquoise"
								@click.stop="handleAction(notification.actions[0])">
								{{ $t("notification.actions." + notification.actions[0].label) }}
							</button>
						</template>
						<!-- / One Action !-->
						<!-- Multi Actions !-->
						<template v-else>
							<div class="flex">
								<!-- Action !-->
								<button
									type="button"
									class="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-turquoise hover:text-turquoise focus:z-10 focus:outline-none focus:ring-2 focus:ring-turquoise"
									@click.stop="handleAction(notification.actions[0])">
									{{ $t("notification.actions." + notification.actions[0].label) }}
								</button>
							</div>
							<div class="flex">
								<!-- Action !-->
								<button
									type="button"
									class="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-turquoise"
									@click.stop="handleAction(notification.actions[1])">
									{{ $t("notification.actions." + notification.actions[1].label) }}
								</button>
							</div>
						</template>
						<!-- / Multi Actions !-->
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from "@/components/Loading.vue"
import HandleCloseMixin from "./mixins/handle_close.js"
import HandleActionMixin from "./mixins/handle_action.js"

export default {
	name: "ProgressNotification",
	mixins: [HandleCloseMixin, HandleActionMixin],
	props: {
		notification: {
			type: Object,
			required: true
		}
	},
	components: {Loading},
	computed: {
		/**
		 * Calculate notification type icon class
		 * @returns {VueI18n.TranslateResult}
		 */
		title() {
			return this.$t(`notification.${this.notification.code}.title`, this.notification.data.params)
		},
		/**
		 * Calculate notification message
		 * @returns {VueI18n.TranslateResult}
		 */
		message() {
			return this.$t(`notification.${this.notification.code}.message`, this.notification.data.params)
		},
		/**
		 * Check if notification has a message
		 * @returns {boolean}
		 */
		hasMessage() {
			return this.message !== `notification.${this.notification.code}.message`
		},
		/**
		 * Calculate notification size
		 * @returns {*}
		 */
		notificationSizeClass() {
			return {
				'sm': 'max-w-sm',
				'md': 'max-w-md',
				'lg': 'max-w-lg',
				'full': '',
			}[this.notification.size] ?? 'max-w-sm'
		},
		/**
		 * Calculate notification type icon class
		 * @returns {*}
		 */
		notificationIconClass() {
			return {
				'info': 'fa-circle-info text-gray-400',
				'success': 'fa-check-circle text-green-500',
				'warning': 'fa-warning text-yellow-500',
				'error': 'fa-times-circle text-red-500',
			}[this.notification.notification_status]
		}
	},
	methods: {
		/**
		 * Handle click on notification
		 */
		handleClick(){
			if(this.notification.actions?.length === 1) {
				this.handleAction(this.notification.actions[0])
			}
		},
		// .. handleAction
		// .. handleClose
		// .. delayClose
	}
}
</script>
