<template>
    <transition
        :enter-class="`transform opacity-0 scale-95 ${enterExtra}`"
        enter-active-class="transition ease-out duration-100"
        enter-to-class="transform opacity-100 scale-100"
        :leave-class="`transform opacity-100 scale-100 ${enterLeave}`"
        leave-to-class="transform opacity-0 scale-95"
        leave-active-class="transition ease-in duration-75">
        <slot/>
    </transition>
</template>

<script>
    export default {
        name: "OpacityScaleTransition",
        props: {
            enterExtra: String,
            enterLeave: String
        }
    }
</script>
