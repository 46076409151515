import {enum_to_hash} from "../helpers.js"

export default {
    "ADMIN": 10,
    "DEV": 11,
    "USER": 20,
    "CLIENT": 30,
}

export const options = [
    {id: 10, text: 'ADMIN', label: 'Admin'},
    {id: 11, text: 'DEV', label: 'Dev'},
    {id: 20, text: 'USER', label: 'User'},
    {id: 30, text: 'CLIENT', label: 'Client'}
]

export const hash = enum_to_hash(options)