<template>
	<section class="flex-1 w-screen max-w-md -mx-4">
		<div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
			<!-- Header !-->
			<header class="p-6">
				<div class="flex space-x-2 items-center">
					<i class="mr-2 text-xl text-turquoise fa fa-bell"></i>
					<h2 class="text-lg leading-7 font-medium text-dark-blue-gray">
						Notificações
					</h2>
				</div>
			</header>
			<!-- Filter Tabs !-->
			<div class="border-b border-gray-200">
				<div class="px-6">
					<nav class="-mb-px flex space-x-6">
						<button
							type="button"
							class="whitespace-no-wrap pb-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none"
							:class="filter === 'all' ? 'text-blue-600 border-blue-500 focus:text-blue-800 focus:border-blue-700' : 'text-gray-500 hover:text-gray-700 border-transparent focus:text-gray-700 focus:border-gray-300'"
							aria-current="page"
							@click="filter = 'all'">
							Todas
						</button>
						<button
							type="button"
							class="whitespace-no-wrap pb-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none"
							:class="filter === 'new' ? 'text-blue-600 border-blue-500 focus:text-blue-800 focus:border-blue-700' : 'text-gray-500 hover:text-gray-700 border-transparent focus:text-gray-700 focus:border-gray-300'"
							@click="filter = 'new'">
							Novas
						</button>
						<button
							type="button"
							class="whitespace-no-wrap pb-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none"
							:class="filter === 'old' ? 'text-blue-600 border-blue-500 focus:text-blue-800 focus:border-blue-700' : 'text-gray-500 hover:text-gray-700 border-transparent focus:text-gray-700 focus:border-gray-300'"
							@click="filter = 'old'">
							Lidas
						</button>
					</nav>
				</div>
			</div>
			<!-- /Filter Tabs !-->
			<template v-if="hasNotification">
				<!-- Notifications !-->
				<div class="flex-1 overflow-y-auto">
					<ul class="h-0 divide-y divide-gray-200">
						<template v-for="(notification, index) in notifications">
							<!-- Notification !-->
							<li :key="`notification-${notification.id}`" class="relative">
								<NotificationItem :notification="notification" @reload="handleLoad" @deleted="handleDelete(index)" @click="handleClick"/>
							</li>
						</template>
					</ul>
				</div>
			</template>
			<template v-else>
				<!-- Empty !-->
				<div class="flex-1 flex items-center justify-center">
					<div class="text-center">
						<h3 class="text-lg leading-6 font-medium text-gray-900">
							Nenhuma notificação
						</h3>
						<div class="mt-2 text-sm leading-5 text-gray-500">
							<p>
								Quando você tiver notificações, elas aparecerão aqui.
							</p>
						</div>
					</div>
				</div>
			</template>
			<!-- /Empty !-->
		</div>
		<!-- /Notifications !-->
	</section>
</template>
<script>
import NotificationItem from "./NotificationItem.vue"
import {dispatch, get} from "vuex-pathify";

export default {
	name: "NotificationSlider",
	components: {
		NotificationItem
	},
	data() {
		return {
			filter: 'all'
		}
	},
	computed: {
		notifications: get('Notifications/notifications'),
		filteredNotifications() {
			if (this.filter === 'new') {
				return this.notifications.filter(notification => !notification.read_at)
			}

			if (this.filter === 'old') {
				return this.notifications.filter(notification => notification.read_at)
			}

			return this.notifications
		},
		hasNotification() {
			return this.filteredNotifications.length > 0
		}
	},
	methods: {
		handleClick(){
			this.$emit('close')
		},
		handleLoad() {
			// Load notifications
		},
		handleDelete(index) {
			this.notifications.splice(index, 1)

			dispatch('Notifications/calcNewNotifications')
		}
	}
}
</script>