<template>
	<section ref="container"
			 class="flex flex-col bg-gray-50 border border-gray-200 pointer-events-auto rounded-md transform hover:shadow-xl transition-all"
			 :class="{
				'shadow-lg translate-y-0' : open,
				'w-[220px] shadow-lg translate-y-[calc(100%-50px)]' : !open ,
				'w-[500px] h-[700px]' : expanded && open,
				'w-[340px] h-[400px]' : !expanded && open,
				'h-[400px]' : !expanded && !open
			}">
		<!-- Title, Status, New Messages !-->
		<header class="p-4 flex items-center cursor-pointer" :class="{ 'hover:bg-gray-100' : !open }" @click="handleOpen">
			<!-- Status !-->
			<!--			<div class="mr-2 flex-none rounded-full p-1 text-red-400 bg-red-400/10">-->
			<!--				<div class="h-2 w-2 rounded-full bg-current"></div>-->
			<!--			</div>-->
			<!-- Title !-->
			<h3 class="font-semibold truncate"
				:class="{
				'max-w-[160px]' : !open,
				'max-w-[220px]' : open && !expanded,
				'max-w-[380px]' : open && expanded,
				}">{{ title }}</h3>
			<template v-if="open">
				<!-- Group Menu !-->
				<button type="button" class="ml-3 rounded-full text-[12px] hover:bg-gray-200 flex justify-center items-center tabular-nums">
					<i class="ml-2 fal fa-users"></i>
				</button>
				<!-- Expand !-->
				<button type="button" class="ml-auto p-3 rounded-full text-[12px] hover:bg-gray-200 flex justify-center items-center" @click.stop="handleExpand">
					<i class="absolute fal" :class="expanded ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'"></i>
				</button>
			</template>
			<!-- Open/Close !-->
			<button type="button" class="p-3 rounded-full text-[12px] hover:bg-gray-200 flex justify-center items-center"
					:class="{ 'ml-auto' : !open, 'ml-3' : open }" @click.stop="handleClose">
				<i class="absolute fal fa-times text-lg"></i>
			</button>
		</header>
		<!-- Group Members!-->
		<div v-if="room.expanded && room.is_group" class="p-2 flex space-x-2 border-y bg-gray100 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
			<template v-for="(member, index) in room.members">
				<p :key="`member-${index}`" class="whitespace-nowrap bg-gray-100 rounded-md py-1.5 px-2.5 text-xs">{{ member.name }}</p>
			</template>
		</div>
		<!-- Messages !-->
		<div class="flex-1 flex flex-col p-2 border-t border-gray-200">
			<ChatMessageContainer ref="scroll" :members="room.members" :messages="room.messages"/>
		</div>
		<!-- Message Input !-->
		<div class="p-2 flex space-x-2 items-end">
			<!-- Message !-->
			<textarea ref="editor" rows="1" class="form-input rounded w-full resize-none" @input="handleInput" @keydown.enter="handleSend"/>
			<!-- Button !-->
			<button type="button" class="py-3.5 px-3 flex space-x-2 items-center bg-blue-500 rounded text-white hover:bg-blue-600" @click="handleSend">
				<i class="fa fa-paper-plane"></i>
			</button>
		</div>
	</section>
</template>

<script>
import ChatMessageContainer from "@/domain/chat/ChatMessageContainer.vue"
export default {
	name: "ChatRoom",
	props: {
		open: Boolean,
		expanded: Boolean,
		room: {
			type: Object,
			required: true
		},
		currentUserId: 1
	},
	components: {
		ChatMessageContainer,
	},
	data() {
		return {
			chatRoomState: {
				open: this.open,
				expanded: this.expanded,
				currentUserId: this.currentUserId
			}
		}
	},
	computed: {
		title() {
			return (this.room.id) ? this.room.title : 'Nova conversa'
		}
	},
	provide() {
		return {
			chatRoomState: this.chatRoomState
		}
	},
	methods: {
		handleSend(){
			this.send();
		},
		handleOpen() {
			this.$emit('update:open', !this.open)
		},
		handleExpand() {
			this.$emit('update:expanded', !this.expanded)
		},
		handleClose() {
			this.$emit('update:show', false)
		},
		handleInput(e) {
			e.target.rows = e.target.value?.search('\n') !== -1 ? e.target.value?.split('\n').length : 1
		}
	},
	watch: {
		open: {
			immediate: true,
			handler(val) {
				this.chatRoomState.open = val
				if (val) {
					this.$nextTick(() => {
						this.$refs.editor.focus()
						this.$refs.scroll?.scrollEnd()
					})
				}
			}
		},
		expanded: {
			immediate: true,
			handler(val) {
				this.chatRoomState.expanded = val
			}
		}
	}

}
</script>