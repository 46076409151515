import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get(window.API_PREFIX + "/imports", {params}))
    },
    async get(id) {
        return to(Http.get(window.API_PREFIX + `/imports/${id}`))
    },
    async create(data) {
        return to(Http.post(window.API_PREFIX + `/imports/create`, data))
    },
    async edit(id, data) {
        return to(Http.post(window.API_PREFIX + `/imports/${id}/edit`, data))
    },
    async insert(data) {
        return to(Http.post(window.API_PREFIX + `/imports`, data))
    },
    async update(id, data) {
        data.append('_method', 'patch')
        return to(Http.post(window.API_PREFIX + `/imports/${id}`, data))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.insert(data)
    },
    async delete(id) {
        return to(Http.post(window.API_PREFIX + `/imports/${id}`, {'_method': 'delete'}))
    },
    async uploadFiles(params) {
        return to(Http.post(window.API_PREFIX + "/upload-files", params))
    },
}

