import {data_get} from "@/helpers"

export default {
    install(vue) {
        vue.mixin({
            methods: {
                $data_get: data_get
            }
        })

    }
}
