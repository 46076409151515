<template>
    <div>
        <div class="hidden sm:block">
            <div class="flex">
                <nav class="flex space-x-3" aria-label="Tabs">
                    <template v-for="(item, index) in list">
                        <button class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                                :class="{ 'border-turquoise' : item === currentSelected }"
                            @click="handleSelect(item)">
                            {{ item.label }}
                            <template v-if="totalFor(item)">
                                <span class="bg-gray-100 text-gray-900 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block" :class="item.class">
                                    {{ totalFor(item) }}
                                </span>
                            </template>
                        </button>
					</template>
					<slot />
				</nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TabFilter",
    props: [
		'list',
		'totals',
		'total',
        'selected'
	],
    data(){
        return {
            currentSelected: null
        }
    },
    methods: {
        /**
         * Handle select
         * @param item
         */
        handleSelect(item){
            this.$emit('selected', item)
            this.$emit('click', item)
            this.currentSelected = item
        },
        /**
         * Calculate Total for Status
         * @param item
         * @returns {*|number}
         */
        totalFor(item){
            const index = item.text ? item.text.toLowerCase() : 'total';
            return this.totals && this.totals[index] ? this.totals[index] : 0
        }
    },
    watch: {
        list: {
            handler(value){
                if(!this.currentSelected && value.length) {
                    this.currentSelected = value[0]
                }
            },
            immediate: true
        }
    }
}
</script>