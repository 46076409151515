import Locale from "@/API/Locale"
import {dispatch, get, sync} from "vuex-pathify";

import LoadingEmitterMixin from "@/mixins/loading_emitter.js"

export default {
    mixins: [LoadingEmitterMixin],
    data(){
        return {
            isLocaleLoading: false,
            reloadWhenChangeLocale: false
        }
    },
    computed: {
        ...get('Auxiliar', ['countries', 'locales']),
        current_locale: sync('Locales/current'),
        currentLocale(){
            if(!this.current_locale || !this.locales) return null
            return this.locales.find(locale => locale.uti == this.current_locale)
        }
    },
    methods: {
        async setLocale(locale) {
            this.loading('isLocaleLoading')
            const [err, res] = await Locale.set(locale)
            this.loaded('isLocaleLoading')

            if (err) {
                this.$alertError("Ops, houve um erro ao se registrar!")
                return false
            }

            document.querySelector('html')
                .setAttribute('lang', locale)

            this.current_locale = locale

            this.$i18n.locale = locale

            if(this.reloadWhenChangeLocale) {
                window.location.reload()
            }

            return true
        },
        async loadLocale() {

            if(this.current_locale || this.isLocaleLoading) return true

            console.debug("Loading currentLocale")
            this.loading('isLocaleLoading')
            const [err, res] = []// await Locale.get()
            this.loaded('isLocaleLoading')

            if (err) {
                this.$alertError("Ops, houve um erro ao se registrar!")
                return false
            }

            this.current_locale = res.data

            return true
        }
    }
}
