<template>
	<button
		v-if="ticket && ticket.support_ticket_status == 0"
		type="button"
		class="relative -ml-px hidden items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
		@click="handleArchive">
		<i class="fa fa-box-taped text-gray-600 mr-1"></i>
		Arquivar
	</button>
</template>

<script>
import LoadingMixin from "@/mixins/loading_emitter.js"
import SupportTickets from "@/API/backoffice/SupportTickets.js"

export default {
	name: "SupportTicketArchiveButton",
	mixins: [LoadingMixin],
	props: {
		ticket: {
			type: Object,
			required: true
		}
	},
	methods: {
		/**
		 * Handle archive
		 * @returns {Promise<void>}
		 */
		async handleArchive() {
			console.debug("SupportTicketArchiveButton@handleArchive: Archiving SupportTicket.", {id: this.ticket.id})

			this.loading()
			const [err, res] = await SupportTickets.archive(this.ticket.id)
			this.loaded()

			if (err) {
				console.error("SupportTicketArchiveButton@handleArchive: Error on archive SupportTicket.", err)
				return false
			}

			this.ticket = res.data.data

			console.info("SupportTicketArchiveButton@handleArchive: SupportTicket archived.", {id: this.ticket.id})

			this.$emit('archived')

			return true
		}
	}
}
</script>