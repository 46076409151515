<i18n src="@/i18n/components/FormSelect.json"></i18n>
<template>
	<div :class="{'relative' : loading }">
		<div class="rounded-md shadow-sm relative">
			<!-- Select !-->
			<select
				class="block w-full min-w-0 rounded-md sm:text-sm"
				:class="computedClass"
				:value="value"
				@input="handleInput"
				@change="handleChange">
				<option v-if="defaultOption" value="" class="text-gray-900" :selected="isEmptySelected()">{{ defaultOptionLabel }}</option>
				<template v-for="(item, index) in options">
					<option :key="`select-${index}`" :value="item[optValue]" :selected="isSelected(item, index)" class="text-gray-900">
						<slot name="option" :data="{ item, localized: text(item) }">{{ text(item) }}</slot>
					</option>
				</template>
			</select>
			<!-- Icone de erro !-->
			<div v-if="validation && validation.$error" class="absolute inset-y-0 right-0 pr-7 flex items-center pointer-events-none">
				<i class="fa fa-exclamation-circle text-red-500"></i>
			</div>
		</div>
		<!-- Error !-->
		<ErrorBag v-if="validation" :validation="validation" v-bind="error"/>
		<!-- Info !-->
		<div v-if="$slots.info" class="mt-2 text-sm text-gray-500 flex items-center">
			<slot name="info"/>
		</div>
		<!-- Loading !-->
		<Loading v-if="loading" use-spinner/>
	</div>
</template>

<script>

import Loading from "@/components/Loading.vue"
import ErrorBag from "@/components/ErrorBag.vue"

export default {
	name: "FormSelect",
	components: {
		ErrorBag,
		Loading
	},
	props: {
		value: [String, Number],
		defaultValue: [String, Number],
		validation: Object,
		optLabel: {
			type: String,
			default: 'label'
		},
		optValue: {
			type: String,
			default: 'id'
		},
		error: Object,
		defaultOptionLabel: {
			type: String,
			default() {
				return this.$t('form_select.select')
			}
		},
		defaultOption: {
			type: Boolean,
			default: true
		},
		loading: Boolean,
		localized: Boolean,
		enum: Boolean,
		textCamelize: Boolean,
		textUpper: Boolean,
		textLower: Boolean,
		options: [Array, Object],
		selectDefault: {
			type: String,
			default: 'form-select'
		},
		defaultSelectClass: {
			type: String,
			default: 'form-select'
		},
		errorClass: {
			type: String,
			default: 'error'
		},
	},
	computed: {
		computedClass() {
			return [
				this.defaultSelectClass,
				{
					[this.errorClass]: this.hasError
				}
			]
		},
		hasError() {
			return this.validation?.$error
		},
		optionsList() {
			if (this.options.length) {
				return this.options
			}
			return this.options
		}
	},
	methods: {
		handleInput(e) {
			this.$emit('input', e.target.value)
			this.$emit('input:object', this.options.find(item => item[this.optValue] == e.target.value))
		},
		handleChange(e) {
			this.$emit('change', e.target.value)
			this.$emit('change:object', this.options.find(item => item[this.optValue] == e.target.value))
		},
		uppercase(text) {
			if (!text || !text.toUpperCase) return text

			return text.toUpperCase()
		},
		lowercase(text) {
			if (!text || !text.toLowerCase) return text

			return text.toLowerCase()
		},
		camelize(text) {
			if (!text || !text.replace) return text

			return text.toLowerCase().replace(/[a-z]/g, function (word, index) {
				return index ? word.toLowerCase() : word.toUpperCase();
			});
		},
		localize(text) {
			return this.localized ? this.$localize(text) : text;
		},
		text(item) {

			let text = item[this.localized && this.optLabel === 'label' ? 'label_locale' : this.optLabel]

			text = this.localize(text)

			if (this.textLower) {
				return this.uppercase(text)
			} else if (this.textUpper) {
				return this.lowercase(text)
			} else if (this.textCamelize) {
				return this.camelize(text)
			}

			return text
		},
		isEmptySelected() {
			return !this.value
		},
		isSelected(item, index) {
			if (!item || !this.value && this.value != null) {
				return index == 0
			}
			return this.value == item[this.optValue]
		},
		focus(){

		}
	},
	watch: {
		value: {
			handler(v) {
				if (!v && this.defaultValue) {
					this.$emit('input', this.defaultValue)
					this.$emit('change', this.defaultValue)
				}
			},
			immediate: true
		}
	}
}
</script>
