<template>
	<div class="relative" :class="{ 'bg-white' : !transparent, 'rounded shadow-md' : !frameless, 'overflow-hidden' : loading, 'cursor-pointer' : collapsible } ">
		<!-- header !-->
		<template v-if="title">
			<header :class="headerClass" class="flex py-3 border-b border-gray-200 items-center" @click="handleCollapse">
				<h3 class="border-l-4 px-4 border-turquoise font-bold text-turquoise"
					:class="[titleClass,titleSize]"
				>
					{{ title }}
					<!-- Title Loading !-->
					<template v-if="titleLoading">
						<i class="ml-3 text-xl spinner-icon fal fa-spinner-third text-blue-500 normal"></i>
					</template>
				</h3>
				<slot name="header"/>
				<slot name="after-title"/>
				<template v-if="collapsible">
					<div class="ml-auto pr-6 text-gray-600">
						<i class="fa" :class="{ 'fa-chevron-down' : collapsed, 'fa-chevron-up' : !collapsed }"></i>
					</div>
				</template>
			</header>
		</template>
		<!-- / header !-->
		<!-- content !-->
		<slot v-if="!collapsible || collapsed" v-bind:card="{ 'loading' : loading }" :class="{ 'h-32 overflow-hidden': loading }"/>
		<!-- loading !-->
		<Loading :overlay-opacity="100" v-bind="spinner" v-if="loading"/>
	</div>
</template>

<script>
import Loading from "@/components/Loading.vue"

export default {
	name: "Card",
	components: {Loading},
	props: {
		title: String,
		titleClass: String,
		titleSize: {
			type: String,
			default: "text-2xl"
		},
		transparent: {
			type: Boolean,
			default: false
		},
		frameless: {
			type: Boolean,
			default: false
		},
		headerClass: String,
		loading: {
			type: Boolean,
			default: false
		},
		titleLoading:{
			type: Boolean,
			default: false
		},
		collapsible: {
			type: Boolean,
			default: false
		},
		collapsed: {
			type: Boolean,
			default: false
		},
		spinner: Object
	},
	methods: {
		/**
		 * Handle collapse
		 * @returns {boolean}
		 */
		handleCollapse() {
			if (!this.collapsible) return false
			this.$emit('update:collapsed', !this.collapsed)
		}
	}
}
</script>
