import SupportTickets from "@/API/shared/SupportTickets.js"

import LoadingMixin from "@/mixins/loading.js"
import SystemMixin from "@/mixins/system.js"
import HandleRequestErrorsMixin from "@/mixins/handle_request_errors.js"

import ValidationMixin from "@/mixins/validation.js"
import {email, minLength, required, sameAs} from "@vuelidate/validators"

import {sync} from "vuex-pathify"

export default {
    mixins: [LoadingMixin, SystemMixin, HandleRequestErrorsMixin, ValidationMixin],
    computed: {
        ...sync('SupportTickets', ['form'])
    },
    validations: {
        form: {
            shipment: { },
            support_subject_id: {
                required
            },
            body: {
                required
            }
        }
    },
    methods: {
        /**
         * Action
         * @returns {boolean}
         */
        validate() {
            this.v$.$touch()

            console.debug('SupportTicket/Form@validade: Data validated.')

            return !this.v$.$invalid
        },
        /**
         * Action save
         * @returns {Promise<boolean>}
         */
        async save() {
            if (!this.validate()) return false

            const data = object_to_formdata(this.form)

            console.debug('SupportTicket/Form@save: Saving data.', data)

            this.loading()

            const [err, res] = await SupportTickets.insert(data)

            this.loaded()

            if (err) {
                console.error('SupportTicket/Form@save: Error on save data!', err)
                return this.$alertError(this.handleRequestError(err))
            }

            this.form = res.data.data

            console.debug('SupportTicket/Form@save: Data saved!')

            return true
        },
        /**
         * INIT
         */
        init(){
            // TODO
        }
    }
}
