import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    tokens: [],
    webhooks: [],
}

const mutations = make.mutations(state)

export default {
    namespaced: true,
    state,
    mutations
}
