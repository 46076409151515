<template>
	<button type="button"
			class="relative -ml-px hidden items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
			v-on="$listeners">
		<i class="fa fa-user-plus text-gray-600 mr-1"></i>
		Adicionar
	</button>
</template>

<script>
export default {
	name: "SupportTicketAddAddressButton"
}
</script>