<i18n src="@/i18n/components/Notifications.json"></i18n>
<template>
	<div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
		<div class="p-4 relative">
			<div class="flex items-center">
				<!-- Content! -->
				<div class="flex w-0 flex-1 justify-between">
					<!-- Title !-->
					<p class="w-0 flex-1 text-sm font-medium text-gray-900">{{ $t(notification.title, notification.localization_params) }}</p>
					<!-- Action link !-->
					<template v-if="action">
						<template v-if="isActionLink(action)">
							<a :href="action.redirect" :target="action.target">{{ $t("notification.actions." + action.label) }}</a>
						</template>
						<template v-else>
							<button type="button"
									:disabled="isActionLoading"
									class="relative px-2 py ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-blue-500 hover:text-blue-800 focus:outline-none hover:bg-blue-100"
									@click="handleAction(action)">
								{{ $t("notification.actions." + action.label) }}
								<Loading v-if="isActionLoading" use-spinner spinner-color="text-turquoise"/>
							</button>
						</template>
					</template>
				</div>
				<!-- / Content !-->
				<!-- Close !-->
				<div class="ml-4 flex flex-shrink-0">
					<button type="button"
							class="items-center justify-center w-4 inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
							@click="handleClose">
						<span class="sr-only">Close</span>
						<i class="fa fa-times"></i>
					</button>
				</div>
				<!-- / Close !-->
			</div>
		</div>
	</div>
</template>

<script>
import Loading from "@/components/Loading.vue"
import HandleCloseMixin from "./mixins/handle_close.js"
import HandleActionMixin from "./mixins/handle_action.js"

export default {
	name: "CondensedNotification",
	mixins: [HandleCloseMixin, HandleActionMixin],
	props: {
		notification: {
			type: Object,
			required: true
		}
	},
	components: {
		Loading
	},
	computed: {
		action() {
			return this.notification.actions[0]
		}
	},
	methods: {
		// .. handleAction
		// .. handleClose
		// .. delayClose
	},
	mounted() {
		if(this.notification.localization) {
			Object.keys(this.notification.localization).forEach((locale) => {
				this.$i18n.mergeLocaleMessage(locale, this.notification.localization[locale])
			} )
		}
	}
}
</script>
