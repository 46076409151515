<template>
	<div class="md:flex md:flex-shrink-0 z-40 min-h-screen">
		<!-- Mobile -->
		<transition v-on:enter="tEnter" v-on:leave="tLeave" :css="false">
			<div v-if="value">
				<!-- Overlay !-->
				<div @click="$emit('input', false)" class="fixed inset-0 z-30">
					<div class="absolute inset-0 bg-gray-600 opacity-75"></div>
				</div>
				<!-- Sidebar !-->
				<div class="fixed inset-0 flex z-40">
					<div class="flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
						<!-- Close !-->
						<div class="absolute top-0 right-0 -mrx4 p-1">
							<button @click="$emit('input', false)"
									class="flex items-center justify-center h-12 w-12 text-gray-100 rounded-full focus:outline-none focus:bg-gray-600 hover:bg-gray-600"
									aria-label="Close sidebar">
								<svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
								</svg>
							</button>
						</div>
						<!-- Logo !-->
						<div class="flex-shrink-0 flex items-center px-4">
							<img class="h-8 w-auto" :src="$domain.logo" alt="Logo"/>
						</div>
						<!-- Sidebar !-->
						<div class="custom-scrollbar mt-5 flex-1 h-0 overflow-y-auto">
							<MenuLinks class="px-2"/>
						</div>
					</div>
					<div class="flex-shrink-0 w-14">
						<!-- Force sidebar to shrink to fit close icon -->
					</div>
				</div>
				<!-- /Sidebar !-->
			</div>
		</transition>
		<!-- /Mobile -->
		<!-- Desktop -->
		<div class="hidden md:flex md:flex-shrink-0">
			<div class="flex flex-col translate-all duration-150" :class="{'w-72' : showLabels, 'w-16' : !showLabels}">
				<!-- Top !-->
				<div class="flex justify-center items-center h-16 flex-shrink-0 px-4 bg-white">
					<!-- Logo !-->
					<OpacityScaleTransition>
						<img v-if="showLabels" class="h-10" :src="$domain.logo" alt="Logo"/>
					</OpacityScaleTransition>
				</div>
				<!-- /Top !-->
				<!-- Menu !-->
				<div class="custom-scrollbar h-0 flex-1 flex flex-col overflow-y-auto overflow-x-hidden">
					<!-- Sidebar component, swap this element with another sidebar if you like -->
					<MenuLinks :show-labels="showLabels" class="flex-1 bg-dark-blue-gray"/>
				</div>
			</div>
		</div>
		<!-- /Desktop -->
	</div>
</template>

<script>
import MenuLinks from "./MenuLinks"
import OpacityScaleTransition from "@/components/OpacityScaleTransition"

import gsap from "gsap";

export default {
	name: "Sidebar",
	components: {MenuLinks, OpacityScaleTransition},
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			showLabels: true
			// showLabels: sync('App/sidebarLabels')
		}
	},
	methods: {
		handleClickLogo() {
			this.$router.push({name: 'dashboard'})
		},
		handleShowLabels() {
			this.showLabels = !this.showLabels
		},
		tEnter(el, done) {
			gsap.fromTo(el, {opacity: 0}, {opacity: 1, duration: .3, ease: "ease-in", onComplete: done})
		},
		tLeave(el, done) {
			gsap.to(el, {opacity: 0, duration: .2, ease: "ease-in", onComplete: done})
		}
	}
}
</script>
