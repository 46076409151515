import Pusher from "pusher-js"
import Echo from "laravel-echo"

import "@/css/tailwind.css"
import "@/css/tooltip.css"

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_PUSHER_HOST,
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    wssPort: process.env.VUE_APP_PUSHER_PORT,
    forceTLS: process.env.NODE_ENV !== 'development',
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});