import Axios from "axios"
import {serialize} from 'object-to-formdata'

export function serializer(params, prefix) {
    if (params === null) return params;
    const query = Object.keys(params).map((key) => {
        const value = params[key];

        if (key === undefined) return '';
        if (value === null || value === undefined) return `${key}=`

        if (Array.isArray(params)) {
            key = `${prefix}[]`
        } else if (params.constructor === Object) {
            key = (prefix ? `${prefix}[${key}]` : key)
        }

        if (value instanceof Date) {
            return `${key}=${value.toISOString()}`
        }

        if (typeof value === 'object') {
            return serializer(value, key)
        }

        return `${key}=${encodeURIComponent(value)}`
    });

    return [].concat.apply([], query).join('&')
}

export function paramsSerializer(params) {
    return serializer(params)
}

const client = Axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true
})

client.get('/sanctum/csrf-cookie');

export default client
